import React from "react";
import { TableCell as MuiTableCell } from "@mui/material";
import { styled } from "@mui/system";

const StyledTableCell = styled(MuiTableCell)({
  borderBottom: "solid 1px var(--future-tax-8)",
  color: "var(--future-tax-70)",
});

const TableCell = ({ children, ...props }) => {
  return <StyledTableCell {...props}>{children}</StyledTableCell>;
};

export default TableCell;
