import rootReducer from "./reducers";
import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
// import thunkMiddleware from "redux-thunk";

export const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware)
  // applyMiddleware(thunkMiddleware)
  // composeWithDevTools()
);
export default store;
