import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.293 9.707a1 1 0 0 0 1.414-1.414L7.414 6l2.293-2.293a1 1 0 0 0-1.414-1.414L6 4.586 3.707 2.293a1 1 0 0 0-1.414 1.414L4.586 6 2.293 8.293a1 1 0 0 0 1.414 1.414L6 7.414l2.293 2.293z"
        fill="#000E25"
      />
    </svg>
  );
};

export default CloseIcon;
