import { AUTH_BASE_URL, URLS } from "../configuration/urls";
import axios from "axios";
import jwtDecode from "jwt-decode";

export const ECAxiosAPI = axios.create({
  baseURL: "",
});

export const isTokenValid = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const tokenExpiryTime = new Date(decodedToken.exp * 1000);
    const currentDateTime = new Date();
    return tokenExpiryTime >= currentDateTime;
  } catch (err) {
    return false;
  }
};

export default class ECAuthInterceptor {
  constructor() {
    this.keycloack_api_url = AUTH_BASE_URL;
    this.isRefreshing = false;
    this.refreshTokenPromise = null;
  }

  validateRequest = async (req) => {
    const refreshToken = localStorage.getItem("refreshToken");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: refreshToken,
      redirect: "follow",
    };
    if (refreshToken && isTokenValid(refreshToken)) {
      const res = await (
        await fetch(this.keycloack_api_url + URLS.REFRESHTOKEN, requestOptions)
      ).json();

      localStorage.setItem("getToken", res?.access_token);
      localStorage.setItem("refreshToken", res?.refresh_token);
      req.headers["Authorization"] = `Bearer ${res?.access_token}`;
      this.isRefreshing = false;
      return res.access_token;
    } else {
      localStorage.removeItem("getToken");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    }
  };

  initRequestInterceptor = () => {
    ECAxiosAPI.interceptors.request.use(
      async (req) => {
        let token = localStorage.getItem("getToken");
        if (
          token &&
          !req.url.includes("ec-authorization-be/v1/user-manager/login") &&
          isTokenValid(token)
        ) {
          req.headers["Authorization"] = `Bearer ${token}`;
          return req;
        } else {
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenPromise = this.validateRequest(req)
              .then((newToken) => {
                this.isRefreshing = false;
                this.refreshTokenPromise = null;
                return newToken;
              })
              .catch((error) => {
                this.isRefreshing = false;
                this.refreshTokenPromise = null;
                throw error;
              });
          }
          token = await this.refreshTokenPromise;
          req.headers["Authorization"] = `Bearer ${token}`;
          return req;
        }
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  };

  initResponseInterCeptor = () => {
    ECAxiosAPI.interceptors.response.use(
      (res) => {
        this.retry_time = 0;
        return res;
      },
      (err) => {
        if (
          err.request.responseURL.includes(
            "ec-authorization-be/v1/user-manager/login"
          )
        ) {
          return Promise.reject(
            err.response.data.message === "unverified_email"
              ? "UNVERIFIED"
              : "NotAuthorizedException"
          );
        }
        switch (err.response?.status) {
          case 403:
            return Promise.reject(err);
          // localStorage.removeItem("getToken");
          // localStorage.removeItem("refreshToken");
          // window.location.href = window.location.origin;
          default:
            return Promise.reject(err);
        }
      }
    );
  };
}
