import { createTheme } from "@mui/material";

import TheinhardtLItWoff2 from "../assets/fonts/Theinhardt-LightItalic.woff2";
import TheinhardtLItWoff from "../assets/fonts/Theinhardt-LightItalic.woff";

import TheinhardtLWoff2 from "../assets/fonts/Theinhardt-Light.woff2";
import TheinhardtLWoff from "../assets/fonts/Theinhardt-Light.woff";

import TheinhardtItWoff2 from "../assets/fonts/Theinhardt-Italic.woff2";
import TheinhardtItWoff from "../assets/fonts/Theinhardt-Italic.woff";

import TheinhardtMWoff2 from "../assets/fonts/Theinhardt-Medium.woff2";
import TheinhardtMWoff from "../assets/fonts/Theinhardt-Medium.woff";

import TheinhardtRWoff2 from "../assets/fonts/Theinhardt-Regular.woff2";
import TheinhardtRWoff from "../assets/fonts/Theinhardt-Regular.woff";

import TheinhardtMItWoff2 from "../assets/fonts/Theinhardt-MediumItalic.woff2";
import TheinhardtMItWoff from "../assets/fonts/Theinhardt-MediumItalic.woff";

export const lightMode = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#00183D",
    },
    secondary: {
      main: "#f50057",
    },
    info: {
      main: "#f2f4f7",
      light: "#FCFDFF",
    },
    warning: {
      main: "#ff8800",
      lightBg: "#fffaf5",
    },
    success: {
      main: "#00c376",
      lightBg: "#f5fdfa",
    },
    error: {
      main: "#e01600",
      lightBg: "#fef6f5",
    },
    white: {
      main: "#ffffff",
    },
    infoLight: {
      main: "#fcfdff",
    },
    infoFade: {
      main: "rgba(0, 24, 61, 0.6)",
    },
    background: {
      default: "#F2F4F7",
    },
  },
  typography: {
    fontFamily: "Theinhardt",
    fontSize: 16,
    lineHeight: 1,
    allVariants: {
      color: "#00183D",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Theinhardt';
          src: url(${TheinhardtLItWoff2}) format('woff2'),
              url(${TheinhardtLItWoff}) format('woff');
          font-weight: 300;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: 'Theinhardt';
          src: url(${TheinhardtLWoff2}) format('woff2'),
              url(${TheinhardtLWoff}) format('woff');
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Theinhardt';
          src: url(${TheinhardtItWoff2}) format('woff2'),
              url(${TheinhardtItWoff}) format('woff');
          font-weight: normal;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: 'Theinhardt';
          src: url(${TheinhardtMWoff2}) format('woff2'),
              url(${TheinhardtMWoff}) format('woff');
          font-weight: 500;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Theinhardt';
          src: url(${TheinhardtRWoff2}) format('woff2'),
              url(${TheinhardtRWoff}) format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'Theinhardt';
          src: url(${TheinhardtMItWoff2}) format('woff2'),
              url(${TheinhardtMItWoff}) format('woff');
          font-weight: 500;
          font-style: italic;
          font-display: swap;
        }
      `,
    },
  },
  props: {
    MuiAppBar: {
      color: "transparent",
    },
  },
  shape: {
    borderRadius: "6px",
  },
});
