import "./styles.css";
import {
  Box,
  CssBaseline,
  Typography,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setLang } from "../../redux/actions/settings";
import Logo from "../Logo/Logo";
import { FormattedMessage } from "react-intl";
import { setIsAuthenticated, setUserGroups } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { formattedMessage } from "../../configuration/helpers";
import { deLocale, enLocale } from "./constants";

const DeFlag = ({ fontSize }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={fontSize}
      height={fontSize}
      viewBox="0 0 512 512"
    >
      <mask id="a">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path fill="#ffda44" d="m0 345 256.7-25.5L512 345v167H0z" />
        <path fill="#d80027" d="m0 167 255-23 257 23v178H0z" />
        <path fill="#333" d="M0 0h512v167H0z" />
      </g>
    </svg>
  );
};

const GbFlag = ({ fontSize }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={fontSize}
      height={fontSize}
      viewBox="0 0 512 512"
    >
      <mask id="a">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#eee"
          d="m0 0 8 22-8 23v23l32 54-32 54v32l32 48-32 48v32l32 54-32 54v68l22-8 23 8h23l54-32 54 32h32l48-32 48 32h32l54-32 54 32h68l-8-22 8-23v-23l-32-54 32-54v-32l-32-48 32-48v-32l-32-54 32-54V0l-22 8-23-8h-23l-54 32-54-32h-32l-48 32-48-32h-32l-54 32L68 0H0z"
        />
        <path
          fill="#0052b4"
          d="M336 0v108L444 0Zm176 68L404 176h108zM0 176h108L0 68ZM68 0l108 108V0Zm108 512V404L68 512ZM0 444l108-108H0Zm512-108H404l108 108Zm-68 176L336 404v108z"
        />
        <path
          fill="#d80027"
          d="M0 0v45l131 131h45L0 0zm208 0v208H0v96h208v208h96V304h208v-96H304V0h-96zm259 0L336 131v45L512 0h-45zM176 336 0 512h45l131-131v-45zm160 0 176 176v-45L381 336h-45z"
        />
      </g>
    </svg>
  );
};

function Header() {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.settings.lang);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("getToken");
    localStorage.removeItem("refreshToken");
    dispatch(setIsAuthenticated(false));
    dispatch(setUserGroups([]));
    navigate("/");
  };

  moment.locale(lang, lang === "en" ? enLocale : deLocale);

  return (
    <Box sx={{ display: "flex", marginBottom: isAuthenticated && "70px" }}>
      <CssBaseline />
      <nav className="app-navbar">
        <div className="navbar-content">
          <Typography
            sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}
          >
            <Logo />
          </Typography>
          <Box
            sx={{ display: { xs: "block", sm: "block" } }}
            style={{ display: "flex", alignItems: "center" }}
          >
            {isAuthenticated && (
              <div style={{ marginRight: "112px" }}>
                <Typography
                  style={{
                    color: "var(--future-tax-70)",
                    fontSize: "16px",
                  }}
                >
                  {moment().format(formattedMessage("header_datetime"))}
                </Typography>
              </div>
            )}

            <Select
              value={lang}
              onChange={(e) => {
                dispatch(setLang(e.target.value));
              }}
              variant="standard"
              disableUnderline
              className="language-select"
              MenuProps={{
                disableScrollLock: true,
              }}
              style={{
                marginRight: "8px",
                cursor: "pointer",
                userSelect: "none",
              }}
              IconComponent={() => (
                <svg
                  width="18"
                  height="18"
                  viewBox="2 2 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    transform: "translateX(-16px)",
                    userSelect: "none",
                    cursor: "pointer",
                  }}
                >
                  <path
                    d="M4.17 4.818a1.035 1.035 0 0 0-1.518 1.408l4.615 4.982a1 1 0 0 0 1.467 0l4.615-4.982a1.035 1.035 0 0 0-1.518-1.408L8 8.945 4.17 4.818z"
                    fill="#000E25"
                  />
                </svg>
              )}
            >
              <MenuItem
                value="en"
                style={{
                  padding: "8px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <GbFlag fontSize={16} />
                  <div
                    style={{
                      color: "var(--future-tax-80)",
                      fontSize: "16px",
                      marginLeft: "6px",
                    }}
                  >
                    <FormattedMessage id="English" />
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                value="de"
                style={{
                  padding: "8px 0px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <DeFlag fontSize={16} />
                  <div
                    style={{
                      color: "var(--future-tax-80)",
                      fontSize: "16px",
                      marginLeft: "6px",
                    }}
                  >
                    <FormattedMessage id="German" />
                  </div>
                </div>
              </MenuItem>
            </Select>
            {isAuthenticated && (
              <Button
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => handleLogout()}
              >
                <FormattedMessage id="Logout" />
                <svg
                  style={{ marginLeft: "12px" }}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 7a1 1 0 1 1 0 2H8a1 1 0 0 1 0-2h4z"
                    fill="#324664"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.293 11.707a1 1 0 0 1 0-1.414L12.586 8l-2.293-2.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414 0zM1 3a1 1 0 0 1 1-1h5v2H3.25a.25.25 0 0 0-.25.25v7.5c0 .138.112.25.25.25H7v2H2a1 1 0 0 1-1-1V3z"
                    fill="#324664"
                  />
                </svg>
              </Button>
            )}
            {/* <DarkModeToggle /> */}
          </Box>
        </div>
      </nav>
    </Box>
  );
}

export default Header;
