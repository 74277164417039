import { ECAxiosAPI } from "../configuration/interceptor";
import { AUTH_BASE_URL, URLS } from "../configuration/urls";

export const getRefreshToken = async (oldReq) => {
  let newToken = "";

  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: refreshToken,
      redirect: "follow",
    };

    const res = await (
      await fetch(AUTH_BASE_URL + URLS.REFRESHTOKEN, requestOptions)
    ).json();

    console.log("AUTH REFRESH : ", res);

    localStorage.setItem("getToken", res?.access_token);
    localStorage.setItem("refreshToken", res?.refresh_token);

    if (
      res.message === "invalid_refresh_token" ||
      res.status === 401 ||
      res.status === "UNAUTHORIZED"
    ) {
      localStorage.removeItem("getToken");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    }
  } catch (error) {
    // no user
    localStorage.removeItem("getToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/";
  }

  if (oldReq) {
    // this.retry_time += 1;
    return ECAxiosAPI.request(oldReq);
  } else {
    return newToken;
  }
};
