import React from "react";

const AddIcon = ({ size, color = "#324664" }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 14.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0 2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-10a1 1 0 0 1 2 0v1h1a1 1 0 1 1 0 2H9v1a1 1 0 1 1-2 0v-1H6a1 1 0 0 1 0-2h1v-1z"
        fill={color}
      />
    </svg>
  );
};

export default AddIcon;
