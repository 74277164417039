import React, { useState, useRef, useCallback, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import FileIcon from "../../../../assets/icons/FileIcon";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import CheckIcon from "../../../../assets/icons/CheckIcon";
import { imgPreview } from "./cropHelpers";
import { FormattedMessage } from "react-intl";
import UploadIcon from "../../../../assets/icons/UploadIcon";
import DisableIcon2 from "../../../../assets/icons/DisableIcon2";
import { useDropzone } from "react-dropzone";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

export default function ImageUpload({
  image,
  title = "Image Upload",
  loading,
  setImage,
}) {
  const [mode, setMode] = useState("upload");
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    image ? setMode("preview") : setMode("upload");
    (imgSrc === "" || imgSrc === null || !imgSrc) && setImgSrc(image);
  }, [image]);

  const fileInputRef = useRef(null);
  const imgRef = useRef(null);

  const resetAll = () => {
    setImgSrc("");
    setImage("");
    setCrop();
    setCompletedCrop();
    setError(null);
    fileInputRef.current = null;
    imgRef.current = null;
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop();
      setCompletedCrop();
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
      });
      reader.readAsDataURL(e.target.files[0]);
      setMode("crop");
    }
  }

  const handleDeleteImage = () => {
    resetAll();
    setMode("upload");
  };

  const handleReCrop = () => {
    setMode("crop");
  };

  const handleCancelCrop = () => {
    if (image) {
      setMode("preview");
    } else {
      setMode("upload");
    }
  };

  const getPreview = async () => {
    const response = await imgPreview(
      imgRef.current,
      completedCrop || {
        x: 0,
        y: 0,
        unit: "px",
        width: imgRef.current.width,
        height: imgRef.current.height,
      },
      1,
      0,
      "url"
    );
    setImage(response);
    setMode("preview");
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    switch (rejectedFiles.length > 0 && rejectedFiles?.[0]?.errors?.[0].code) {
      case "file-too-large":
        setError("error.fileLargeLocal");
        return;
      default:
        setError("error.unsupportedFile");
    }

    if (acceptedFiles.length === 0) {
      setError("error.unsupportedFile");
      return;
    }
    const file = acceptedFiles[0];
    if (file.size > 200 * 1024) {
      setError("error.fileLarge");
      return;
    }

    if (!["image/jpeg", "image/png", "image/gif"].includes(file.type)) {
      setError("error.unsupportedFile");
      return;
    }

    setError(null);
    setCrop();
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgSrc(reader.result?.toString() || "");
    });

    reader.readAsDataURL(file);
    setMode("crop");
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,

    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
    maxSize: 200 * 1024,
  });

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: "12px" }}>
        <span className="ParagraphMediumBold">{title}</span>
      </div>
      {loading ? (
        <div style={{ position: "relative" }}>
          <Skeleton
            style={{ height: "165px", transform: "scale(1, 1)" }}
            animation="wave"
          />
          <ImageOutlinedIcon
            style={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: "auto",
              position: "absolute",
              fontSize: "48px",
              color: "var(--future-tax-80)",
            }}
          />
        </div>
      ) : (
        <div>
          {mode === "upload" && (
            <div {...getRootProps()} style={{ cursor: "pointer" }}>
              <input {...getInputProps()} />
              <div
                className={`dropzone ${error && "error"}`}
                style={{
                  padding: "26px 16px",
                  height: "139px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "6px",
                }}
              >
                <div>
                  {error ? (
                    <DisableIcon2 color="var(--error-100)" />
                  ) : (
                    <UploadIcon color="var(--future-tax-140)" />
                  )}
                </div>
                <div>
                  <span
                    className=" ParagraphSmallDefault"
                    style={{
                      color: error
                        ? "var(--error-100)"
                        : "var(--future-tax-140)",
                    }}
                  >
                    <FormattedMessage
                      id="uploadPrompt"
                      values={{
                        b: (...chunks) => {
                          return (
                            <span
                              className="ParagraphSmallBold"
                              style={{
                                color: error
                                  ? "var(--error-100)"
                                  : "var(--future-tax-70)",
                              }}
                            >
                              {chunks[0]}
                            </span>
                          );
                        },
                      }}
                    />
                  </span>
                </div>
                <div>
                  {error ? (
                    <span
                      className="Placeholder NoteBold"
                      style={{ color: "var(--error-100)" }}
                    >
                      <FormattedMessage id={error} />
                    </span>
                  ) : (
                    <span
                      className="Placeholder NoteBold"
                      style={{ color: "var(--future-tax-70)" }}
                    >
                      <FormattedMessage id="fileUploadInfo" />
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          {imgSrc && mode === "crop" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={4 / 3}
              >
                <img ref={imgRef} alt="Crop" src={imgSrc} />
              </ReactCrop>
              <div>
                <Tooltip title={<FormattedMessage id="Cancel" />}>
                  <IconButton size="small" onClick={handleCancelCrop}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="Save changes" />}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      getPreview();
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
          <div>
            {mode === "preview" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    maxHeight: "300px",
                    maxWidth: "300px",
                    objectFit: "contain",
                  }}
                  src={image}
                  alt="cropped"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    columnGap: 10,
                  }}
                >
                  <Tooltip title={<FormattedMessage id="changeImage" />}>
                    <IconButton
                      size="small"
                      style={{ width: "27px", height: "27px", padding: "8px" }}
                      onClick={() => fileInputRef.current.click()}
                    >
                      <div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={onSelectFile}
                          style={{ display: "none" }}
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                        />
                        <FileIcon />
                      </div>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={<FormattedMessage id="removeImage" />}>
                    <IconButton
                      size="small"
                      onClick={handleDeleteImage}
                      style={{ width: "26px", height: "26px" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={<FormattedMessage id="editCrop" />}>
                    <IconButton size="small" onClick={handleReCrop}>
                      <PencilIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
