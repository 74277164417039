export const columns = [
  {
    id: "businessPartner",
    label: "businessPartner",
    minWidth: 300,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "tenantId",
    label: "tenantId",
    minWidth: 300,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "name",
    label: "name",
    minWidth: 300,
    align: "left",
    sortLabel: true,
  },
  {
    id: "legalForm",
    label: "legalForm",
    minWidth: 150,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "vatID",
    label: "vatID",
    minWidth: 170,
    align: "left",
    sortLabel: true,
  },
  // {
  //   id: "createdDate",
  //   label: "createdDate",
  //   minWidth: 170,
  //   align: "left",
  // },
  {
    id: "activated",
    label: "activated",
    minWidth: 170,
    align: "left",
    sortLabel: true,
  },
  {
    id: "lastUploadedDate",
    label: "lastUploadedDate",
    minWidth: 200,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "dataActuality",
    label: "dataActuality",
    minWidth: 170,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "email",
    label: "userMailAddress",
    minWidth: 170,
    align: "left",
    sortLabel: true,
  },
  {
    id: "phoneNumber",
    label: "phoneNumber",
    minWidth: 170,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "street",
    label: "street",
    minWidth: 170,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "houseNumber",
    label: "houseNumber",
    minWidth: 170,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "postalCode",
    label: "postalCode",
    minWidth: 170,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "city",
    label: "city",
    minWidth: 170,
    align: "left",
    sortLabel: true,
  },
  {
    id: "country",
    label: "country",
    minWidth: 170,
    align: "left",
    sortLabel: true,
  },
  {
    id: "trialPeriod",
    label: "trialPeriod",
    minWidth: 170,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "daysLeftInTrialPeriod",
    label: "daysLeft",
    minWidth: 220,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "paymentMethod",
    label: "paymentMethod",
    minWidth: 220,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "channelPartner",
    label: "channelPartner",
    minWidth: 220,
    align: "left",
    sortLabel: true,
  },
  {
    id: "product",
    label: "product",
    minWidth: 220,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "subscriptionStart",
    label: "subscriptionStart",
    minWidth: 220,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "status",
    label: "status",
    minWidth: 220,
    align: "left",
    // sortLabel: true,
  },
  {
    id: "actions",
    label: "actions",
    align: "center",
    sticky: true,
  },
];

export const checkboxes = [
  "businessPartner",
  "tenantId",
  "name",
  "legalForm",
  "vatID",
  // "createdDate",
  "activated",
  "lastUploadedDate",
  "dataActuality",
  "email",
  "phoneNumber",
  "street",
  "houseNumber",
  "postalCode",
  "city",
  "country",
  "trialPeriod",
  "paymentMethod",
  "channelPartner",
  "product",
  "subscriptionStart",
  "status",
];

export const filters = {
  checkboxes: {
    name: "checked",
    legalForm: "unChecked",
  },
};

export const dataFilterControls = [
  {
    label: "Business Partner",
    name: "name",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "merchantId",
    name: "merchantId",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Merchant Name",
    name: "name",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Legal Form",
    name: "legalForm",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Merchant VAT ID",
    name: "vatID",
    type: "text",
    textType: "alphanumeric",
  },
  // {
  //   label: "Created On",
  //   name: "createdDate",
  //   type: "date",
  // },
  {
    label: "Activated",
    name: "activated",
    type: "dropdown",
    options: ["Yes", "No"],
  },
  {
    label: "Last data upload",
    name: "lastUploadedDate",
    type: "date",
  },
  {
    label: "Data actuality",
    name: "dataActuality",
    type: "date",
  },
  {
    label: "Email",
    name: "mailAddress",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Phone number",
    name: "phoneNumber",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "street",
    name: "street",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "House number",
    name: "houseNumber",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Postal code",
    name: "postalCode",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "City",
    name: "city",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "country",
    name: "country",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Trial period",
    name: "trialPeriod",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Payment Method",
    name: "paymentMethod",
    type: "text",
    textType: "alphanumeric",
  },
  {
    label: "Channel Partner",
    name: "channelPartner",
    type: "text",
    textType: "alphanumeric",
  },
];
