import React from "react";

const CopyIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 7.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4zM3 5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H3z"
        fill="#000E25"
      />
      <path
        d="M14 5.5A3.5 3.5 0 0 0 10.5 2H6v2h4.25c.966 0 1.75.784 1.75 1.75V10h2V5.5z"
        fill="#000E25"
      />
    </svg>
  );
};

export default CopyIcon;
