import {
  GET_TAX_COUNTRIES_LIST,
  GET_TAX_COUNTRIES_LIST_SUCCESS,
  GET_TAX_COUNTRIES_LIST_ERROR,
  GET_TAX_TYPE_LIST,
  GET_TAX_TYPE_LIST_SUCCESS,
  GET_TAX_TYPE_LIST_ERROR,
  GET_TAX_VERSION_LIST,
  GET_TAX_VERSION_LIST_SUCCESS,
  GET_TAX_VERSION_LIST_ERROR,
  GET_TAX_ATTRIBUTE_LIST,
  GET_TAX_ATTRIBUTE_LIST_SUCCESS,
  GET_TAX_ATTRIBUTE_LIST_ERROR,
  CREATE_TAX_VERSION,
  CREATE_TAX_VERSION_SUCCESS,
  CREATE_TAX_VERSION_ERROR,
  UPDATE_TAX_VERSION,
  UPDATE_TAX_VERSION_SUCCESS,
  UPDATE_TAX_VERSION_ERROR,
  CREATE_TAX_ATTRIBUTE,
  CREATE_TAX_ATTRIBUTE_SUCCESS,
  CREATE_TAX_ATTRIBUTE_ERROR,
  UPDATE_TAX_ATTRIBUTE_SUCCESS,
  UPDATE_TAX_ATTRIBUTE,
  UPDATE_TAX_ATTRIBUTE_ERROR,
  DELETE_TAX_VERSION,
  DELETE_TAX_VERSION_SUCCESS,
  DELETE_TAX_VERSION_ERROR,
  DELETE_TAX_ATTRIBUTE,
  DELETE_TAX_ATTRIBUTE_SUCCESS,
  DELETE_TAX_ATTRIBUTE_ERROR,
  SET_TAX_COUNTRIES_LIST_STATE,
  COPY_TAX_VERSION,
  COPY_TAX_VERSION_SUCCESS,
  COPY_TAX_VERSION_ERROR,
  GET_VALIDATE_TAX_VERSION,
  GET_VALIDATE_TAX_VERSION_SUCCESS,
  GET_VALIDATE_TAX_VERSION_ERROR,
  SET_VALIDATE_TAX_VERSION_STATE,
  GET_TAX_CODE_LIST,
  GET_TAX_CODE_LIST_SUCCESS,
  GET_TAX_CODE_LIST_ERROR,
  GET_TAX_CODE_COUNTRY_LIST,
  GET_TAX_CODE_COUNTRY_LIST_SUCCESS,
  GET_TAX_CODE_COUNTRY_LIST_ERROR,
  DELETE_TAX_CODE,
  DELETE_TAX_CODE_SUCCESS,
  DELETE_TAX_CODE_ERROR,
  CREATE_TAX_CODE,
  CREATE_TAX_CODE_SUCCESS,
  CREATE_TAX_CODE_ERROR,
  GET_TAX_CODE,
  GET_TAX_CODE_SUCCESS,
  GET_TAX_CODE_ERROR,
  UPDATE_TAX_CODE,
  UPDATE_TAX_CODE_SUCCESS,
  UPDATE_TAX_CODE_ERROR,
  SET_FORMULA_ERROR_STATE,
} from "../actions/filevat";

import { produce } from "immer";

const initialState = {
  loading: false,
  // listLoading: false,
  // showSpinner: true,
  items: [],
  taxTypeList: [],
  taxCountriesList: [],
  tempTaxCountriesList: [],
  taxVersionList: [],
  item: {},
  taxVersionCreated: {},
  taxVersionDefault: {},
  taxVersionCopied: {},
  taxBoxUpdatedList: {},
  taxCodeCreated: {},
  taxAttributeList: [],
  validationSuccess: [],
  taxCodeItem: {},
  taxCodeList: [],
  taxCodeCountryList: [],
  taxBoxFormulaError: "",
  totalNumberOfElements: 0,
  error: "",
  versionDeletedSuccess: false,
  taxCodeDeletedSuccess: false,
  attributeDeletedSuccess: false,
};

export function fileVat(state = initialState, action) {
  switch (action.type) {
    case GET_TAX_COUNTRIES_LIST:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case GET_TAX_COUNTRIES_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxCountriesList = action.payload;
      });
    case GET_TAX_COUNTRIES_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });

    case GET_TAX_TYPE_LIST:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case GET_TAX_TYPE_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxTypeList = action.payload;
      });
    case GET_TAX_TYPE_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });

    case GET_TAX_VERSION_LIST:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.taxVersionCreated = {};
        draftState.taxAttributeList = [];
        draftState.taxBoxUpdatedList = {};
      });
    case GET_TAX_VERSION_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxVersionList = action.payload;
        draftState.taxVersionDefault = {};
        //need changes
        draftState.versionDeletedSuccess = false;
      });
    case GET_TAX_VERSION_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
        draftState.taxVersionList = [];

        //need changes
        draftState.versionDeletedSuccess = false;
      });

    case CREATE_TAX_VERSION:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case CREATE_TAX_VERSION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxVersionCreated = action.payload;
      });
    case CREATE_TAX_VERSION_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });

    case UPDATE_TAX_VERSION:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case UPDATE_TAX_VERSION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxVersionCreated = action.payload;
        draftState.taxVersionDefault = action.payload;
      });
    case UPDATE_TAX_VERSION_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });

    case GET_TAX_ATTRIBUTE_LIST:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.attributeDeletedSuccess = false;
      });
    case GET_TAX_ATTRIBUTE_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxAttributeList = action.payload;
      });
    case GET_TAX_ATTRIBUTE_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
        draftState.taxAttributeList = [];
      });

    case CREATE_TAX_ATTRIBUTE:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.taxBoxFormulaError = "";
      });
    case CREATE_TAX_ATTRIBUTE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxBoxUpdatedList = action.payload;
      });
    case CREATE_TAX_ATTRIBUTE_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
        draftState.taxBoxFormulaError = action.error;
        draftState.taxBoxUpdatedList = {};
      });

    case UPDATE_TAX_ATTRIBUTE:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.taxBoxFormulaError = "";
      });
    case UPDATE_TAX_ATTRIBUTE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxBoxUpdatedList = action.payload;
      });
    case UPDATE_TAX_ATTRIBUTE_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
        draftState.taxBoxFormulaError = action.error;
        draftState.taxBoxUpdatedList = {};
      });

    case DELETE_TAX_VERSION:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case DELETE_TAX_VERSION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.versionDeletedSuccess = true;
      });
    case DELETE_TAX_VERSION_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.versionDeletedSuccess = false;
        draftState.error = action.error;
      });

    case DELETE_TAX_ATTRIBUTE:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case DELETE_TAX_ATTRIBUTE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.attributeDeletedSuccess = true;
      });
    case DELETE_TAX_ATTRIBUTE_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.attributeDeletedSuccess = false;
        draftState.error = action.error;
      });

    case SET_TAX_COUNTRIES_LIST_STATE:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.tempTaxCountriesList = action.payload;
      });

    case COPY_TAX_VERSION:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case COPY_TAX_VERSION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxVersionCopied = action.payload;
      });
    case COPY_TAX_VERSION_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });

    case GET_VALIDATE_TAX_VERSION:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case GET_VALIDATE_TAX_VERSION_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.validationSuccess = action.payload;
      });
    case GET_VALIDATE_TAX_VERSION_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
        draftState.validationSuccess = action.error;
      });
    case SET_VALIDATE_TAX_VERSION_STATE:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.validationSuccess = [];
      });

    case GET_TAX_CODE_LIST:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.taxCodeDeletedSuccess = false;
      });
    case GET_TAX_CODE_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxCodeList = action.payload.countryTaxCodeDtoList;
        draftState.totalNumberOfElements = action.payload.totalNumberOfElements;
      });
    case GET_TAX_CODE_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });
    case GET_TAX_CODE_COUNTRY_LIST:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.taxCodeCreated = {};
        draftState.taxCodeDeletedSuccess = false;
      });
    case GET_TAX_CODE_COUNTRY_LIST_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxCodeCountryList = action.payload.taxCodeList;
        draftState.totalNumberOfElements = action.payload.totalNumberOfElements;
      });
    case GET_TAX_CODE_COUNTRY_LIST_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });
    case DELETE_TAX_CODE:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case DELETE_TAX_CODE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = true;
        draftState.taxCodeDeletedSuccess = true;
      });
    case DELETE_TAX_CODE_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxCodeDeletedSuccess = action.error;
        draftState.error = action.error;
      });
    case CREATE_TAX_CODE:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case CREATE_TAX_CODE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxCodeCreated = action.payload;
      });
    case CREATE_TAX_CODE_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });
    case GET_TAX_CODE:
      return produce(state, (draftState) => {
        draftState.loading = false;
      });
    case GET_TAX_CODE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxCodeItem = action.payload;
      });
    case GET_TAX_CODE_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });

    case UPDATE_TAX_CODE:
      return produce(state, (draftState) => {
        draftState.loading = true;
      });
    case UPDATE_TAX_CODE_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.taxCodeCreated = action.payload;
      });
    case UPDATE_TAX_CODE_ERROR:
      return produce(state, (draftState) => {
        draftState.loading = false;
        draftState.error = action.error;
      });
    case SET_FORMULA_ERROR_STATE:
      return produce(state, (draftState) => {
        draftState.taxBoxFormulaError = "";
      });
    default:
      return state;
  }
}
