import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  IconButton,
  TextField,
  InputAdornment,
  Checkbox,
  TableSortLabel,
  TablePagination,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as Duplicate } from "../../../assets/icons/duplicate.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/checked.svg";
import { ReactComponent as UnCheckIcon } from "../../../assets/icons/unCheck.svg";
import {
  checkIfImageExists,
  getCountryName,
  getStatusColor,
  trimString,
} from "../common/helper";
import { fileVatActions } from "../../../redux/actions/filevat";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "./ConfirmDialog";
import moment from "moment";

function CountryTable({ setTaxCodeModalValue }) {
  const {
    taxCodeCountryList,
    taxCodeCreated,
    taxCodeDeletedSuccess,
    totalNumberOfElements,
  } = useSelector((state) => state.fileVat);
  const [ascending, setAscending] = useState(true);
  const [orderBy, setOrderBy] = useState("vatTaxCode");
  const [pagination, setPagination] = useState({
    page: 0,
    size: 25,
    // totalRecords: 25,
  });
  const { country } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [taxCodeList, setTaxCodeList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    type: "",
    id: null,
    content: "",
  });

  useEffect(() => {
    dispatch(
      fileVatActions.getTaxCodeCountryList({
        ...pagination,
        countryCode: country,
        ascending,
        orderBy,
        searchedText,
      })
    );
  }, [ascending, orderBy, pagination.page, pagination.size, searchedText]);

  useEffect(() => {
    if (Object.keys(taxCodeCreated).length > 0) {
      dispatch(
        fileVatActions.getTaxCodeCountryList({
          ...pagination,
          countryCode: country,
          ascending,
          orderBy,
          searchedText,
        })
      );
    }
  }, [taxCodeCreated]);
  // one use Effect
  useEffect(() => {
    if (taxCodeDeletedSuccess === true) {
      dispatch(
        fileVatActions.getTaxCodeCountryList({
          ...pagination,
          countryCode: country,
          ascending,
          orderBy,
          searchedText,
        })
      );
    } else if (
      taxCodeDeletedSuccess &&
      taxCodeDeletedSuccess.code === "INVALID_OPERATION_EXCEPTION"
    ) {
      setDialogOpen(true);
      setDialogData({
        type: "INVALID_OPERATION_EXCEPTION",
        title: `The eClear VAT Tax Code is used within at least one Mapping Template and cannot be deleted!`,
      });
    }
  }, [taxCodeDeletedSuccess]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property ? !ascending : false;
    setAscending(isAsc);
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangePageSize = (event) => {
    setPagination({
      ...pagination,
      size: +event.target.value,
      page: 0,
    });
  };
  const deleteTaxCode = (taxCodeItem) => {
    setDialogOpen(true);
    setDialogData({
      type: "deleteTaxCode",
      taxCodeItem: taxCodeItem,
      title: `Are you sure that you want to remove the VAT Tax Code ${taxCodeItem.vatTaxCode}?`,
      content: "This action cannot be undone!",
    });
  };
  // useEffect(() => {
  //   if (Object.keys(taxVersionCopied).length > 0 && isDuplicated) {
  //     setIsDuplicated(false);
  //     navigate(`/filevat-version-detail/${country.toLowerCase()}`, {
  //       state: {
  //         ...state,
  //         selectedVersionItem: taxVersionCopied,
  //         viewType: "edit",
  //       },
  //     });
  //   }
  // }, [taxVersionCopied]);

  const handleSearch = (e) => {
    if (e.trim().length > 0) {
      setSearchedText(e);
    } else {
      setSearchedText("");
    }
  };
  const handleDialogResponse = () => {
    setDialogOpen(false);
    switch (dialogData.type) {
      case "deleteTaxCode":
        dispatch(
          fileVatActions.deleteTaxCode({
            id: dialogData.taxCodeItem.id,
          })
        );
        break;
      default:
        break;
    }
  };

  const headCells = [
    {
      id: "vatTaxCode",
      numeric: false,
      disablePadding: true,
      label: "VAT Tax Code",
      sort: true,
    },
    {
      id: "vatTaxName",
      numeric: false,
      disablePadding: false,
      label: "Tax Code Name",
      sort: true,
    },
    {
      id: "countryOfDeparture",
      numeric: false,
      disablePadding: false,
      label: "Country of departure",
      sort: true,
    },
    {
      id: "countryOfArrival",
      numeric: false,
      disablePadding: false,
      label: "Country of arrival",
      sort: true,
    },
    {
      id: "taxRate",
      numeric: false,
      disablePadding: false,
      label: "Tax Rate",
      sort: true,
    },
    {
      id: "customerDescription",
      numeric: false,
      disablePadding: false,
      label: "Customer Description",
      sort: false,
    },
    {
      id: "internalDescription",
      numeric: false,
      disablePadding: false,
      label: "Internal Description",
      sort: false,
    },

    {
      disablePadding: false,
      label: "Action",
      sort: false,
    },
  ];
  const SortIndicator = ({ direction, active, colId, orderBy }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2px",
        marginLeft: "10px",
        height: "100%",
      }}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "ASC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="scale(-1,-1)"
        style={{ marginTop: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "DESC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
  return (
    <div className="version-table">
      {/* <Link to="/">
        <h2 className="back-btn">
          <ArrowBackIos />
          Back
        </h2>{" "}
      </Link> */}

      <div className="search-header">
        <TextField
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search className="search-icon" />
              </InputAdornment>
            ),
          }}
          placeholder="Filter by Text and/or Keywords"
        />
      </div>
      {/* <TableContainer component={Paper} sx={{ maxHeight: 440 }}> */}
      <Table
        stickyHeader
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} align={"left"}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  disabled={!headCell.sort}
                  hideSortIcon={!headCell.sort}
                  direction={ascending ? "asc" : "desc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={() => (
                    <SortIndicator
                      direction={ascending ? "ASC" : "DESC"}
                      active={orderBy === headCell.id}
                      colId={headCell.id}
                      orderBy={orderBy}
                    />
                  )}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {taxCodeCountryList && taxCodeCountryList.length > 0 ? (
            taxCodeCountryList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left" scope="row">
                  {row.vatTaxCode}
                </TableCell>
                <TableCell align="left" title={row.vatTaxName}>
                  {trimString(row.vatTaxName)}
                </TableCell>
                <TableCell align="left">
                  <div className="country-flex">
                    <span className="flag">
                      <img
                        alt=""
                        src={checkIfImageExists(row.countryOfDeparture)}
                        width={27}
                      />
                    </span>
                    <span className="title">
                      {getCountryName(row.countryOfDeparture)}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="left">
                  <div className="country-flex">
                    <span className="flag">
                      <img
                        alt=""
                        src={checkIfImageExists(row.countryOfArrival)}
                        width={27}
                      />
                    </span>
                    <span className="title">
                      {getCountryName(row.countryOfArrival)}
                    </span>
                  </div>
                </TableCell>

                <TableCell align="left">{row.taxRate}</TableCell>
                <TableCell align="left">
                  {trimString(row.customerDescription)}
                </TableCell>
                <TableCell align="left">
                  {trimString(row.internalDescription)}
                </TableCell>

                <TableCell align="left">
                  <IconButton onClick={() => deleteTaxCode(row)}>
                    <DeleteIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => setTaxCodeModalValue(row.id, true)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <div className="no-records">
                  {searchedText.length > 0
                    ? "No Tax Codes found matching your search string"
                    : "No Tax Codes have been added so far!"}
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* </TableContainer> */}

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalNumberOfElements}
        rowsPerPage={pagination.size}
        page={pagination.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
      />
      <ConfirmDialog
        open={dialogOpen}
        data={dialogData}
        handleClose={
          dialogData.type === "INVALID_OPERATION_EXCEPTION"
            ? false
            : () => setDialogOpen(false)
        }
        handleOk={() => handleDialogResponse()}
      />
    </div>
  );
}
// const mapStateToProps = (state) => ({
//   overviewList: state.uber.overviewList,
//   loading: state.uber.loading,
// });

export default CountryTable;
