import React from "react";

const CheckBoxIcon = ({ size, color = "#324664" }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1.5h10l-1 2H3v10h4.333l2.667 2h3.005H2a1 1 0 0 1-1-1v-12a1 1 0 0 1 1-1z"
        fill={color}
      />
      <path
        d="M15.425 3.88a1 1 0 0 0-1.85-.76l-3.049 7.405L7.53 8.652a1 1 0 1 0-1.06 1.696l4 2.5a1 1 0 0 0 1.455-.467l3.5-8.5z"
        fill={color}
      />
    </svg>
  );
};

export default CheckBoxIcon;
