import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 1.5c-.963 0-1.959.32-2.727 1.003C4.487 3.202 4 4.229 4 5.5c0 1.054.128 2.465.646 3.65.114.261.25.52.415.765a8.12 8.12 0 0 0-.409.19c-.425.213-.81.58-1.118.941a7.511 7.511 0 0 0-.878 1.292c-.247.46-.46.97-.571 1.466-.105.465-.159 1.086.12 1.643l.228.456.502.083.01.002.027.005.103.016.376.058c.318.048.764.112 1.274.175 1.008.127 2.309.258 3.374.258s2.366-.131 3.374-.258a52.922 52.922 0 0 0 1.65-.233l.103-.016.027-.005.01-.002.503-.083.227-.456c.28-.557.225-1.178.121-1.642a5.91 5.91 0 0 0-.572-1.467 7.508 7.508 0 0 0-.877-1.292c-.308-.361-.693-.728-1.118-.94a6.869 6.869 0 0 0-.566-.255c.146-.226.268-.462.373-.7.518-1.186.646-2.597.646-3.65 0-1.272-.487-2.3-1.273-2.998C9.959 1.82 8.963 1.5 8 1.5zm-2 4c0-.729.263-1.202.602-1.503C6.959 3.68 7.462 3.5 8 3.5c.537 0 1.041.18 1.398.497.339.301.602.774.602 1.503 0 .946-.123 2.035-.479 2.85-.174.397-.383.681-.615.863-.217.17-.498.287-.906.287-.408 0-.69-.117-.906-.287-.232-.182-.441-.466-.615-.864C6.122 7.535 6 6.446 6 5.5zm7.1 9.5.164.986-.165-.986zm-10 0-.165.986.164-.986zm2.446-3.105c.465-.232.733-.313 1.024-.353.311-.042.664-.042 1.314-.042h.215c.777 0 1.195.002 1.547.052.307.044.578.128 1.006.342.075.037.253.17.491.45.224.263.45.594.638.943.165.306.286.6.355.848-.26.038-.571.08-.911.123-.991.124-2.19.242-3.126.242-.936 0-2.135-.118-3.126-.242-.34-.043-.65-.085-.91-.122a4.21 4.21 0 0 1 .354-.85c.188-.348.414-.679.638-.941.239-.28.417-.413.491-.45z"
        fill="#324664"
      />
    </svg>
  );
};

export default UserIcon;
