import "../assets/styles/admin-dashboard.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { IconButton, Typography } from "@mui/material";
import { Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import moment from "moment";
import { range } from "lodash";
import { FormattedMessage } from "react-intl";
import TextField from "@mui/material/TextField";
import { ReactComponent as DateIcon } from "../../../assets/icons/date.svg";


export default function AdminDatePicker({
  messages,
  lang,
  label,
  changeFilter,
  placeholder,
  hasInitial = true,
  dateFormat = "dd/MM/yyyy"
}) {
  const [startDate, setStartDate] = useState(hasInitial ? new Date() : "");
  const years = range(1990, moment().year() + 1);
  const months =
    !lang && !messages
      ? [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
      : [
        messages[lang]["january"],
        messages[lang]["february"],
        messages[lang]["march"],
        messages[lang]["april"],
        messages[lang]["may"],
        messages[lang]["june"],
        messages[lang]["july"],
        messages[lang]["august"],
        messages[lang]["september"],
        messages[lang]["october"],
        messages[lang]["november"],
        messages[lang]["december"]
      ];

  function DatePickerHeader({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative"
        }}
      >
        <IconButton
          aria-label="<"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          style={{ width: "28px", height: "28px" }}
        >
          <ChevronLeftIcon style={{ color: "#00183D" }} />
        </IconButton>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <select
            // value={months[getMonth(date)]}
            value={months[moment(date).month()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            className="admin-datepicker-select select-month"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            // value={getYear(date)}
            value={moment(date).year()}
            onChange={({ target: { value } }) => changeYear(value)}
            className="admin-datepicker-select select-year"
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <IconButton
          aria-label=">"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          style={{ width: "28px", height: "28px" }}
        >
          <ChevronRightIcon style={{ color: "#00183D" }} />
        </IconButton>
      </div>
    );
  }

  function DatePickerFooter() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "4px",
          paddingRight: "4px"
        }}
      >
        <Button
          variant="contained"
          disableElevation
          color="white"
          onClick={() => {
          }}
          className="admin-datepicker-footer-btn"
        >
          Today
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="white"
          onClick={() => {
            console.log("Last 7 dyas");
          }}
          className="admin-datepicker-footer-btn"
        >
          Last 7 Days
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="white"
          onClick={() => {
            console.log("Last 30 Days");
          }}
          className="admin-datepicker-footer-btn"
        >
          Last 30 Days
        </Button>
      </div>
    );
  }

  const CustomInput = ({ value, onClick }) => {
    return (
      <TextField
        fullWidth
        placeholder={placeholder}
        onClick={onClick}
        value={value}

        InputProps={{
          endAdornment: <DateIcon />
        }}
      />
    );
  };

  return (
    <div>
      <Typography
        component="span"
        fontWeight={500}
        style={{
          userSelect: "none",
          fontSize: "16px"
        }}
      >
        {!label ? "" : <FormattedMessage id={label} />}
      </Typography>

      <DatePicker
        renderCustomHeader={DatePickerHeader}
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          changeFilter(date);
        }}
        calendarStartDay={1}
        wrapperClassName={"admin-date-picker"}
        peekNextMonth={false}
        dateFormat={dateFormat}
        customInput={<CustomInput value={startDate} />}
      />
      {/* <DatePickerFooter /> */}
    </div>
  );
}
