import "../assets/styles/admin-dashboard.css";

import React, { useState } from "react";
import {
  TableCell,
  TableRow,
  CircularProgress,
  ButtonGroup,
  Tooltip,
  Button
} from "@mui/material";
import { AUTH_BASE_URL, URLS } from "../../../configuration/urls";
import AccessKeyModal from "./AccessKeyModal";
import { FormattedMessage } from "react-intl";
import { postData, getData, error_codes } from "../../../configuration/api";
import { useSnackbar } from "notistack";
import messages_de from "../../../translations/de.json";
import messages_en from "../../../translations/en.json";
import * as _ from "lodash";
import RolesModal from "./RolesModal";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsUserDetailsEditable,
  setInviteData,
  setTenantDetails,
  setUserRolesList,
  setUsersDataList,
  setUserManagement,
  setIsFetchingUserMgmtData,
  setTenantShops
} from "../../../redux/actions/adminDashboard";
import { useNavigate } from "react-router-dom/dist";
import UsersModal from "./UsersModal/UsersModal";
import AppsIcon from "../../../assets/icons/AppsIcon";
import KeyIcon from "../../../assets/icons/KeyIcon";
import { admin_dashboard_route_strings } from "../../../route_strings";
import { getMerchant } from "../../../services/adminDashboard";
import { ECAxiosAPI } from "../../../configuration/interceptor";
import ConfirmDialog from "../../FileVat/components/ConfirmDialog";

function Row(props) {
  const { row, visibleColumns } = props;

  const navigate = useNavigate();

  const [isGeneratingKeyWarning, setIsGeneratingKeyWarning] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isgeneratingKey, setIsGeneratingKey] = useState(false);
  const [isGettingAPIKey, setIsGettingAPIKey] = useState(false);
  const [isGettingRolesList, setIsGettingRolesList] = useState(false);
  const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);
  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);

  const [isError, setIsError] = useState(false);

  const [keyDetails, setKeyDetails] = useState({
    access_key_id: "",
    access_key_secret: ""
  });

  const [apiCredentials, setApiCredentials] = useState([]);

  const lang = useSelector((state) => state.settings.lang);
  const userRolesList = useSelector(
    (state) => state.adminDashboard.userRolesList
  );

  const messages = {
    de: messages_de,
    en: messages_en
  };

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const getKeyDetails = async (merchantId) => {
    setIsGettingAPIKey(true);
    const res = await getData(URLS.MERCHANT_ACCESS_KEY(merchantId));
    if (error_codes.includes(res)) {
      enqueueSnackbar(messages[lang][`error_${res}`], {
        variant: "error"
      });
    } else {
      setApiCredentials(res);
      setIsOpen(true);
      setIsError(false);
    }
    setIsGettingAPIKey(false);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const createAccessKey = async (merchantId) => {
    setIsGeneratingKey(true);
    const createKey = await ECAxiosAPI.post(
      URLS.CREATE_ACCESS_KEY_ADMIN(merchantId)
    )
      .then((res) => {
        setKeyDetails(res.data);
        setOpenModal(true);
        setIsGeneratingKeyWarning(false);
        // props.getAdminData();
      })
      .catch((err) => {
        enqueueSnackbar(messages[lang][`error_${createKey}`], {
          variant: "error"
        });
      });

    setIsGeneratingKey(false);
  };

  const getAllUserRoles = async () => {
    if (userRolesList.length === 0) {
      setIsGettingRolesList(true);
      await ECAxiosAPI.get(AUTH_BASE_URL + URLS.ALL_USER_ROLES)
        .then(function (res) {
          dispatch(setUserRolesList(res.data));
          setIsRolesModalOpen(true);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    } else if (userRolesList.length > 0) {
      setIsRolesModalOpen(true);
    }
    setIsGettingRolesList(false);
  };

  const getUsers = async () => {
    await ECAxiosAPI.get(URLS.GET_MERCHANT_USERS(props.row.merchantId))
      .then((res) => {
        dispatch(setUsersDataList(res.data));
        setIsUsersModalOpen(true);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const getInviteStatus = async () => {
    dispatch(setInviteData([]));
    await ECAxiosAPI.post(URLS.GET_INVITE_REPORT, {
      merchant_id: props.row.merchantId,
      sortBy: "email",
      sortDirection: "DESC",
      page: 0,
      size: 100,
      invite_status: ["INVITED", "REGISTERED"]
    })
      .then((res) => {
        dispatch(setInviteData(res.data.content));
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const getUserManagementData = async () => {
    dispatch(setIsFetchingUserMgmtData(true));
    let results = [];

    await ECAxiosAPI.get(URLS.GET_MERCHANT_USERS(props.row.merchantId))
      .then((res) => {
        results = [...results, ...res.data];
      })
      .catch((err) => {});

    await ECAxiosAPI.post(
      URLS.GET_INVITE_REPORT,
      {
        merchant_id: props.row.merchantId,
        sortBy: "email",
        sortDirection: "DESC",
        page: 0,
        size: 100,
        invite_status: ["INVITED", "REGISTERED"]
      },
      {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("getToken")}`
        }
      }
    )
      .then((res) => {
        let inviteTransform = res.data.content.map((item, idx) => {
          return {
            id: item.id,
            firstName: item.merchant.name,
            lastName: item.merchant.lastName || "",
            email: item.email,
            status: item.status
          };
        });
        results = [...results, ...inviteTransform];
      })
      .catch((err) => {});

    dispatch(setUserManagement(results));
    dispatch(setIsFetchingUserMgmtData(false));
  };

  const actionButtonStyles = { borderRadius: 0 };
  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    height: "28px",
    borderRadius: "6px"
  };

  return (
    <React.Fragment>
      <TableRow hover tabIndex={-1} key={props.id}>
        {_.some(visibleColumns, { id: "businessPartner" }) && (
          <TableCell>{props.row?.businessPartner}</TableCell>
        )}
        {_.some(visibleColumns, { id: "tenantId" }) && (
          <TableCell>{props.row?.merchantId}</TableCell>
        )}
        {_.some(visibleColumns, { id: "name" }) && (
          <TableCell>{props.row?.name}</TableCell>
        )}
        {_.some(visibleColumns, { id: "legalForm" }) && (
          <TableCell>{props.row?.legalForm}</TableCell>
        )}
        {_.some(visibleColumns, { id: "vatID" }) && (
          <TableCell>{props.row?.vatID}</TableCell>
        )}
        {_.some(visibleColumns, { id: "activated" }) && (
          <TableCell>
            {props.row?.activated === true ? (
              <FormattedMessage id="yes" />
            ) : (
              <FormattedMessage id="no" />
            )}
          </TableCell>
        )}
        {_.some(visibleColumns, { id: "lastUploadedDate" }) && (
          <TableCell>
            {props.row[props.row?.lastUploadedDate] &&
              new Date(props.row[props.row?.lastUploadedDate])
                .toLocaleString(messages[lang]["time_format"])
                .replace(",", "")}
          </TableCell>
        )}
        {_.some(visibleColumns, { id: "dataActuality" }) && (
          <TableCell>
            {props.row[props.row?.dataActuality] &&
              new Date(props.row[props.row?.dataActuality])
                .toLocaleString(messages[lang]["time_format"])
                .replace(",", "")}
          </TableCell>
        )}
        {_.some(visibleColumns, { id: "email" }) && (
          <TableCell>{props.row?.email}</TableCell>
        )}
        {_.some(visibleColumns, { id: "phoneNumber" }) && (
          <TableCell>{props.row?.phoneNumber}</TableCell>
        )}
        {_.some(visibleColumns, { id: "street" }) && (
          <TableCell>{props.row?.street}</TableCell>
        )}
        {_.some(visibleColumns, { id: "houseNumber" }) && (
          <TableCell>{props.row?.houseNumber}</TableCell>
        )}
        {_.some(visibleColumns, { id: "postalCode" }) && (
          <TableCell>{props.row?.postalCode}</TableCell>
        )}
        {_.some(visibleColumns, { id: "city" }) && (
          <TableCell>{props.row?.city}</TableCell>
        )}
        {_.some(visibleColumns, { id: "country" }) && (
          <TableCell>
            <FormattedMessage id={props.row?.country || "nullOrUndefined"} />
          </TableCell>
        )}
        {_.some(visibleColumns, { id: "trialPeriod" }) && (
          <TableCell>{props.row?.trialPeriod}</TableCell>
        )}
        {_.some(visibleColumns, { id: "daysLeftInTrialPeriod" }) && (
          <TableCell>{props.row?.daysLeftInTrialPeriod}</TableCell>
        )}
        {_.some(visibleColumns, { id: "paymentMethod" }) && (
          <TableCell>
            {" "}
            <FormattedMessage
              id={
                _.get(row, "users[0].userSubscriptions[0].paymentMethod") ||
                "nullOrUndefined"
              }
              defaultMessage={""}
            />
          </TableCell>
        )}
        {_.some(visibleColumns, { id: "channelPartner" }) && (
          <TableCell>{props.row?.channelPartner}</TableCell>
        )}
        {_.some(visibleColumns, { id: "product" }) && (
          <TableCell>
            {props.row?.users?.[0]?.userSubscriptions
              ?.map((item) => {
                return item.name;
              })
              ?.join(", ")}
          </TableCell>
        )}
        {_.some(visibleColumns, { id: "subscriptionStart" }) && (
          <TableCell>
            {props.row?.users?.[0]?.userSubscriptions
              ?.map((item) => {
                return new Date(item.startDate)
                  .toLocaleString(messages[lang]["time_format"])
                  .replace(",", "");
              })
              ?.join(", ")}
          </TableCell>
        )}
        {_.some(visibleColumns, { id: "status" }) && (
          <TableCell>
            {props.row?.users?.[0]?.userSubscriptions
              ?.map((item) => {
                return item.status === "ENABLED"
                  ? messages[lang]["active"]
                  : messages[lang]["cancelled"];
              })
              ?.join(", ")}
          </TableCell>
        )}
        <TableCell className="sticky-column admin-table-cell">
          <ButtonGroup
            size="large"
            aria-label="large button group"
            sx={{ alignItems: "center" }}
          >
            {/*<Tooltip title={<FormattedMessage id="manageRoles" />}>*/}
            {/*  <Button*/}
            {/*    color="primary"*/}
            {/*    disableElevation*/}
            {/*    onClick={() => {*/}
            {/*      getAllUserRoles();*/}
            {/*    }}*/}
            {/*    variant="text"*/}
            {/*    style={buttonStyles}*/}
            {/*  >*/}
            {/*    <div style={{ marginRight: "7px" }}>*/}
            {/*      <span*/}
            {/*        style={{*/}
            {/*          fontSize: "14px",*/}
            {/*          color: "var(--future-tax-80)",*/}
            {/*          fontWeight: 500*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {props.row?.users?.[0]?.userGroups.length}*/}
            {/*      </span>*/}
            {/*    </div>*/}
            {/*    <AppsIcon />*/}
            {/*  </Button>*/}
            {/*</Tooltip>*/}
            <Tooltip title={<FormattedMessage id="generateAccessKey" />}>
              <Button
                color="primary"
                variant="text"
                disableElevation
                style={buttonStyles}
                disabled={isgeneratingKey}
                onClick={() => {
                  setIsGeneratingKeyWarning(true);
                  // createAccessKey(props.row.merchantId);
                }}
              >
                {isgeneratingKey ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  <KeyIcon />
                )}
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="manageUsers" />}>
              <Button
                color="primary"
                disableElevation
                onClick={() => {
                  dispatch(setTenantDetails(props.row));
                  // dispatch(setIsUserDetailsEditable(true));
                  getUsers();
                  getInviteStatus();
                }}
                variant="text"
                style={buttonStyles}
              >
                <div style={{ marginRight: "6px" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "var(--future-tax-80)",
                      fontWeight: 500
                    }}
                  >
                    {props.row?.numberOfInvites + props.row?.numberOfUsers}
                  </span>
                </div>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 1.5c-.963 0-1.959.32-2.727 1.003C4.487 3.202 4 4.229 4 5.5c0 1.054.128 2.465.646 3.65.114.261.25.52.415.765a8.12 8.12 0 0 0-.409.19c-.425.213-.81.58-1.118.941a7.511 7.511 0 0 0-.878 1.292c-.247.46-.46.97-.571 1.466-.105.465-.159 1.086.12 1.643l.228.456.502.083.01.002.027.005.103.016.376.058c.318.048.764.112 1.274.175 1.008.127 2.309.258 3.374.258s2.366-.131 3.374-.258a52.922 52.922 0 0 0 1.65-.233l.103-.016.027-.005.01-.002.503-.083.227-.456c.28-.557.225-1.178.121-1.642a5.91 5.91 0 0 0-.572-1.467 7.508 7.508 0 0 0-.877-1.292c-.308-.361-.693-.728-1.118-.94a6.869 6.869 0 0 0-.566-.255c.146-.226.268-.462.373-.7.518-1.186.646-2.597.646-3.65 0-1.272-.487-2.3-1.273-2.998C9.959 1.82 8.963 1.5 8 1.5zm-2 4c0-.729.263-1.202.602-1.503C6.959 3.68 7.462 3.5 8 3.5c.537 0 1.041.18 1.398.497.339.301.602.774.602 1.503 0 .946-.123 2.035-.479 2.85-.174.397-.383.681-.615.863-.217.17-.498.287-.906.287-.408 0-.69-.117-.906-.287-.232-.182-.441-.466-.615-.864C6.122 7.535 6 6.446 6 5.5zm7.1 9.5.164.986-.165-.986zm-10 0-.165.986.164-.986zm2.446-3.105c.465-.232.733-.313 1.024-.353.311-.042.664-.042 1.314-.042h.215c.777 0 1.195.002 1.547.052.307.044.578.128 1.006.342.075.037.253.17.491.45.224.263.45.594.638.943.165.306.286.6.355.848-.26.038-.571.08-.911.123-.991.124-2.19.242-3.126.242-.936 0-2.135-.118-3.126-.242-.34-.043-.65-.085-.91-.122a4.21 4.21 0 0 1 .354-.85c.188-.348.414-.679.638-.941.239-.28.417-.413.491-.45z"
                    fill="#324664"
                  />
                </svg>
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="tenantDetails" />}>
              <Button
                variant="text"
                color="primary"
                disableElevation
                style={buttonStyles}
                onClick={() => {
                  userRolesList.length === 0 && getAllUserRoles();
                  getUserManagementData();
                  const data = {
                    ...props.row,
                    paymentMethod:
                      props.row.users[0]?.userSubscriptions[0]?.paymentMethod ||
                      "-"
                  };
                  dispatch(setTenantDetails(data));
                  dispatch(setTenantShops([]));
                  getMerchant(props.row.merchantId);
                  navigate(
                    admin_dashboard_route_strings.tenantDetails_dynamic(
                      props.row.merchantId
                    )
                  );
                }}
              >
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ opacity: 1 }}
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.819 13.849a1.035 1.035 0 0 1 0-1.518L9.866 9.5H3a1 1 0 1 1 0-2h6.867L6.818 4.67a1.035 1.035 0 0 1 1.408-1.518l4.982 4.615a1 1 0 0 1 0 1.467l-4.982 4.615a1.035 1.035 0 0 1-1.407 0z"
                    fill="#324664"
                  />
                </svg>
              </Button>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
      </TableRow>
      <AccessKeyModal
        modalOpen={openModal}
        setModalOpen={setOpenModal}
        keyDetails={keyDetails}
      />
      <RolesModal
        modalOpen={isRolesModalOpen}
        setModalOpen={setIsRolesModalOpen}
        assignedRoles={props.row?.users?.[0]?.role}
        getAdminData={props.getAdminData}
        row={props.row}
      />
      <UsersModal
        modalOpen={isUsersModalOpen}
        setModalOpen={setIsUsersModalOpen}
        row={props.row}
        getAdminData={props.getAdminData}
      />
      <ConfirmDialog
        open={isGeneratingKeyWarning}
        data={{
          title: <FormattedMessage id="warning" />,
          content: <FormattedMessage id="generateNewAccessKey" />
        }}
        handleClose={() => {
          setIsGeneratingKeyWarning(false);
        }}
        handleOk={() => {
          createAccessKey(props.row.merchantId);
        }}
        isLoading={isgeneratingKey}
      />
    </React.Fragment>
  );
}

export default Row;
