import React, { useEffect, useState, Children } from "react";
import { Box } from "@mui/system";
import { Modal, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RolesSwitch from "./RolesSwitch";
import { ModalHeader } from "../../../components/SectionHeader/SectionHeaders";
import * as _ from "lodash";
import { FormattedMessage } from "react-intl";
import TableComponent from "./Table/Table";
import Typography from "@mui/material/Typography";
import { groupItemsByProductType } from "../../../configuration/helpers";

const Row = ({ row, data, setHasMadeApiCall }) => {
  return (
    <TableRow
      key={row.id} hover
    >
      <TableCell
        sx={{
          borderBottom: "solid 1px var(--future-tax-8)"
        }}
      >
        {row.name}
      </TableCell>
      <TableCell
        align="left"
        sx={{
          borderBottom: "solid 1px var(--future-tax-8)",
          width: "15%"
        }}
      >
        <RolesSwitch
          name={row.name}
          row={data}
          isDefaultChecked={row.isChecked}
          setHasMadeApiCall={setHasMadeApiCall}
        />
      </TableCell>
    </TableRow>
  );
};

const RolesModal = ({
  modalOpen,
  setModalOpen,
  getAdminData,
  row,
  userGroups,
  onClose
}) => {
  const userRolesList = useSelector(
    (state) => state.adminDashboard.userRolesList
  );
  const dataFilters = useSelector((state) => state.adminDashboard.dataFilters);
  const pagination = useSelector((state) => state.adminDashboard.pagination);
  const dashboardOrder = useSelector((state) => state.adminDashboard.order);

  const [switches, setSwitches] = useState([]);
  const [hasMadeApiCall, setHasMadeApiCall] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("service");

  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const sortData = (data, order, orderBy) => {
    const orderByProperty = orderBy === "service" ? "name" : orderBy;
    if (order === "ASC") {
      return _.sortBy(data, [(obj) => _.toLower(obj[orderByProperty])]);
    } else {
      return _.sortBy(data, [
        (obj) => _.toLower(obj[orderByProperty])
      ]).reverse();
    }
  };

  useEffect(() => {
    const roles = userRolesList?.map((role, idx) => {
      const isChecked = userGroups
        ? userGroups.some((assignedRole) => assignedRole.id === role.id)
        : row?.users?.[0]?.userGroups?.some(
          (assignedRole) => assignedRole.id === role.id
        );
      return {
        id: idx,
        ...role,
        isChecked: isChecked,
        isMakingApiCall: false
      };
    });
    setSwitches(sortData(roles, order, orderBy));
  }, [userRolesList, modalOpen]);


  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isTabletScreen ? "85%" : isMobileScreen ? "95%" : "800px",
    bgcolor: "infoLight.main",
    borderRadius: "6px",
    overflow: "hidden"
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    hasMadeApiCall &&
    (getAdminData
      ? getAdminData(dataFilters, pagination, dashboardOrder)
      : onClose());
    setHasMadeApiCall(false);
  };

  const columns = [
    { id: "service", label: "service", align: "left", sortLabel: false },
    { id: "active", label: "active", align: "left", sortLabel: false }
  ];

  useEffect(() => {
    setSwitches(sortData(switches, order, orderBy));
  }, [order, orderBy]);

  const handleRequestSort = (property, order) => {
    setOrder(order);
    setOrderBy(property);
  };


  const groupItemsByProductTypeEntries = Object.entries(groupItemsByProductType(switches));

  return (
    <Modal
      open={modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <ModalHeader
          onClose={handleCloseModal}
          title={
            <FormattedMessage
              id="rolesModal.title"
              values={{
                b: (...chunks) => (
                  <span>
                    {row.firstName} {row.lastName}
                  </span>
                )
              }}
            />
          }
          tooltipContent={
            <FormattedMessage
              id="rolesModal.title"
              values={{
                b: (...chunks) => (
                  <span>
                    {row.firstName} {row.lastName}
                  </span>
                )
              }}
            />
          }
        />
        <Box
          style={{
            overflow: "scroll",
            minHeight: "40vh",
            maxHeight: "70vh"
          }}
        >
          {
            Children.toArray(
              groupItemsByProductTypeEntries?.map(item => {
                return (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        backgroundColor: "var(--future-tax-8)",
                        padding: "5px 10px"
                      }}
                    >
                      {item?.[0]}
                    </Typography>
                    <TableComponent
                      rows={item?.[1] || []}
                      columns={columns}
                      RowComponent={Row}
                      rowProps={{
                        data: row,
                        setHasMadeApiCall
                      }}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      tableContainerStyle={{
                        // padding: "1px 1rem",
                        maxHeight: "calc(100vh - 20rem)"
                      }}
                    />
                  </>
                );
              })
            )
          }
        </Box>
      </Box>
    </Modal>
  );
};

export default RolesModal;
