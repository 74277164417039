import React, { useEffect, useState } from "react";
import { Card, CardHeader, IconButton, Tooltip } from "@mui/material";
import {
  AddCircleOutline,
  ArrowBackIos,
  ArrowForward,
  InfoOutlined,
} from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { fileVatActions } from "../../redux/actions/filevat";
import TaxCodeTable from "./components/TaxCodeTable";
import SelectModal from "./components/SelectModal";
import { useSelector } from "react-redux";
export default function TaxCodeList() {
  const { taxCodeList, loading, } = useSelector((state) => state.fileVat);
  const [selectModalState, setSelectModalState] = useState({
    isSelectModalVisible: false,
    type: "taxCode",
  });
  const setSelectModalValue = (type, isSelectModalVisible) => {
    setSelectModalState({
      type,
      isSelectModalVisible,
    });
  };
  const navigate = useNavigate();
  const addSelectedOption = (selectedOption) => {
    navigate(
      `/filevat-tax-code-detail/${selectedOption.country_code.toLowerCase()}`
    );
  };

  // useEffect(() => {
  //   setCountryTaxList(taxCodeList);
  // }, [taxCodeList]);
  return (
    <>
      <Card sx={{ minWidth: 275 }} className="card version-list-card">
        <CardHeader
          className="header"
          action={
            <IconButton onClick={() => setSelectModalValue("taxCode", true)}>
              <AddCircleOutline className="icon" />
            </IconButton>
          }
          title={
            <div className="country-flex">
              {/* <span className="flag">
                <img alt="" src={checkIfImageExists(country)} width={30} />
              </span> */}
              <span className="title">Tax Codes Country Overview</span>
              {/* <span className="text-gray ml-1"> Version</span> */}
              <Tooltip title={"information"} arrow>
                <InfoOutlined className="info-icon" />
              </Tooltip>
            </div>
          }
        />
        <TaxCodeTable />
      </Card>

      {selectModalState.isSelectModalVisible && (
        <SelectModal
          isSelectModalVisible={selectModalState.isSelectModalVisible}
          selectModalType={selectModalState.type}
          setSelectModalValue={setSelectModalValue}
          addSelectedOption={addSelectedOption}
          title="Country"
          selectedTaxItem={taxCodeList.map(e=>e.countryCode)}
          placeholder={" Search the country to add tax types"}
        />
      )}
    </>
  );
}
