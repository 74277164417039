import React, { useState, useMemo, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  InputLabel,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  Close,
  RadioButtonUnchecked,
  RadioButtonChecked,
  DoneOutline,
  WarningAmber,
  AddCircleOutline,
} from "@mui/icons-material";
import { isEmpty } from "lodash";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import countries from "../../../configuration/countries.json";
// import { ReactComponent as EditText } from "../../../assets/icons/editText.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as PercentageIcon } from "../../../assets/icons/percentage.svg";
import { useDispatch, useSelector } from "react-redux";
import { fileVatActions } from "../../../redux/actions/filevat";
import { useParams } from "react-router-dom";
import { checkIfImageExists, getCountryName } from "../common/helper";
import ConfirmDialog from "./ConfirmDialog";
const initialValues = {
  vatTaxCode: "",
  vatTaxName: "",
  countryOfDeparture: "none",
  countryOfArrival: "none",
  taxRate: "",
  customerDescription: "",
  internalDescription: "",
};
export default function TaxCodeModal({
  setTaxCodeModalValue,
  isTaxCodeModalVisible,
  id,
}) {
  const { taxCodeItem, taxCodeCountryList, loading, taxCodeCreated } =
    useSelector((state) => state.fileVat);
  const [taxCodeInput, setTaxCodeInput] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [addOtherTaxCode, setAddOtherTaxCode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    type: "",
    content: "",
  });

  const dispatch = useDispatch();
  const { country } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fileVatActions.getTaxCode({ id }));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(taxCodeCreated).length > 0) {
      if (addOtherTaxCode) {
        setTaxCodeInput(initialValues);
        setErrors({});
      } else {
        resetAll();
      }
    }
    setAddOtherTaxCode(false);
  }, [taxCodeCreated]);

  useEffect(() => {
    if (id && Object.keys(taxCodeItem).length > 0) {
      setTaxCodeInput(taxCodeItem);
    }
  }, [taxCodeItem]);

  const onCancel = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    setDialogOpen(true);
    setDialogData({
      type: "cancel",
      title: "Are you sure that you want to discard the data?",
      content: "This cannot be undone!",
    });
  };
  const handleDialogResponse = () => {
    setDialogOpen(false);
    switch (dialogData.type) {
      case "cancel":
        resetAll();
        break;
      default:
        break;
    }
  };
  const resetAll = () => {
    setTaxCodeModalValue("", false);
    setTaxCodeInput(initialValues);
    setErrors({});
  };

  function handleInputChange(e) {
    const { name, value } = e.target;
    setTaxCodeInput((taxCodeInput) => ({ ...taxCodeInput, [name]: value }));
    validateTaxCodeFields({ ...taxCodeInput, [name]: value });
  }
  const validateTaxCodeFields = (taxCodeInput) => {
    const message = {};
    if (
      taxCodeInput.vatTaxCode !== undefined &&
      !taxCodeInput.vatTaxCode.trim()
    ) {
      message.vatTaxCode = "The Tax Code must not be empty";
    }
    if (
      taxCodeInput.vatTaxCode !== undefined &&
      taxCodeInput.vatTaxCode.trim() &&
      taxCodeCountryList.length > 0
    ) {
      let result = taxCodeCountryList.find(
        (e) =>
          e.vatTaxCode.toUpperCase() ===
            taxCodeInput.vatTaxCode.toUpperCase() &&
          (id ? e.id !== taxCodeInput.id : true)
      );
      if (!isEmpty(result)) {
        message.vatTaxCode = "The Tax Code is already added";
      }
    }
    if (
      taxCodeInput.vatTaxName !== undefined &&
      !taxCodeInput.vatTaxName.trim()
    ) {
      message.vatTaxName = "The name must not be empty";
    }
    if (
      taxCodeInput.customerDescription !== undefined &&
      !taxCodeInput.customerDescription.trim()
    ) {
      message.customerDescription = "The description must not be empty";
    }

    const decimalCheck =/^(?:(100(?:\.00?)?)|(\d{1,2}\.\d{2}))?$/;

    if (!decimalCheck.test(taxCodeInput.taxRate)) {
      message.taxRate = "Must be decimal with max 2 decimal places";

    }

    setErrors(message);
    return message;
  };
  const handleSubmitForm = (addAnotherTaxCode = false) => {
    const validationRes = validateTaxCodeFields(taxCodeInput);
    if (Object.keys(validationRes).length === 0) {
      setAddOtherTaxCode(addAnotherTaxCode);
      dispatch(
        fileVatActions.createTaxCode({
          ...taxCodeInput,
          countryCode: country,
          countryName: getCountryName(country),
        })
      );
      // }
    }
  };
  const handleUpdateForm = () => {
    const validationRes = validateTaxCodeFields(taxCodeInput);
    if (Object.keys(validationRes).length === 0) {
      dispatch(fileVatActions.updateTaxCode(taxCodeInput));
      // }
    }
  };

  function renderPrimaryButton() {
    const disableUploadBtn = loading || Object.keys(errors).length !== 0;

    return [
      <Button disabled={loading} key={"cancel"} onClick={() => onCancel()}>
        Cancel
      </Button>,

      id ? (
        <Button
          style={{ marginRight: "10px" }}
          className="upload-btn"
          variant="contained"
          onClick={() => handleUpdateForm()}
          disabled={disableUploadBtn}
        >
          Update Tax Code
        </Button>
      ) : (
        <div>
          <Button
            style={{ marginRight: "10px" }}
            className="upload-btn"
            variant="contained"
            onClick={() => handleSubmitForm(true)}
            disabled={disableUploadBtn}
            startIcon={<AddCircleOutline size={24} />}
          >
            add Tax Code & another one
          </Button>
          <Button
            className="upload-btn"
            variant="contained"
            onClick={() => handleSubmitForm()}
            disabled={disableUploadBtn}
            startIcon={<AddCircleOutline size={24} />}
          >
            add Tax Code
          </Button>
        </div>
      ),
    ];
  }
  // "id": 0,
  // "vatTaxCode": "string",
  // "vatTaxName": "string",
  // "countryOfDeparture": "string",
  // "countryOfArrival": "string",
  // "taxRate": "string",
  // "customerDescription": "string",
  // "internalDescription": "string"
  function renderModalContent() {
    return (
      <div className="form-fields">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <InputLabel>
              VAT TAX Code {getCountryName(country)} ({country.toUpperCase()}){" "}
            </InputLabel>

            <TextField
              error={errors.vatTaxCode}
              fullWidth
              placeholder="Enter the TAX Code"
              name="vatTaxCode"
              inputProps={{
                maxLength: 25,
              }}
              onChange={handleInputChange}
              value={taxCodeInput.vatTaxCode}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {country.toUpperCase()} -{" "}
                  </InputAdornment>
                ),
                endAdornment: <EditIcon />,
              }}
              helperText={errors.vatTaxCode}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>TAX Code Name</InputLabel>
            <TextField
              error={errors.vatTaxName}
              fullWidth
              placeholder="Enter a TAX Code Name"
              name="vatTaxName"
              inputProps={{
                maxLength: 200,
              }}
              onChange={handleInputChange}
              value={taxCodeInput.vatTaxName}
              InputProps={{
                endAdornment: <EditIcon />,
              }}
              helperText={errors.vatTaxName}
            ></TextField>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Country of departure</InputLabel>
            <Select
              id="select-country"
              name="countryOfDeparture"
              fullWidth
              onChange={handleInputChange}
              value={taxCodeInput.countryOfDeparture}
            >
              <MenuItem value="none" disabled>
                Select a Country
              </MenuItem>
              {countries.map((item) => (
                <MenuItem value={item.country_code}>
                  <div className="country-flex">
                    <span className="flag" style={{ maxHeight: "28px" }}>
                      <img
                        alt=""
                        src={checkIfImageExists(item.country_code)}
                        width={30}
                      />
                    </span>
                    <span className="title">
                      {item.country_code} - {item.country_name}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Country of arrival</InputLabel>
            <Select
              id="select-country"
              name="countryOfArrival"
              fullWidth
              onChange={handleInputChange}
              value={taxCodeInput.countryOfArrival}
            >
              <MenuItem value="none" disabled>
                Select a Country
              </MenuItem>
              {countries.map((item) => (
                <MenuItem value={item.country_code}>
                  <div className="country-flex">
                    <span className="flag" style={{ maxHeight: "28px" }}>
                      <img
                        alt=""
                        src={checkIfImageExists(item.country_code)}
                        width={30}
                      />
                    </span>
                    <span className="title">
                      {item.country_code} - {item.country_name}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <InputLabel>Tax rate</InputLabel>
            <TextField
              error={errors.taxRate}
              fullWidth
              placeholder="Enter a percentage"
              name="taxRate"
              // type="number"
              onChange={handleInputChange}
              value={taxCodeInput.taxRate}
              InputProps={{
                endAdornment: <PercentageIcon />,
              }}
              helperText={errors.taxRate}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Customer description</InputLabel>

            <TextField
              error={errors.customerDescription}
              className="multi-row-text"
              fullWidth
              placeholder="Please Enter Text"
              name="customerDescription"
              value={taxCodeInput.customerDescription}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 5000,
              }}
              multiline
              rows={3}
              helperText={
                errors.customerDescription
                  ? errors.customerDescription
                  : `${
                      taxCodeInput.customerDescription
                        ? taxCodeInput.customerDescription.length
                        : "0"
                    }/${5000} characters`
              }
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Internal description</InputLabel>

            <TextField
              className="multi-row-text"
              fullWidth
              placeholder="Please Enter Text"
              name="internalDescription"
              value={taxCodeInput.internalDescription}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 5000,
              }}
              multiline
              rows={3}
              helperText={`${
                taxCodeInput.internalDescription
                  ? taxCodeInput.internalDescription.length
                  : "0"
              }/${5000} characters`}
            ></TextField>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <>
      <Dialog
        open={isTaxCodeModalVisible}
        fullWidth={true}
        maxWidth={"md"}
        onClose={onCancel}
        // style={{ maxHeight: "max-content" }}
        className="tax-modal"
        style={{ minHeight: "500px" }}
      >
        <div className="dialog-title">
          <div className="country-flex">
            <span className="flag">
              <img alt="" src={checkIfImageExists(country)} width={30} />
            </span>
            <span className="title">
              {" "}
              <b>
                {getCountryName(country)} - {id ? "Edit" : "Addition of"} Tax
                Code
              </b>
            </span>
          </div>

          {isTaxCodeModalVisible ? (
            <IconButton
              // disabled={loading}
              aria-label="close"
              onClick={() => onCancel()}
              sx={{
                position: "absolute",
                right: 7,
                top: 5,
              }}
            >
              <Close className="close-icon" />
            </IconButton>
          ) : null}
        </div>
        <DialogContent dividers style={{ padding: "0px" }}>
          {isTaxCodeModalVisible && renderModalContent()}
        </DialogContent>

        <DialogActions className="dialog-footer">
          {renderPrimaryButton()}
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={dialogOpen}
        data={dialogData}
        handleClose={() => setDialogOpen(false)}
        handleOk={() => handleDialogResponse()}
      />
    </>
  );
}
