import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.707 10.293 11 9.586 9.586 11l.707.707 1.414-1.414zm1.586 4.414a1 1 0 0 0 1.414-1.414l-1.414 1.414zm-3-3 3 3 1.414-1.414-3-3-1.414 1.414z"
        fill="#000E25"
      />
      <circle cx="7" cy="7" r="5" stroke="#000E25" stroke-width="2" />
    </svg>
  );
};

export default SearchIcon;
