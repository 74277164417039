import "./assets/styles/admin-dashboard.css";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  TablePagination,
  Collapse,
  Divider,
  Button,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import Row from "./components/Row";
import messages_de from "../../translations/de.json";
import messages_en from "../../translations/en.json";
import { useSelector } from "react-redux";
import Checkbox from "./components/Checkbox";
import DataFilters from "./components/DataFilters";
import { URLS, getBaseURL } from "../../configuration/urls";
import { error_codes } from "../../configuration/api";
import { columns, checkboxes } from "./dashboard-table-columns";
import { useSnackbar } from "notistack";
import InfoToolTip from "./components/InfoToolTip";
import { useDispatch } from "react-redux";
import {
  setDashboardData,
  setPagination,
  setOrder,
} from "../../redux/actions/adminDashboard";
import TableComponent from "./components/Table/Table";
import { ECAxiosAPI } from "../../configuration/interceptor";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

function AdminDashboard(props) {
  const [filterType, setFilterType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const history = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const tableData = useSelector((state) => state.adminDashboard.dashboardData);
  const pagination = useSelector((state) => state.adminDashboard.pagination);
  const order = useSelector((state) => state.adminDashboard.order);
  const [isEmpty, setIsEmpty] = useState(false);

  const lang = useSelector((state) => state.settings.lang);

  const messages = {
    de: messages_de,
    en: messages_en,
  };

  // function to format the search query if there is no data in a field it will not add it the search query
  function formatQ(q) {
    let newQ = "";
    for (const key in q) {
      if (q[key]) {
        newQ += `&${key}=${q[key]}`;
      }
    }
    return newQ.includes("+") ? newQ.replace("+", "%2B") : newQ;
  }

  async function getAdminData(dataFilterValues, pagination, order) {
    console.log("Getting admin data");
    setIsFetchingData(true);
    let data = await ECAxiosAPI.get(
      `${URLS.EC_ADMIN}?sortBy=${order?.orderBy}&sortDirection=${
        order.direction
      }&page=${pagination.page}&size=${pagination.rowsPerPage}${formatQ(
        dataFilterValues
      )}`
    )
      .then((res) => {
        dispatch(setDashboardData(res.data.content));
        dispatch(
          setPagination({
            page: pagination.page,
            rowsPerPage: pagination.rowsPerPage,
            totalRecords: res.data?.totalElements,
          })
        );
      })
      .catch((err) => {
        setIsEmpty(true);
        enqueueSnackbar(messages[lang][`error`], {
          variant: "error",
        });
      });
    setIsFetchingData(false);
  }

  useEffect(() => {
    tableData.length === 0 && getAdminData({}, pagination, order);
  }, []);

  const [columnFilters, setColumnFilters] = useState({
    businessPartner: true,
    tenantId: true,
    name: true,
    legalForm: true,
    vatID: true,
    // createdDate: true,
    activated: true,
    lastUploadedDate: true,
    dataActuality: true,
    email: true,
    phoneNumber: true,
    street: true,
    houseNumber: true,
    postalCode: true,
    city: true,
    country: true,
    trialPeriod: true,
    paymentMethod: true,
    channelPartner: true,
    product: true,
    subscriptionStart: true,
    status: true,
    actions: true,
  });

  const handleChangePage = (event, newPage) => {
    dispatch(
      setPagination({
        ...pagination,
        page: newPage,
      })
    );
    getAdminData(
      {},
      {
        ...pagination,
        page: newPage,
      },
      order
    );

    const query = {
      ...queryParams,
      page: newPage,
    };
    history(`?${queryString.stringify(query)}`);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      setPagination({
        ...pagination,
        rowsPerPage: +event.target.value,
      })
    );
    getAdminData(
      {},
      {
        ...pagination,
        rowsPerPage: +event.target.value,
      },
      order
    );

    const query = {
      ...queryParams,
      rows: +event.target.value,
    };
    history(`?${queryString.stringify(query)}`);
  };

  const handleRequestSort = (property, order) => {
    dispatch(
      setOrder({
        direction: order,
        orderBy: property,
      })
    );
    getAdminData({}, pagination, {
      direction: order,
      orderBy: property,
    });
  };

  const setColumns = (name) => {
    let arr = [];
    for (let key in columnFilters) {
      if (columnFilters[key] === true) {
        columns.filter((item) => {
          if (item.id === key) {
            arr.push(item);
          }
        });
      }
    }
    setVisibleColumns(arr);
  };

  const LoadingPagination = () => {
    return (
      <div
        style={{
          display: "flex",
          columnGap: 20,
          width: "100%",
          justifyContent: "flex-end",
          padding: "16px",
        }}
      >
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={160}
          height={36}
          style={{ borderRadius: "6px" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={120}
          height={36}
          style={{ borderRadius: "6px" }}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={70}
          height={36}
          style={{ borderRadius: "6px" }}
        />
      </div>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          background: "#fff",
          borderRadius: "8px",
          boxShadow:
            " 2px 1px 2px 0 rgba(0, 0, 0, 0.06), -1px -1px 1px 0 rgba(255, 255, 255, 0.6), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.6), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.08)",
        }}
      >
        <Box sx={{ padding: "10px 16px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" sx={{ fontSize: "20px" }}>
                <FormattedMessage id="Overview of Tenants" />
              </Typography>
              <InfoToolTip
                opacity={1}
                msg={<FormattedMessage id="infoAboutSpotTenants" />}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Tooltip title={<FormattedMessage id="createNewTenant" />}>
                <IconButton
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    window.open(getBaseURL() + "/signup/?type=invite");
                  }}
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 14.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12zm0 2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-10a1 1 0 0 1 2 0v1h1a1 1 0 1 1 0 2H9v1a1 1 0 1 1-2 0v-1H6a1 1 0 0 1 0-2h1v-1z"
                      fill="#000E25"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title={<FormattedMessage id="columns" />}>
                <IconButton
                  onClick={() =>
                    filterType === "columnFilter"
                      ? setFilterType("")
                      : setFilterType("columnFilter")
                  }
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#snbeoz5v0a)">
                      <path fill="#F2F4F7" d="M0 .5h16v16H0z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 2.5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v5.171a2.992 2.992 0 0 0-2 0V3.5h-3v7.042a2.992 2.992 0 0 0-2 1.3V3.5H7v10h1.5c0 .727.265 1.444.763 2H1a1 1 0 0 1-1-1v-12zm5 1H2v10h3v-10z"
                        fill="#000E25"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.447 9.606a1 1 0 0 1 .447 1.341l-2 4a1 1 0 0 1-1.449.385l-1.5-1a1 1 0 0 1 1.11-1.664l.557.372 1.494-2.987a1 1 0 0 1 1.341-.447z"
                        fill="#000E25"
                      />
                    </g>
                    <defs>
                      <clipPath id="snbeoz5v0a">
                        <path
                          fill="#fff"
                          transform="translate(0 .5)"
                          d="M0 0h16v16H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </IconButton>
              </Tooltip>
              <Tooltip title={<FormattedMessage id="filterData" />}>
                <IconButton
                  onClick={() =>
                    filterType === "dataFilter"
                      ? setFilterType("")
                      : setFilterType("dataFilter")
                  }
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 4.5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H2a1 1 0 0 1-1-1zm2 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm3 3a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2H6z"
                      fill="#000E25"
                    />
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </Box>
        <Divider />
        <Collapse in={filterType === "columnFilter"}>
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              borderRadius: "10px",
              padding: "0px 24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 0px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>
                <FormattedMessage id="columns" />
              </Typography>
              <Button
                sx={{ fontSize: "14px" }}
                onClick={() => setFilterType("")}
              >
                <FormattedMessage id="closeColumnSelection" />
              </Button>
            </Box>
            <Grid container spacing={3}>
              {checkboxes.map((name, idx) => {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={idx}>
                    <Box
                      sx={{
                        height: "44px",
                        padding: "8px 10px",
                        backgroundColor: "var(--future-tax-8)",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "6px",
                      }}
                    >
                      <Checkbox
                        name={name}
                        setState={setColumnFilters}
                        state={columnFilters}
                        setColumns={() => setColumns(name)}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: "10px" },
                        }}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Collapse>

        <Collapse in={filterType === "dataFilter"}>
          <DataFilters getAdminData={getAdminData} />
        </Collapse>
        <Paper
          elevation={0}
          sx={{
            width: "100%",
          }}
        >
          <TableComponent
            loadingRows={pagination.rowsPerPage || parseInt(queryParams.rows)}
            withSearch
            rows={tableData}
            columns={visibleColumns}
            RowComponent={Row}
            rowProps={{
              getAdminData: getAdminData,
            }}
            order={order.direction}
            orderBy={order.orderBy}
            tableContainerStyle={{
              backgroundColor: "var(--future-tax-0)",
              maxHeight: "calc(100vh - 22rem)",
            }}
            loading={isFetchingData}
            isEmpty={isEmpty}
            onRequestSort={handleRequestSort}
          />

          {isFetchingData ? (
            <LoadingPagination />
          ) : (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={pagination.totalRecords}
              rowsPerPage={pagination.rowsPerPage}
              page={pagination.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              getItemAriaLabel={() => {
                return "goto row number";
              }}
              labelDisplayedRows={(from = pagination.page) =>
                `${from.from}-${from.to === -1 ? from.count : from.to} ${
                  messages[lang]["of"]
                } ${from.count}`
              }
              labelRowsPerPage={messages[lang]["rowsPerPage"]}
              disabled={isFetchingData}
            />
          )}
        </Paper>
      </Box>
    </Box>
  );
}

export default AdminDashboard;
