import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React from "react";

export default function InfoToolTip({ msg, opacity = 1, infoIconSize = 16 }) {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <HtmlTooltip title={msg}>
      <IconButton
        aria-label={msg}
        size="small"
        style={{
          opacity: opacity,
        }}
      >
        <svg
          width={infoIconSize}
          height={infoIconSize}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm2 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM7 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm0 3a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V8z"
            fill="var(--future-tax-140)"
          />
        </svg>
      </IconButton>
    </HtmlTooltip>
  );
}
