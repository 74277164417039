import { useNavigate } from "react-router-dom";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import "./styles.css";
import { Box, Divider, Link } from "@mui/material";
import { FormattedMessage } from "react-intl";

const Forbidden = ({ homePageRoute = "/" }) => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          marginBottom: "30px",
          background: "white",
          boxShadow:
            " 2px 1px 2px 0 rgba(0, 0, 0, 0.06), -1px -1px 1px 0 rgba(255, 255, 255, 0.6), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.6), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: "6px",
        }}
      >
        <Box style={{ padding: "16px" }}>
          <SectionHeader
            title={<FormattedMessage id="accessForbidden" />}
            withInfo={false}
          />
        </Box>
        <Divider />
        <Box style={{ padding: "16px" }}>
          <Link component="button" onClick={() => navigate(homePageRoute)}>
            <FormattedMessage id="backToHomepage" />
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default Forbidden;
