import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, Divider, Grid, Tooltip } from "@mui/material";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import { InfoItem, Input } from "../../components/Input";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import { useParams } from "react-router-dom/dist";
import { enqueueSnackbar } from "notistack";
import { Dropdown } from "../../../../components/Dropdown/Dropdown";
import { URLS } from "../../../../configuration/urls";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import { getAllData } from "../../../../services/adminDashboard";
import * as _ from "lodash";
import { ECAxiosAPI } from "../../../../configuration/interceptor";
import { FormattedMessage } from "react-intl";

const UserDetails = () => {
  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );
  const usersDataList = useSelector(
    (state) => state.adminDashboard.usersDataList
  );
  const isUserDetailsEditable = useSelector(
    (state) => state.adminDashboard.isUserDetailsEditable
  );

  const userRolesList = useSelector(
    (state) => state.adminDashboard.userRolesList
  );

  const { merchantId, userId } = useParams();
  const [userDetails, setUserDetails] = useState(
    usersDataList.filter((item) => {
      return item.id === userId;
    })[0]
  );
  const initFormDetails = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    roles: {
      fileVat: "",
      clearVat: "",
      vatRules: "",
      checkVat: "",
      clearCustoms: "",
      inventoryAssignment: ""
    }
  };
  const [formData, setFormData] = useState(initFormDetails);

  useEffect(() => {
    _.isEmpty(tenantDetails) && getAllData({ merchantId });
  }, []);

  useEffect(() => {
    setUserDetails(
      usersDataList.filter((item) => {
        return item.id === userId;
      })[0]
    );
  }, [usersDataList]);

  const breadcrumbs = [
    {
      label: <FormattedMessage id="Tenants" />,
      link: admin_dashboard_route_strings.adminDashboard
    },
    {
      label: tenantDetails.name,
      link: admin_dashboard_route_strings.tenantDetails_dynamic(merchantId)
    },
    {
      label: <FormattedMessage id="userManagement" />,
      link: admin_dashboard_route_strings.userManagement_dynamic(merchantId)
    },
    {
      label: userDetails?.firstName + " " + userDetails?.lastName
    }
  ];

  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    height: "28px",
    borderRadius: "6px"
  };

  const saveUserData = async () => {
    await ECAxiosAPI.put(URLS.EDIT_USER_DATA(tenantDetails.merchantId), {
      id: userDetails?.id,
      firstName: formData?.firstName,
      lastName: formData?.lastName
    })
    .then((res) => {
      // dispatch(setIsUserDetailsEditable(false));
      enqueueSnackbar("User Details Updated", { variant: "success" });
    })
    .catch((error) => {
      enqueueSnackbar("Error", { variant: "error" });
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px"
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
        {/* <div>
          <ButtonGroup
            size="large"
            aria-label="large button group"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={<FormattedMessage id="deleteUser" />}>
              <Button
                color="primary"
                disableElevation
                onClick={() => {}}
                variant="text"
                style={buttonStyles}
              >
                <DisableIcon />
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="editUser" />}>
              <Button
                variant="text"
                color="primary"
                disableElevation
                style={{
                  ...buttonStyles,
                  backgroundColor: isUserDetailsEditable && "rgba(0,0,0,0.08)",
                }}
                onClick={() => {
                  // dispatch(setIsUserDetailsEditable(!isUserDetailsEditable));
                }}
              >
                <PencilIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div> */}
      </div>
      <div style={{ marginBottom: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <SectionHeader
          title={`Profile Settings of ${userDetails?.firstName} ${userDetails?.lastName}`}
          fontSize={"20px"}
        />
        <Tooltip title={<FormattedMessage id="Edit User" />}>
          <Button
            variant="text"
            color="primary"
            disableElevation
            // style={buttonStyles}
          >
            <PencilIcon />
          </Button>
        </Tooltip>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={2}>
            <Input
              label={<FormattedMessage id="firstName" />}
              value={formData?.firstName}
              type={isUserDetailsEditable ? "edit" : "view"}
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Input
              label={<FormattedMessage id="lastName" />}
              value={formData.lastName}
              type={isUserDetailsEditable ? "edit" : "view"}
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="email" />}
              value={userDetails?.email}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="registeredSince" />}
              value={tenantDetails?.createdAt}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="tenant" />}
              value={tenantDetails?.firstName + " " + tenantDetails?.lastName}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="lastActivity" />}
              value={userDetails?.lastActivity}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="activated" />}
              value={
                userDetails?.status === "ENABLED" ? (
                  <FormattedMessage id="yes" />
                ) : (
                  <FormattedMessage id="no" />
                )
              }
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <SectionHeader
          title="Permissions  and Rights of Applications"
          fontSize={"16px"}
        />
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={2}>
            <Dropdown
              label="FileVAT"
              options={userRolesList.filter((item) =>
                item.name.includes("FILEVAT")
              )}
              type={isUserDetailsEditable ? "edit" : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  roles: { ...formData.roles, fileVat: e.target.value }
                });
              }}
              value={formData.roles.fileVat}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Dropdown
              label="ClearVAT"
              options={userRolesList.filter((item) =>
                item.name.includes("CLEARVAT")
              )}
              type={isUserDetailsEditable ? "edit" : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  roles: { ...formData.roles, clearVat: e.target.value }
                });
              }}
              value={formData.roles.clearVat}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Dropdown
              label="VATRules"
              options={userRolesList.filter((item) =>
                item.name.includes("VATRULES")
              )}
              type={isUserDetailsEditable ? "edit" : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  roles: { ...formData.roles, vatRules: e.target.value }
                });
              }}
              value={formData.roles.vatRules}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Dropdown
              label="CheckVAT"
              options={userRolesList.filter((item) =>
                item.name.includes("CHECKVAT")
              )}
              type={isUserDetailsEditable ? "edit" : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  roles: { ...formData.roles, checkVat: e.target.value }
                });
              }}
              value={formData.roles.checkVat}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Dropdown
              label="ClearCustoms"
              options={userRolesList.filter((item) =>
                item.name.includes("CLEARCUSTOMS")
              )}
              type={isUserDetailsEditable ? "edit" : ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  roles: { ...formData.roles, clearCustoms: e.target.value }
                });
              }}
              value={formData.roles.clearCustoms}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InfoItem spacing="0px" label="Inventory & Assignment" value={""} />
          </Grid>
        </Grid>
      </div>

      <Collapse in={isUserDetailsEditable}>
        <Divider />
        <div
          style={{
            margin: "30px 0px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Button
            onClick={() => {
              setFormData(initFormDetails);
              // dispatch(setIsUserDetailsEditable(false));
            }}
          >
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              saveUserData();
            }}
          >
            <FormattedMessage id="Save" />
          </Button>
        </div>
      </Collapse>
    </div>
  );
};
export default UserDetails;
