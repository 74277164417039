import { ButtonBase, Skeleton, Typography } from "@mui/material";
import { SectionHeader } from "../../../components/SectionHeader/SectionHeaders";
import "./styles.css";
import React from "react";
import RightArrowIcon from "../../../assets/icons/RightArrowIcon";

const TenantCard = ({
  title,
  value,
  onClick,
  disabled,
  isLoading,
  tooltipContent = "",
}) => {
  return (
    <ButtonBase className="tenant-card" onClick={onClick} disabled={disabled}>
      <SectionHeader
        containerStyle={{
          opacity: disabled ? 0.5 : 1,
        }}
        title={title}
        fontSize={"14px"}
        infoIconSize={"12px"}
        tooltipContent={tooltipContent}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "auto",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={100}
            height={36}
            style={{ borderRadius: "6px" }}
          />
        ) : (
          <Typography
            style={{
              color: "var(--future-tax-140)",
              fontSize: "24px",
              fontWeight: 500,
            }}
          >
            {value}
          </Typography>
        )}
        <RightArrowIcon />
      </div>
    </ButtonBase>
  );
};

export default TenantCard;
