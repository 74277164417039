import "./styles.css";
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { URLS } from "../configuration/urls";
import {
  AlertTitle,
  CircularProgress,
  Collapse,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { NumbersRounded } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import SetNewPassword from "./SetNewPassword";

function Verify(props) {
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEmailSuccess, setIsEmailSuccess] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    control: control2,
    getValues: getValues2,
  } = useForm({
    defaultValues: {
      code: "",
      newPassword: "",
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    await sendForgotEmail();
    setLoading(false);
  };
  const handleCancel = async () => {
    localStorage.clear();
    navigate("/");
  };

  const sendForgotEmail = async () => {
    const email = getValues().email;
    await axios
      .post(`${URLS.SEND_EMAIL_FORGOT_PASSWORD_CODE}`, email, {
        headers: {
          "Content-Type": "application/text",
        },
      })
      .then((res) => {
        localStorage.setItem("userData", JSON.stringify({ email }));
        console.log(res);
        setError({});
        setIsEmailSuccess(true);
        return true;
      })
      .catch((err) => {
        setError(err);
        console.log("sendEmail Response", err);
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={`eclear-bg section center-v-h`}
    >
      <CssBaseline />

      {isEmailSuccess ? (
        <SetNewPassword
          email={getValues().email}
          goBack={() => {
            setIsEmailSuccess(false);
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            minWidth: "450px",
          }}
          className={`glass-surface`}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            <FormattedMessage id="Forgot password" />
          </Typography>
          <Box
            component="form"
            width="100%"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name={"email"}
              control={control}
              rules={{
                required: true,
                validate: {
                  emailFormat: (value) => {
                    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                      value
                    );
                  },
                },
              }}
              render={({
                field: { onChange, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <React.Fragment>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={<FormattedMessage id="Email Address" />}
                    autoComplete="email"
                    autoFocus
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    value={value}
                    error={error}
                  />
                  <Collapse
                    in={error}
                    style={{
                      padding: "0px 10px",
                      marginTop: "-12px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        color: "var(--error-100)",
                      }}
                    >
                      {error?.type === "emailFormat" ? (
                        <FormattedMessage id="Email is invalid" />
                      ) : (
                        <FormattedMessage id="Email is required" />
                      )}
                    </span>
                  </Collapse>
                </React.Fragment>
              )}
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                disableElevation
                onClick={() => handleCancel()}
                disabled={loading}
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
              >
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                disableElevation
                type="submit"
                disabled={loading}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <FormattedMessage id="Submit" />
              </Button>
            </div>
          </Box>
        </Box>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Verify);
