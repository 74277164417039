import React from "react";
import { Box, Typography, TextField, IconButton } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TextfieldIcon from "../../../assets/icons/TextfieldIcon";

export default function InputField(props) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography
        variant="subtitle2"
        component="span"
        sx={{
          pb: 1,
          pl: 0,
          color: props.disabled ? "var(--future-tax-70)" : "var(--primary)",
        }}
      >
        <span>
          <FormattedMessage id={props.label} />
          {props.required && "*"}
        </span>
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          background:
            // props.clearButton &&
            "linear-gradient(0deg, rgba(0, 24, 61, 0.04), rgba(0, 24, 61, 0.04)), #fcfdff",
          borderRadius: "6px",
        }}
      >
        <TextField
          size="small"
          fullWidth
          id={props.label}
          name={props.name}
          className="onbarding-input"
          placeholder={props.placeholder}
          value={props.value}
          disableUnderline
          onChange={props.onChange}
          InputProps={{
            disableUnderline: true,
          }}
          disabled={props.disabled}
        />
        {props.clearButton && props?.value?.length > 0 && (
          <IconButton
            aria-label="clear"
            size="small"
            style={{
              width: "30px",
              height: "30px",
              marginRight: "5px",
            }}
            onClick={() => {
              props.handleClear();
            }}
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 8.5L8.5 1.5"
                stroke="#00183D"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M1.5 1.5L8.5 8.5"
                stroke="#00183D"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </IconButton>
        )}
        {props.endIcon && (
          <span style={{ paddingRight: "14px" }}>{props.endIcon}</span>
        )}
      </Box>
    </Box>
  );
}
