import React from "react";
import { Box } from "@mui/system";
import { Divider, IconButton, Typography } from "@mui/material";
import InfoToolTip from "../../pages/admin-dashboard/components/InfoToolTip";

export const ModalHeader = ({
  title = "Modal Title",
  tooltipContent,
  onClose = () => {},
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: " center",
          padding: "10px 16px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontSize: "20px" }}>
            {title}
          </Typography>
          <InfoToolTip msg={tooltipContent || title} />
        </Box>
        <IconButton aria-label="close popup" size="small" onClick={onClose}>
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.793 12.707a1 1 0 0 0 1.414-1.414L9.414 8.5l2.793-2.793a1 1 0 0 0-1.414-1.414L8 7.086 5.207 4.293a1 1 0 0 0-1.414 1.414L6.586 8.5l-2.793 2.793a1 1 0 1 0 1.414 1.414L8 9.914l2.793 2.793z"
              fill="#324664"
            />
          </svg>
        </IconButton>
      </Box>
      <Divider />
    </Box>
  );
};

export const SectionHeader = ({
  title = "Modal Title",
  tooltipContent,
  fontSize,
  containerStyle,
  infoIconSize,
  actionButtons,
  withInfo = true,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: " center",
        ...containerStyle,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "left" }}>
        <Typography variant="h6" sx={{ fontSize: fontSize }}>
          {title}
        </Typography>
        {withInfo && (
          <InfoToolTip
            msg={tooltipContent || title}
            infoIconSize={infoIconSize}
          />
        )}
      </Box>
      <Box>{actionButtons}</Box>
    </Box>
  );
};
