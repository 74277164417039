import React from "react";

const TextfieldIcon = ({ color = "var(--primary)", size = "16px" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5H2v8H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H4V4h1.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-5zm10.075 2.62a1 1 0 0 1 1.85 0l3.45 8.38h-2.162l-.618-1.5h-3.19L9.287 13H7.124l3.451-8.38zm1.697 4.88L11.5 7.626 10.729 9.5h1.543z"
        fill={color}
      />
    </svg>
  );
};

export default TextfieldIcon;
