import React, { useState } from "react";
import {
  Switch,
  CircularProgress,
  Modal,
  Box,
  Typography,
  Divider,
  Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import AdminDatePicker from "./DatePicker";

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(24px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#324664",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#011C3B",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    margin: 2,
    boxShadow: "none"
  },
  "& .MuiSwitch-track": {
    borderRadius: 100,
    backgroundColor: "var(--future-tax-40)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

const Toggle = ({ onConfirm, isChecked, item }) => {
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: "6px",
    padding: "0 12px 12px 12px"
  };

  const handleChange = async (date) => {
    setLoading(true);
    if (item?.productType === "CLEARVAT_AWS") {
      await onConfirm(date);
    } else {
      await onConfirm();
    }
    setLoading(false);
  };


  return (
    <React.Fragment>
      <IOSSwitch
        disabled={loading}
        checkedIcon={
          loading ? (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 2px",
                borderRadius: "100px",
                backgroundColor: "white",
                flexWrap: "wrap"
              }}
            >
              <CircularProgress size={"16px"} style={{ display: "inline" }} />
            </div>
          ) : (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 2px",
                borderRadius: "100px",
                backgroundColor: "white"
              }}
            ></div>
          )
        }
        checked={isChecked}
        onClick={() => {
          setIsPermissionModalOpen(true);
        }}
        icon={
          loading ? (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 4px",
                borderRadius: "100px",
                backgroundColor: "white"
              }}
            >
              <CircularProgress size={"16px"} style={{ display: "inline" }} />
            </div>
          ) : (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 4px",
                borderRadius: "100px",
                backgroundColor: "white"
              }}
            ></div>
          )
        }
      />
      <Modal open={isPermissionModalOpen}>
        <Box sx={style}>
          <Typography style={{ padding: "12px", fontSize: "1.5rem" }}>
            <FormattedMessage id="subscription" />
          </Typography>
          <Divider />
          <>
            {!item.isActive && item?.productType === "CLEARVAT_AWS" ?
              <Box
                sx={{
                  margin: "0 0 12px 0"
                }}
              >
                <Typography style={{ padding: "12px" }}>
                  <FormattedMessage id="pleaseEnterClearVat" />
                </Typography>
                <AdminDatePicker
                  changeFilter={setSelectedDate}
                  placeholder="DD/MM/YYYY"
                />
              </Box>
              :
              <Typography style={{ padding: "12px" }}>
                <FormattedMessage id="roleUpdateMessage" />
              </Typography>
            }
          </>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px"
            }}
          >
            <Button
              onClick={() => {
                setIsPermissionModalOpen(false);
              }}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setIsPermissionModalOpen(false);
                handleChange(selectedDate);
              }}
            >
              <FormattedMessage id="Submit" />
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Toggle;
