import React from "react";

const DisableIcon = ({ size, color = "#324664" }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#ubsjcwiwya)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14 8.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-10-1a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="ubsjcwiwya">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DisableIcon;
