import Snackbar, { callAPI } from "../pages/FileVat/common/helper";
import { URLS } from "../configuration/urls";

export const fileVatService = {
  getTaxTypesList,
  getTaxCountriesList,
  getTaxVersionList,
  getTaxAttributeList,
  createTaxVersion,
  updateTaxVersion,
  createTaxAttribute,
  updateTaxAttribute,
  deleteTaxVersion,
  deleteTaxAttribute,
  copyTaxVersion,
  getValidateTaxVersion,
  getTaxCodeList,
  getTaxCodeCountryList,
  deleteTaxCode,
  createTaxCode,
  getTaxCode,
  updateTaxCode,
};

function getTaxTypesList() {
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "GET",
        url: `${URLS.FILE_VAT_GET_TAX_TYPE}`,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getTaxCountriesList() {
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "GET",
        url: `${URLS.FILE_VAT_GET_TAX_COUNTRY}/list`,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function getTaxVersionList({ countryCode, taxId }) {
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "GET",
        url: `${URLS.FILE_VAT_GET_TAX_VERSION_LIST}?countryCode=${countryCode}&taxId=${taxId}`,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function getTaxAttributeList(data) {
  const { versionId } = data;
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "GET",
        url: `${URLS.FILE_VAT_GET_TAX_ATTRIBUTE_LIST}?versionId=${versionId}`,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function createTaxVersion(payload) {
  const { countryCode, taxId, name, validFrom, validTo, status, description } =
    payload;
  const data = {
    name,
    validFrom,
    validTo,
    status,
    description,
  };
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "POST",
        url: `${URLS.FILE_VAT_GET_TAX_VERSION}?countryCode=${countryCode}&taxId=${taxId}`,
        data,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Version Created Successfully");

        resolve(response.data);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error);
      });
  });
}
function updateTaxVersion(data) {
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "PUT",
        url: `${URLS.FILE_VAT_GET_TAX_VERSION}`,
        data,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Version Updated Successfully");

        resolve(response.data);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error);
      });
  });
}
function createTaxAttribute(payload) {
  const { boxNumber, boxName, type, order, formula, versionId } = payload;
  const data = {
    boxNumber,
    boxName,
    type,
    order,
    formula,
  };
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "POST",
        url: `${URLS.FILE_VAT_TAX_ATTRIBUTE}?versionId=${versionId}`,
        data,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Attribute Created Successfully");

        resolve(response.data);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error.response.data);
      });
  });
}
function updateTaxAttribute(payload) {
  const { boxNumber, boxName, type, order, formula, id } = payload;
  const data = {
    id,
    boxNumber,
    boxName,
    type,
    order,
    formula,
  };

  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "PUT",
        url: `${URLS.FILE_VAT_TAX_ATTRIBUTE}`,
        data,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Attribute Update Successfully");

        resolve(response.data);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error.response.data);
      });
  });
}
function deleteTaxVersion(payload) {
  const { id } = payload;
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "DELETE",
        url: `${URLS.FILE_VAT_GET_TAX_VERSION}/${id}`,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Version Deleted Successfully");
        resolve(response);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error);
      });
  });
}
function deleteTaxAttribute(payload) {
  const { id } = payload;
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "DELETE",
        url: `${URLS.FILE_VAT_TAX_ATTRIBUTE}/${id}`,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Attribute Deleted Successfully");
        resolve(response);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error);
      });
  });
}
function copyTaxVersion(payload) {
  const { versionId } = payload;

  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "POST",
        url: `${URLS.FILE_VAT_GET_TAX_VERSION}/copy/${versionId}`,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Version Duplicated Successfully");
        resolve(response.data);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error);
      });
  });
}

function getValidateTaxVersion(payload) {
  const { id, validFrom, validTo, countryCode, taxId } = payload;
  const data = {
    validFrom,
    validTo,
  };
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "POST",
        url: `${
          URLS.FILE_VAT_GET_TAX_VERSION
        }/validate?countryCode=${countryCode}${
          id ? "&id=" + id : taxId ? "&taxId=" + taxId : ""
        }`,
        data,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

function getTaxCodeList(payload) {
  const { page, size, ascending, orderBy, searchedText } = payload;
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "GET",
        url: `${URLS.FILE_VAT_GET_TAX_COUNTRY}?page=${page}&size=${size}&asc=${ascending}&property=${orderBy}&search=${searchedText}`,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function getTaxCodeCountryList(payload) {
  const { countryCode, page, size, ascending, orderBy, searchedText } = payload;
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "GET",
        url: `${URLS.FILE_VAT_GET_TAX_CODE}/country?countryCode=${countryCode}&page=${page}&size=${size}&asc=${ascending}&property=${orderBy}&search=${searchedText}`,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function deleteTaxCode(payload) {
  const { id } = payload;
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "DELETE",
        url: `${URLS.FILE_VAT_GET_TAX_CODE}/${id}`,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Code Deleted Successfully");
        resolve(response);
      })
      .catch(function (error) {
        if (
          error &&
          error.response &&
          error.response.data.code === "CANNOT_REMOVE_DATA"
        ) {
          Snackbar.error(error.response.data.message);
        } else if (
          error &&
          error.response &&
          error.response.data.code === "INVALID_OPERATION_EXCEPTION"
        ) {
          reject(error.response.data);
        } else {
          Snackbar.error("There was an error. Please try again");
        }

        reject(error);
      });
  });
}
function createTaxCode(payload) {
  const {
    countryCode,
    countryName,

    vatTaxCode,
    vatTaxName,
    countryOfDeparture,
    countryOfArrival,
    taxRate,
    customerDescription,
    internalDescription,
  } = payload;
  const data = {
    vatTaxCode,
    vatTaxName,
    countryOfDeparture,
    countryOfArrival,
    taxRate,
    customerDescription,
    internalDescription,
  };

  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "POST",
        url: `${URLS.FILE_VAT_GET_TAX_CODE}?countryCode=${countryCode}&countryName=${countryName}`,
        data,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Code Created Successfully");

        resolve(response.data);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error);
      });
  });
}
function getTaxCode(payload) {
  const { id } = payload;
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "GET",
        url: `${URLS.FILE_VAT_GET_TAX_CODE}/${id}`,
      },
      true
    )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
function updateTaxCode(data) {
  return new Promise((resolve, reject) => {
    callAPI(
      {
        method: "PUT",
        url: `${URLS.FILE_VAT_GET_TAX_CODE}`,
        data,
      },
      true
    )
      .then(function (response) {
        Snackbar.success("Tax Code Updated Successfully");
        resolve(response.data);
      })
      .catch(function (error) {
        Snackbar.error("There was an error. Please try again");
        reject(error);
      });
  });
}
// function getUserInfo() {
//   return new Promise((resolve, reject) => {
//     callAPI(
//       {
//         method: "GET",
//         url: `${URLS.GET_COMPANY_INFO}`,
//       },
//       true
//     )
//       .then(function (response) {

//         const companyId = response && response.data.company[0].id
//         callAPI(
//           {
//             method: "GET",
//             url: `${URLS.GET_COMPANY_INFO}/company/${companyId}/users`,
//           },
//           true
//         ).then(function (response) {
//           console.log(response.data.userInfo[0])
//           resolve(response.data);
//         });
//       })
//       .catch(function (error) {
//         reject(error);
//       });
//   });
// }
