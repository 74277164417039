import {
  Collapse,
  IconButton,
  Input as MuiInput,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

const labelStyles = {
  width: " 99px",
  height: " 24px",
  flexGrow: " 0",
  fontFamily: " Theinhardt",
  fontSize: " 16px",
  fontWeight: " 500",
  fontStretch: " normal",
  fontStyle: " normal",
  lineHeight: " 1.45",
  letterSpacing: " normal",
  textAlign: " left",
  color: " var(--future-tax-80)",
};

export const InfoItem = ({
  label = "Label",
  value,
  spacing = "20px",
  textStyle,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: spacing,
        wordWrap: "break-word",
      }}
    >
      <div>
        <span style={labelStyles}>{label}</span>
      </div>
      <div>
        <Typography
          fontSize={"16px"}
          color="var(--future-tax-70)"
          style={textStyle}
        >
          {value}
        </Typography>
      </div>
    </div>
  );
};

export const Input = ({
  icon,
  placeholder = "Placeholder",
  value,
  onChange,
  label,
  onIconClick,
  disabled = false,
  iconTitle = "",
  backgroundColor = "var(--future-tax-4)",
  actions,
  error,
  type = "edit",
  initValue,
}) => {
  const inputStyles = {
    height: " 44px",
    alignSelf: " stretch",
    flexGrow: " 0",
    display: " flex",
    flexDirection: " row",
    justifyContent: " flex-start",
    alignItems: " center",
    gap: " 10px",
    padding: " 10px 16px",
    borderRadius: " 6px",
    backgroundColor: backgroundColor,
    fontSize: "16px",
    "& .MuiInputBase-input.Mui-disabled": {
      color: "#000",
      WebkitTextFillColor: "unset",
    },
    // border: "1px solid rgba(0,0,0,0)",
    transition: "border 0.15s",
    border: error ? "1px solid var(--error-100)" : "1px solid rgba(0,0,0,0)",
  };
  return (
    <React.Fragment>
      <Collapse in={type === "edit"}>
        <div style={{ padding: "0px 0px 5px 0px" }}>
          {label && (
            <div style={{ marginBottom: "12px" }}>
              <span style={labelStyles}>{label}</span>
            </div>
          )}
          <Tooltip
            title={"This field is required"}
            arrow
            open={error ? true : false}
            placement="bottom"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: " 6px",
                backgroundColor: backgroundColor,
              }}
            >
              <MuiInput
                disabled={disabled}
                fullWidth
                sx={inputStyles}
                disableUnderline
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                defaultValue={initValue}
              />

              {icon && (
                <IconButton
                  title={iconTitle}
                  onClick={onIconClick}
                  style={{ marginRight: "8px" }}
                >
                  {icon}
                </IconButton>
              )}
              {actions}
            </div>
          </Tooltip>
        </div>
      </Collapse>
      <Collapse in={type !== "edit"}>
        <InfoItem label={label} value={value} spacing="0px" />
      </Collapse>
    </React.Fragment>
  );
};
