import "./styles.css";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Modal,
  Button,
  Grid,
  Divider,
  CircularProgress,
  Typography
} from "@mui/material";
import { ModalHeader } from "../../../../components/SectionHeader/SectionHeaders";
import { FormattedMessage } from "react-intl";
import InputField from "../../components/InputField";
import { useSelector } from "react-redux";
import { URLS } from "../../../../configuration/urls";
import { enqueueSnackbar } from "notistack";
import { getMerchantShops } from "../../../../services/adminDashboard";
import { formattedMessage } from "../../../../configuration/helpers";
import TextfieldIcon from "../../../../assets/icons/TextfieldIcon";
import DisableIcon2 from "../../../../assets/icons/DisableIcon2";
import ModalSnackbar from "../../../../components/ModalSnackbar/ModalSnackbar";
import ImageUpload from "../ImageUpload/ImageUpload";
import { ECAxiosAPI } from "../../../../configuration/interceptor";
import { useParams } from "react-router-dom";
import {
  handleActiveShop,
  handleDeactiveShop
} from "../../../../services/spot";

const initSnackBar = {
  type: "",
  visible: false,
  message: "",
  title: ""
};

const ShopModal = ({ modalOpen, row, onClose, type }) => {
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    url: "",
    merchantId: "",
    logoFile: null
  });
  const [callingApi, setCallingApi] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(initSnackBar);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const { merchantId } = useParams();

  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );

  useEffect(() => {
    setImage("");
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "infoLight.main",
    borderRadius: "6px",
    overflow: "hidden"
  };

  const getShopLogo = () => {
    setIsImageLoading(true);
    ECAxiosAPI.get(URLS.GET_SHOP_LOGO(row.id, row.logo), {
      responseType: "blob"
    })
      .then(function (response) {
        const imageBlob = response.data;
        const imageUrl = URL.createObjectURL(imageBlob);
        setImage(imageUrl);
        setIsImageLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setIsImageLoading(false);
      });
  };

  useEffect(() => {
    row?.logo && getShopLogo();
    setFormData({
      name: row?.name,
      url: row?.url
    });
  }, [row]);

  const returnError = (err) => {
    switch (err.response.data.message) {
      case "Invalid data exception invalid File, size must not be greater than 204800 and format must be one of [image/jpeg, image/png]":
        return <FormattedMessage id="error.fileLarge" />;
      case "shop_with_incorrect_address":
        return <FormattedMessage id="error.shop_with_incorrect_address" />;
      default:
        return <FormattedMessage id="error.shopEdit" />;
    }
  };

  const updateShopDetails = async (merchantId, shopId, payload) => {
    setCallingApi(true);
    await ECAxiosAPI.put(
      URLS.UPDATE_SHOP_DETAILS(merchantId, shopId),
      payload,
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("getToken")}`
        }
      }
    )
      .then((res) => {
        getMerchantShops(merchantId);
        setCallingApi(false);
        setIsSnackbarVisible({
          type: "success",
          visible: true,
          title: <FormattedMessage id="editShop.success.title" />,
          message: <FormattedMessage id="editShop.success.message" />
        });
        onClose?.();
      })
      .catch((err) => {
        console.log("err.response.data.message: ", err.response.data.message);
        setCallingApi(false);
        setIsSnackbarVisible({
          type: "error",
          visible: true,
          title: <FormattedMessage id="error" />,
          message: returnError(err)
        });
        console.log("err: ", err);
      });
  };

  const onSubmitActivationShop = (item) => {
    setCallingApi(true);
    if (item.status === "ENABLED") {
      handleDeactiveShop({ shopId: item.id, merchantId, onSuccess, onFail });
    } else {
      handleActiveShop({ shopId: item.id, merchantId, onSuccess, onFail });
    }
  };

  const onSuccess = () => {
    getMerchantShops(merchantId);
    setCallingApi(false);
    onClose?.();
  };

  const onFail = () => {
    setCallingApi(false);
  };

  const createShop = async (merchantId, payload) => {
    setCallingApi(true);
    await ECAxiosAPI.post(URLS.CREATE_SHOP(merchantId), payload, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("getToken")}`
      }
    })
      .then((res) => {
        setCallingApi(false);
        getMerchantShops(merchantId);
        onClose();
        enqueueSnackbar(formattedMessage("shopCreation.success.message"), {
          variant: "success"
        });
      })
      .catch((err) => {
        setCallingApi(false);
        console.log("err: ", err);
      });
  };

  const imgToBlobConvert = async () => {
    try {
      const response = await fetch(image);
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error converting image:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const blobImage = await imgToBlobConvert();
    switch (type) {
      case "edit":
        const editShop = new FormData();
        image && editShop.append("logoFile", blobImage, "shopLogo.png");
        editShop.append("name", formData.name);
        editShop.append("status", row.status);
        formData.url && editShop.append("url", formData.url);
        // const url = URL.createObjectURL(image);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = "downloaded-image.jpg"; // Name for the downloaded file
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // URL.revokeObjectURL(url);
        updateShopDetails(merchantId, row.id, editShop);
        break;
      case "new":
        //BO-231 changes
        const newShop = new FormData();
        image && newShop.append("logoFile", blobImage, "shopLogo.png");
        newShop.append("name", formData.name);
        formData.url && newShop.append("url", formData.url);
        newShop.append("merchant.id", tenantDetails.merchantId);
        createShop(tenantDetails.merchantId, newShop);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      onClose={() => {
        setIsSnackbarVisible(initSnackBar);
        onClose();
        setImage("");
      }}
      open={modalOpen}
      aria-labelledby="Shop modal"
      aria-describedby="shop-modal"
    >
      <Box sx={modalStyle}>
        <ModalHeader
          onClose={() => {
            setIsSnackbarVisible(initSnackBar);
            onClose();
            setImage(null);
          }}
          title={
            (type === "new" && <FormattedMessage id="Add a new Shop" />) ||
            // eslint-disable-next-line no-mixed-operators
            (type === "activate" && (
              <FormattedMessage
                id={
                  row.status === "ENABLED" ? "Deactivate Shop" : "Activate Shop"
                }
              />
            )) ||
            // eslint-disable-next-line no-mixed-operators
            (type === "edit" && (
              <span>
                <FormattedMessage
                  id="Edit Shop"
                  values={{
                    b: (...chunks) => {
                      return <span>{row?.name}</span>;
                    }
                  }}
                />
              </span>
            ))
          }
        />
        {type === "activate" && (
          <ActivationModal
            isLoading={callingApi}
            item={row}
            onClose={onClose}
            onSubmit={onSubmitActivationShop}
          />
        )}
        {(type === "new" || type === "edit") && (
          <form onSubmit={handleSubmit}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 16px 10px"
              }}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Grid item xs={12} md={12}>
                  {type === "edit" && (
                    <InputField
                      label={"shopId"}
                      placeholder={null}
                      value={row?.id}
                      name={"id"}
                      disabled
                      endIcon={
                        <DisableIcon2 color="var(--primary)" size="14px" />
                      }
                    />
                  )}
                  <InputField
                    required
                    label={"shopName"}
                    placeholder={null}
                    value={formData?.name}
                    name={"name"}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        name: e.target.value
                      });
                    }}
                    endIcon={
                      <TextfieldIcon color="var(--primary)" size="14px" />
                    }
                  />
                  <InputField
                    label={"URL"}
                    placeholder={null}
                    value={formData?.url}
                    name={"url"}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        url: e.target.value
                      });
                    }}
                    endIcon={
                      <TextfieldIcon color="var(--primary)" size="14px" />
                    }
                  />
                </Grid>
                <ImageUpload
                  loading={isImageLoading}
                  image={image}
                  setImage={setImage}
                  title={<FormattedMessage id="imageUpload" />}
                />
              </Grid>
            </Box>
            <ModalSnackbar
              visible={isSnackbarVisible.visible}
              message={isSnackbarVisible.message}
              type={isSnackbarVisible.type}
              title={isSnackbarVisible.title}
              onClose={() => {
                setIsSnackbarVisible(initSnackBar);
              }}
            />
            <Divider />
            <Box
              sx={{
                height: " 72px",
                alignSelf: " stretch",
                flexGrow: " 0",
                display: " flex",
                flexDirection: " row",
                justifyContent: "space-between",
                alignItems: " center",
                padding: " 20px"
              }}
            >
              <Button disableElevation onClick={onClose}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                // className="btn-ad"
                disableElevation
                variant="contained"
                type="submit"
                disabled={!formData.name || callingApi}
              >
                {callingApi && (
                  <CircularProgress
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "5px"
                    }}
                  />
                )}{" "}
                {type === "edit" ? (
                  <FormattedMessage id="Save changes" />
                ) : (
                  <FormattedMessage id="Create Shop" />
                )}
              </Button>
            </Box>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default ShopModal;

function ActivationModal(props) {
  const { item, onClose, onSubmit, isLoading } = props;
  return (
    <Box>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <Typography style={{ padding: "12px" }}>
          {item.status === "DISABLED" && (
            <FormattedMessage id="shopActivationMessage" />
          )}
          {item.status === "ENABLED" && (
            <FormattedMessage id="shopDeactivationMessage" />
          )}
          <span> {item.name}?</span>
        </Typography>
        <Typography style={{ padding: "12px", fontWeight: "lighter" }}>
          {item.status === "DISABLED" && (
            <FormattedMessage id="Activating a shop allows you to edit its data" />
          )}
          {item.status === "ENABLED" && (
            <FormattedMessage id="shopActiveMessageAlert" />
          )}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px"
        }}
      >
        <Button onClick={onClose}>
          <FormattedMessage id="Cancel" />
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            onSubmit?.(item);
          }}
        >
          {isLoading ? (
            <CircularProgress
              style={{
                color: "white",
                width: "16px",
                height: "16px",
                marginRight: "5px"
              }}
            />
          ) : (
            <>
              {item.status === "DISABLED" && <FormattedMessage id="Activate" />}
              {item.status === "ENABLED" && (
                <FormattedMessage id="Deactivate" />
              )}
            </>
          )}
        </Button>
      </Box>
    </Box>
  );
}
