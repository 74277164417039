import * as React from "react";
import { styled } from "@mui/system";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { FormattedMessage } from "react-intl";

const BOTableHead = styled(TableHead)({
  background: "#fff",
  zIndex: 99,
  whiteSpace: "nowrap",
  border: "solid 1px var(--future-tax-8)",
  "& .MuiTableCell-root": {
    backgroundColor: "var(--future-tax-0)",
    borderBottom: "solid 1px var(--future-tax-8)",
    borderTop: "solid 1px var(--future-tax-8)",
  },
});
const BOTableCell = styled(TableCell)({
  color: "var(--future-tax-80)",
  fontSize: "16px",
  fontWeight: 500,
  "& .MuiTableSortLabel-root": {
    color: "var(--future-tax-80)",
  },
});

const TableHeader = ({ columns, orderBy, order, setOrderBy, setOrder }) => {
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const SortIndicator = ({ direction, active, colId, orderBy }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2px",
        marginLeft: "10px",
        height: "100%",
      }}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "ASC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginTop: "1px", transform: "scale(-1,-1)" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "DESC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
  return (
    <BOTableHead>
      <TableRow sx={{ background: "#fff" }}>
        {columns.map((column, idx) => (
          <BOTableCell
            key={idx}
            align={column.align}
            width={column.width}
            className={column.sticky ? "sticky-column" : "column"}
          >
            {column.sortLabel ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={order.toLowerCase()}
                onClick={createSortHandler(column.id)}
                IconComponent={() => (
                  <SortIndicator
                    direction={order}
                    active={orderBy === column.id}
                    colId={column.id}
                    orderBy={orderBy}
                  />
                )}
              >
                <FormattedMessage id={column.label} defaultMessage={" "} />
              </TableSortLabel>
            ) : (
              <FormattedMessage id={column.label} defaultMessage={" "} />
            )}
          </BOTableCell>
        ))}
      </TableRow>
    </BOTableHead>
  );
};
export default TableHeader;
