import React from "react";

const AppsIcon = ({ size, color = "#324664" }) => {
  return (
    <svg fill={color} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={color}
        fill-rule="evenodd" clip-rule="evenodd"
        d="M2 4.5v-1h12v1H2zm0 2v7h2v-7H2zm12 0v7H6v-7h8zm-14-4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-12zm11.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
      />
    </svg>

  );
};

export default AppsIcon;
