import React, { useState, useEffect } from "react";
import { Typography, Checkbox as MuiCheckbox } from "@mui/material";
import { FormattedMessage } from "react-intl";
import CheckboxUnchecked from "../../../assets/icons/CheckboxUnchecked";
import CheckboxChecked from "../../../assets/icons/CheckboxChecked";

export default function Checkbox({ name, hasName = true, state, setState, setColumns }) {
  const [isChecked, setIsChecked] = useState(state[name]);

  function updateJSON(keyToUpdate) {
    Object.entries(state).forEach(([key, value]) => {
      if (key === keyToUpdate) {
        state[key] = !state[key];
        setState?.({ ...state, keyToUpdate: !state[key] });
      }
    });
  }

  return (
    <label
      style={{
        display: "inline-block"
      }}
    >
      <MuiCheckbox
        size="small"
        checked={isChecked}
        onClick={() => {
          setIsChecked(!isChecked);
          updateJSON(name);
          setColumns?.();
        }}
        icon={<CheckboxUnchecked />}
        checkedIcon={<CheckboxChecked />}
      />
      {hasName &&
        <Typography
          component="span"
          fontWeight={400}
          marginTop={"9px"}
          style={{ userSelect: "none", fontSize: "16px" }}
        >
          <FormattedMessage id={name} />
        </Typography>
      }
    </label>
  );
}
