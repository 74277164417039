import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as DateIcon } from "../../../assets/icons/date.svg";
import { TextField } from "@mui/material";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

export const SingleDatePicker = ({ validFrom, validTo, handleInputChange }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    start &&
      end &&
      handleInputChange({
        name: "validFromTo",
        validFrom: moment(start).format("YYYY-MM-DD"),
        validTo: moment(end).endOf('month').format('YYYY-MM-DD'),
      });
  };
  const CustomInput = ({ value, onClick }) => {
    return (
      <TextField
        fullWidth
        placeholder="Select Date"
        onClick={onClick}
        value={
          startDate && endDate
            ? `${moment(startDate).format("YYYY.MM")} - ${moment(
                endDate
              ).format("YYYY.MM")}`
            : validFrom && validTo
            ? `${moment(validFrom).format("YYYY.MM")} - ${moment(
                validTo
              ).format("YYYY.MM")}`
            : ""
        }
        // disabled={true}
        InputProps={{
          endAdornment: <DateIcon />,
        }}
      ></TextField>
    );
  };
  return (
    <DatePicker
      selected={startDate}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      showMonthYearPicker
      customInput={<CustomInput />}
    />
  );
};
