import React from "react";
import { IconButton, Alert as MuiAlert } from "@mui/material";
import { formattedMessage } from "../../../configuration/helpers";

const Alert = ({ content, type, onClose, style, iconTextMessage = "Attention" }) => {
  const alertIcon = () => {
    switch (type) {
      case "error":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="m12.568 4.154-5.131 7.697H10.568v2h2.503-2.503v5.698L15.7 11.85h-1.132v.52-.52h-2V4.154zm-6.065 9.697h2.065V21.2c0 1.484 1.925 2.067 2.748.832l6.566-9.849c.665-.996-.05-2.332-1.248-2.332h-2.066V2.503c0-1.484-1.925-2.067-2.748-.832l-6.566 9.848c-.664.997.05 2.332 1.249 2.332z"
              fill="#E01600"
            />
          </svg>
        );
      case "warning":
        return (
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 6.375a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0v-3a1 1 0 0 0-1-1zm0 7.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
              fill="#FFAD33"
            />
            <path
              d="M7.779 2.293a.25.25 0 0 1 .442 0l6.73 12.715a.25.25 0 0 1-.222.367H1.27a.25.25 0 0 1-.22-.367L7.778 2.293z"
              stroke="#FFAD33"
              stroke-width="2"
            />
          </svg>
        );
      case "success":
        return (
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.21 2.858A2.293 2.293 0 1 1 14.33 4.87l-4.798 9.794a2 2 0 0 1-2.682.913l-4.13-2.04A2.286 2.286 0 0 1 4.745 9.44l1.828.902 3.636-7.483zm2.152.72a.25.25 0 0 0-.335.116l-4.315 8.88a.5.5 0 0 1-.671.23l-3.213-1.587a.25.25 0 0 0-.334.111l-.032.064a.25.25 0 0 0 .113.337l3.262 1.61a1 1 0 0 0 1.341-.456l4.376-8.932a.25.25 0 0 0-.114-.334l-.077-.038z"
              fill="#00A167"
            />
          </svg>
        );
      case "info":
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm2 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM7 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm0 3a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V8z"
              fill="#005ae0"
            />
          </svg>
        );
      default:
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0zm2 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM7 5a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm0 3a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V8z"
            fill="#005ae0"
          />
        </svg>;
    }
  };

  const bgColor = () => {
    switch (type) {
      case "error":
        return "var(--error-4)";
      case "warning":
        return "#FFF5E6";
      case "success":
        return "#E9FAF3";
      case "info":
        return "#E9F4FF";
      default:
        return "#E9F4FF";
    }
  };

  const textColor = () => {
    switch (type) {
      case "error":
        return "var(--error-80)";
      case "warning":
        return "var(--warning-80)";
      case "success":
        return "var(--success-80)";
      case "info":
        return "#004dbc";
      default:
        return "#004dbc";
    }
  };

  const iconText = () => {
    switch (type) {
      case "error":
        return formattedMessage(iconTextMessage);
      case "warning":
        return "Warning";
      case "success":
        return "Success";
      case "info":
        return "Info";
      default:
        return "Info";
    }
  };

  return (
    <div style={{ background: bgColor(), padding: "20px 16px 24px", ...style }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {alertIcon()}
          <span
            style={{ fontSize: "14px", fontWeight: 500, color: textColor() }}
          >
            {iconText()}
          </span>
        </div>
        {onClose && (
          <IconButton onClick={onClose}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m4.5 11.875 7-7M4.5 4.875l7 7"
                stroke={textColor()}
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </IconButton>
        )}
      </div>

      <div style={{ color: textColor(), fontSize: "14px", marginTop: "8px" }}>
        {content}
      </div>
    </div>
  );
};

export default Alert;
