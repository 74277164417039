import "./styles.css";
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Skeleton,
  TableHead,
  TableSortLabel
} from "@mui/material";
import { styled } from "@mui/system";
import { Input } from "../Input";
import { formattedMessage } from "../../../../configuration/helpers";
import SearchIcon from "../../../../assets/icons/SearchIcon";
import * as _ from "lodash";
import { FormattedMessage } from "react-intl";
import Checkbox from "../Checkbox";

const BOTableHead = styled(TableHead)({
  background: "#fff",
  zIndex: 99,
  whiteSpace: "nowrap",
  border: "solid 1px var(--future-tax-8)",
  "& .MuiTableCell-root": {
    backgroundColor: "var(--future-tax-0)",
    borderBottom: "solid 1px var(--future-tax-8)",
    borderTop: "solid 1px var(--future-tax-8)"
  }
});
const BOTableCell = styled(TableCell)({
  color: "var(--future-tax-80)",
  fontSize: "16px",
  fontWeight: 500,
  "& .MuiTableSortLabel-root": {
    color: "var(--future-tax-80)"
  }
});

const TableComponent = ({
  rows,
  columns,
  order,
  orderBy,
  RowComponent,
  rowProps,
  tableContainerStyle,
  withSearch = false,
  loading,
  loadingRows = 4,
  isEmpty = false,
  onRequestSort,
  showSortLabel,
  alertBadge,
  checkboxSelection = false
}) => {
  const tableId = _.uniqueId("table_");

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const rows = document.querySelectorAll(`#${tableId} tbody tr`);
    rows.forEach((row) => {
      const rowText = row.innerText.toLowerCase();
      if (rowText.includes(searchTerm)) {
        row.style.display = "";
      } else {
        row.style.display = "none";
      }
    });
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === "ASC";
    const newOrder = isAsc ? "DESC" : "ASC";
    onRequestSort?.(property, newOrder);
  };

  const SortIndicator = ({ direction, active, colId, orderBy }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2px",
        marginLeft: "10px",
        height: "100%"
      }}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "ASC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginTop: "1px", transform: "scale(-1,-1)" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "DESC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );

  const renderSkeleton = () => {
    return (
      <TableBody>
        {[...Array(loadingRows)].map((item, idx) => (
          <TableRow key={idx}>
            {columns.map((item, idx) => {
              return (
                <TableCell id={idx}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    style={{ borderRadius: "6px" }}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const renderEmptyTable = () => {
    return (
      <TableRow style={{ position: "relative", height: "200px" }}>
        <TableCell colSpan={columns.length}>
          <div className="emptyTableRow">
            {/* <img
              alt="empty table"
              src={require("../../../../assets/images/empty.png")}
              style={{ height: "100px", objectFit: "contain" }}
            /> */}
            <span
              style={{
                color: "var(--future-tax-70)",
                fontSize: "14px"
              }}
            >
              <FormattedMessage id="noData" />
            </span>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div style={{ background: "var(--future-tax-0)" }}>
      {withSearch && (
        <div style={{ padding: "16px" }}>
          <Input
            placeholder={formattedMessage("searchByText")}
            icon={<SearchIcon />}
            onChange={handleSearch}
          />
        </div>
      )}
      {alertBadge && alertBadge}
      <TableContainer sx={tableContainerStyle}>
        <Table stickyHeader size="small" id={tableId}>
          <BOTableHead>
            <TableRow sx={{ background: "#fff" }}>
              {columns.map((column, idx) => (
                <BOTableCell
                  key={idx}
                  align={column.align}
                  width={column.width}
                  className={column.sticky ? "sticky-column" : "column"}
                >
                  {column.sortLabel || showSortLabel ? (
                    <TableSortLabel
                      disabled={rows.length === 0}
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleSortRequest(column.id)}
                      IconComponent={() =>
                        rows.length > 0 && (
                          <SortIndicator
                            direction={order}
                            active={orderBy === column.id}
                            colId={column.id}
                            orderBy={orderBy}
                          />
                        )
                      }
                    >
                      <FormattedMessage
                        id={column.label}
                        defaultMessage={" "}
                      />
                    </TableSortLabel>
                  ) : (
                    <FormattedMessage id={column.label} defaultMessage={" "} />
                  )}
                </BOTableCell>
              ))}
            </TableRow>
          </BOTableHead>
          {loading ? (
            renderSkeleton()
          ) : (
            <TableBody>
              {!isEmpty && rows.length > 0
                ? rows.map((item) => {
                  return (
                    <RowComponent
                      key={item.id}
                      row={item}
                      visibleColumns={columns}
                      {...rowProps}
                    />
                  );
                })
                : renderEmptyTable()}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableComponent;
