export const inProgressColumns = [
  // {
  //   id: "name",
  //   label: "Prename",
  //   align: "left",
  //   sortLabel: true,
  //   width: 160,
  // },
  // {
  //   id: "surname",
  //   label: "Surname",
  //   align: "left",
  //   sortLabel: true,
  //   width: 160,
  // },
  {
    id: "email",
    label: "Email Address",
    align: "left",
    sortLabel: true,
    width: 320,
  },
  { id: "status", label: "status", align: "left", sortLabel: true, width: 200 },
  { id: "actions", label: "actions", align: "right", sortLabel: false },
];

export const existingColumns = [
  {
    id: "firstName",
    label: "firstName",
    align: "left",
    sortLabel: true,
    width: 160,
  },
  {
    id: "lastName",
    label: "lastName",
    align: "left",
    sortLabel: true,
    width: 160,
  },
  {
    id: "email",
    label: "Email Address",
    align: "left",
    sortLabel: true,
    width: 320,
  },
  { id: "role", label: "role", align: "left", sortLabel: true, width: 200 },
  {
    id: "actions",
    label: "actions",
    align: "right",
    sortLabel: false,
  },
];

export const rolesOptions = [
  {
    id: 2451,
    name: "USER",
  },
  {
    id: 2501,
    name: "FILEVAT",
  },
  {
    id: 2551,
    name: "ADMIN",
  },
  {
    id: 2601,
    name: "FILEVAT_ADMIN",
  },
  {
    id: 2651,
    name: "CLEARCUSTOMS",
  },
  {
    id: 1,
    name: "FAW_USER",
  },
  {
    id: 51,
    name: "FAW_ADMIN",
  },
  {
    id: 6,
    name: "BO_ADMIN",
  },
  {
    id: 7,
    name: "CLEARCUSTOMS_ADMIN",
  },
  {
    id: 52,
    name: "test",
  },
  {
    id: 2701,
    name: "TAX_RULES_API_ADMIN",
  },
  {
    id: 2751,
    name: "TAX_RULES_API_TAXONOMY_MAINTAINER",
  },
  {
    id: 203,
    name: "SUPPORT",
  },
  {
    id: 204,
    name: "CH",
  },
  {
    id: 205,
    name: "GB",
  },
  {
    id: 207,
    name: "COMPLIANCE",
  },
  {
    id: 209,
    name: "MERCHANT_ADMIN",
  },
  {
    id: 301,
    name: "TAX_RULES_ADMIN",
  },
  {
    id: 351,
    name: "TAXONOMY_DATA_SUPPLIER",
  },
  {
    id: 401,
    name: "TAXONOMY_MAINTAINER",
  },
  {
    id: 451,
    name: "TEST_TAX_RULES",
  },
  {
    id: 151,
    name: "CHECKVATID_USER",
  },
  {
    id: 201,
    name: "CHECKVATID_ADMIN",
  },
  {
    id: 206,
    name: "CHECKVATID",
  },
  {
    id: 501,
    name: "IA_USER",
  },
  {
    id: 551,
    name: "IA_EU",
  },
  {
    id: 601,
    name: "IA_CH",
  },
  {
    id: 651,
    name: "IA_GB",
  },
  {
    id: 701,
    name: "CLEARVAT_ADMIN",
  },
  {
    id: 751,
    name: "CLEARVAT_COMPLIANCE",
  },
  {
    id: 202,
    name: "SHOP",
  },
  {
    id: 208,
    name: "MERCHANT",
  },
  {
    id: 801,
    name: "TEST_CUSTOMS_AI_API",
  },
];
