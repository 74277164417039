import "./styles.css";

function Logo() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className={`logo-container`}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="-270 0 900 500"
          width="40px"
          height="40px"
        >
          <g fill="white">
            <g>
              <g>
                <path
                  className="st0"
                  d="M300.52,214.78v-71.59c0,0-267.88,112.58-267.86,112.56C15.9,262.84,3.71,278.01,0.72,296.4
				c0,0.06-0.02,0.13-0.04,0.18c-0.18,1.21-0.33,2.44-0.44,3.67c-0.02,0.22-0.06,0.42-0.07,0.64c-0.11,1.4-0.17,2.79-0.17,4.23
				c0,0.02,0,0.06,0,0.07v0.02c0,0.02,0,0.06,0,0.09c0,1.41,0.06,2.81,0.17,4.21c0.02,0.22,0.06,0.42,0.07,0.64
				c0.11,1.23,0.26,2.46,0.44,3.67c0.02,0.06,0.04,0.13,0.04,0.18c2.99,18.39,15.18,33.56,31.94,40.66
				c-0.02-0.02,267.86,112.58,267.86,112.58v-71.61L78.46,305.22L300.52,214.78z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M32.66,112.58c-16.76,7.07-28.95,22.27-31.94,40.66c0,0.06-0.02,0.11-0.02,0.18
				c-0.2,1.19-0.35,2.42-0.46,3.67c-0.02,0.2-0.06,0.42-0.07,0.64C0.07,159.11,0,160.53,0,161.94c0,0.04,0,0.06,0,0.09
				c0,0.04,0,0.06,0,0.09c0,1.41,0.07,2.83,0.17,4.23c0.02,0.2,0.06,0.42,0.07,0.64c0.11,1.23,0.26,2.46,0.46,3.67
				c0,0.06,0.02,0.11,0.02,0.17c1.38,8.45,4.72,16.18,9.51,22.8c6.69-2.81,13.77-5.81,21.15-8.89c0.42-0.2,0.85-0.4,1.27-0.59
				c-0.02,0,267.86-112.58,267.86-112.58V0C300.52,0,32.64,112.58,32.66,112.58"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <span className="backoffice-logo">Backoffice</span>
    </div>
  );
}

export default Logo;
