import messages_de from "../translations/de.json";
import messages_en from "../translations/en.json";
import store from "../redux/store";
import * as _ from "lodash";

const messages = {
  de: messages_de,
  en: messages_en
};

export const formattedMessage = (message) => {
  const lang = store.getState().settings.lang;
  return messages[lang][message] || message;
};

export const filteredData = (tableData, searchText) => {
  return _.filter(tableData, (item) => {
    return _.some(item, (value) => {
      if (_.isString(value)) {
        return _.includes(_.toLower(value), _.toLower(searchText));
      }
      return false;
    });
  });
};

export const sortData = (data, order, orderBy) => {
  if (order === "ASC") {
    return _.sortBy(data, orderBy);
  } else {
    return _.sortBy(data, orderBy).reverse();
  }
};

export const formattedDate = (date) => {
  const formatdate = new Date(date)
  .toLocaleString(formattedMessage("time_format"))
  .replace(",", "");
  return formatdate === "Invalid Date" ? "" : formatdate;
};

export const makeMultiPartFormData = (jsonObject) => {
  function appendFormData(formData, data, parentKey) {
    if (data && typeof data === "object" && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        appendFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}.${key}` : key
        );
      });
    } else {
      formData.append(parentKey, data);
    }
  }

  const formData = new FormData();
  appendFormData(formData, jsonObject);

  return formData;
};


export function groupItemsByProductType(items) {
  const groupedItems = items.reduce((acc, item) => {
    const productType = item.productType || "UNKNOWN";
    acc[productType] = acc[productType] || [];
    acc[productType].push(item);
    return acc;
  }, {});

  // Move 'UNKNOWN' to the end if it exists
  const unknownGroup = groupedItems.UNKNOWN;
  if (unknownGroup) {
    delete groupedItems.UNKNOWN;
    groupedItems.UNKNOWN = unknownGroup;
  }

  return groupedItems;
}
