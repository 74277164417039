import React from "react";

const FileIcon = ({ color = "#324664", size = "16px" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 3.414V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6.586L13 3.414z"
        stroke={color}
        stroke-width="2"
      />
      <rect x="5" y="11" width="6" height="2" rx=".5" fill={color} />
      <rect x="5" y="8" width="4" height="2" rx=".5" fill={color} />
    </svg>
  );
};

export default FileIcon;
