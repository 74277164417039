export const SET_LANG = "SET_LANG";
export const SET_DARK_MODE = "SET_DARK_MODE";

export const setLang = (payload) => {
  return {
    type: "SET_LANG",
    payload,
  };
};

export const setIsDarkMode = (payload) => {
  return {
    type: "SET_DARK_MODE",
    payload,
  };
};
