import "./configuration/styles.css";
import React, { useEffect } from "react";

import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { IconButton, styled } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { lightMode } from "./configuration/theme";
import RoutesLinks from "./Routes";
import { SnackbarUtilsConfigurator } from "./pages/FileVat/common/helper";
import CloseIcon from "@mui/icons-material/Close";

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    border-radius: 10px;
  }
`;
const packageJSON = require("../package.json");

function App() {
  useEffect(() => {
    console.log("Backoffice v", packageJSON.version);
  }, []);
  const lang = useSelector((state) => state.settings.lang);

  return (
    <IntlProvider
      locale={lang}
      messages={require(`./translations/${lang}.json`)}
    >
      <StyledSnackbarProvider
        maxSnack={3}
        autoHideDuration={4000}
        action={(key) => (
          <IconButton
            color="inherit"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            <CloseIcon style={{ fontSize: "16px" }} />
          </IconButton>
        )}
      >
        <SnackbarUtilsConfigurator></SnackbarUtilsConfigurator>
        <ThemeProvider theme={lightMode}>
          <main className="App">
            <RoutesLinks />
          </main>
        </ThemeProvider>
      </StyledSnackbarProvider>
    </IntlProvider>
  );
}

export default App;
