import { combineReducers } from "redux";
import settings from "./settings";
import user from "./user";
import adminDashboard from "./adminDashboard";
import { fileVat } from "./filevat";

const rootReducer = combineReducers({
  settings,
  user,
  fileVat,
  adminDashboard,
});

export default rootReducer;
