import React, { useEffect, useState } from "react";
import {
  Switch,
  CircularProgress,
  Modal,
  Box,
  Typography,
  Divider,
  Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import * as _ from "lodash";
import { AUTH_BASE_URL, URLS } from "../../../configuration/urls";
import { useSnackbar } from "notistack";
import { ECAxiosAPI } from "../../../configuration/interceptor";
import { useSelector } from "react-redux";
import { IOSSwitch } from "./Toggle";


const RolesSwitch = ({ name, row, isDefaultChecked, setHasMadeApiCall }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [switchState, setSwitchState] = useState({
    isChecked: isDefaultChecked || false,
    isMakingApiCall: false
  });

  useEffect(() => {
    setSwitchState({
      isChecked: isDefaultChecked,
      isMakingApiCall: false
    });
  }, [isDefaultChecked]);

  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);

  const isSuperUser = useSelector((state) => state.user.isSuperUser);
  const userRolesList = useSelector(
    (state) => state.adminDashboard.userRolesList
  );

  const manageRole = async (user_id, role) => {
    setSwitchState({
      ...switchState,
      isMakingApiCall: true
    });

    const url = AUTH_BASE_URL + URLS.ADD_DELETE_USER_ROLE(user_id);
    const data = _.find(userRolesList, { name: role });

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("getToken")}`
    };

    const DELETE_headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("getToken")}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*"
    };

    switchState.isChecked
      ? await ECAxiosAPI.delete(url, {
        data: [data.id],
        headers: DELETE_headers
      })
      .then((res) => {
        setHasMadeApiCall(true);
        setSwitchState({
          isChecked: false,
          isMakingApiCall: false
        });
      })
      .catch((err) => {
        setSwitchState({
          ...switchState,
          isMakingApiCall: false
        });
        enqueueSnackbar(
          <FormattedMessage
            id="manageRole.revokeAccess.fail"
            values={{
              b: (...chunks) => {
                return (
                  <span style={{ fontWeight: "800" }}>
                        &nbsp;
                    <FormattedMessage id={role} />
                      </span>
                );
              }
            }}
          />,
          {
            variant: "error"
          }
        );
      })
      : await ECAxiosAPI.post(url, [data.id], { headers })
      .then((res) => {
        setHasMadeApiCall(true);
        setSwitchState({
          isMakingApiCall: false,
          isChecked: true
        });
      })
      .catch((err) => {
        setSwitchState({
          ...switchState,
          isMakingApiCall: false
        });
        enqueueSnackbar(
          <FormattedMessage
            id="manageRole.grantAccess.fail"
            values={{
              b: (...chunks) => {
                return (
                  <span style={{ fontWeight: "800" }}>
                        &nbsp;
                    <FormattedMessage id={role} />
                      </span>
                );
              }
            }}
          />,
          {
            variant: "error"
          }
        );
      });
  };

  const noUserId = () => {
    setSwitchState({ isChecked: false, isMakingApiCall: false });
    enqueueSnackbar(<FormattedMessage id="User ID not found" />, {
      variant: "error"
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "6px"
  };

  return (
    <React.Fragment>
      <IOSSwitch
        disabled={!isSuperUser || switchState.isMakingApiCall}
        checkedIcon={
          switchState.isMakingApiCall ? (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 2px",
                borderRadius: "100px",
                backgroundColor: "white",
                flexWrap: "wrap"
              }}
            >
              <CircularProgress size={"16px"} style={{ display: "inline" }} />
            </div>
          ) : (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 2px",
                borderRadius: "100px",
                backgroundColor: "white"
              }}
            >
            </div>
          )
        }
        checked={switchState.isChecked}
        onClick={() => {
          setIsPermissionModalOpen(true);
        }}
        icon={
          switchState.isMakingApiCall ? (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 4px",
                borderRadius: "100px",
                backgroundColor: "white"
              }}
            >
              <CircularProgress size={"16px"} style={{ display: "inline" }} />
            </div>
          ) : (
            <div
              style={{
                width: "14px",
                height: "14px",
                margin: "2.5px 4px",
                borderRadius: "100px",
                backgroundColor: "white"
              }}
            ></div>
          )
        }
      />
      <Modal open={isPermissionModalOpen}>
        <Box sx={style}>
          <Typography style={{ padding: "12px" }}>
            <FormattedMessage id="roleUpdateMessage" />
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "12px"
            }}
          >
            <Button
              onClick={() => {
                setIsPermissionModalOpen(false);
              }}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                const userId = row?.users?.find(item => item.email === row?.email)?.id;
                setIsPermissionModalOpen(false);
                try {
                  manageRole(userId || row?.id, name);
                } catch (err) {
                  noUserId();
                }
              }}
            >
              <FormattedMessage id="Submit" />
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default RolesSwitch;
