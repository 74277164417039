import "./styles.css";
import React from "react";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom/dist";

const Breadcrumbs = ({ items }) => {
  const navigate = useNavigate();
  return (
    <MuiBreadcrumbs separator="→" aria-label="breadcrumb">
      {items.map((item, idx) => {
        return idx < items.length - 1 ? (
          <Link
            underline="never"
            key={idx}
            color="inherit"
            onClick={() => navigate(item.link)}
            className="breadcrumb-link"
          >
            {item.label}
          </Link>
        ) : (
          <Typography key={idx} className="last-breadcrumb">
            {item.label}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};
export default Breadcrumbs;
