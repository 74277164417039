import "./styles.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { error_codes, getData } from "../../../../configuration/api";
import { URLS } from "../../../../configuration/urls";
import {
  formattedDate,
  formattedMessage
} from "../../../../configuration/helpers";
import { enqueueSnackbar } from "notistack";
import { Grid, Skeleton, Typography } from "@mui/material";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { FormattedMessage } from "react-intl";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import TenantCard from "../../components/TenantCard";
import { InfoItem, Input } from "../../components/Input";
import CopyIcon from "../../../../assets/icons/CopyIcon";
import { useNavigate } from "react-router-dom/dist";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import { useParams } from "react-router-dom";
import {
  getAllData,
  getMerchantShops
} from "../../../../services/adminDashboard";
import * as _ from "lodash";
import { ECAxiosAPI } from "../../../../configuration/interceptor";

const TenantDetails = () => {
  const [isGettingAPIKey, setIsGettingAPIKey] = useState(false);
  // const [apiCredentials, setApiCredentials] = useState([]);
  const [apiCredentials, setApiCredentials] = useState([]);

  const { merchantId } = useParams();

  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );
  const userManagement = useSelector(
    (state) => state.adminDashboard.userManagement
  );
  const isFetchingUserMgmtData = useSelector(
    (state) => state.adminDashboard.isFetchingUserMgmtData
  );
  const userSubscriptions = useSelector(
    (state) => state.adminDashboard.userSubscriptions
  );
  const isFetchingMerchant = useSelector(
    (state) => state.adminDashboard.isFetchingMerchant
  );
  const tenantShops = useSelector((state) => state.adminDashboard.tenantShops);

  const isFetchingShops = useSelector(
    (state) => state.adminDashboard.isFetchingShops
  );

  const isSuperUser = useSelector((state) => state.user.isSuperUser);

  const navigate = useNavigate();

  const getKeyDetails = async () => {
    setIsGettingAPIKey(true);
    const res = await ECAxiosAPI.get(
      URLS.MERCHANT_ACCESS_KEY(tenantDetails.merchantId || merchantId)
    )
    .then((res) => {
      setApiCredentials(res);
    })
    .catch((err) => {
      enqueueSnackbar(formattedMessage(`error_${err}`), {
        variant: "error"
      });
    });

    setIsGettingAPIKey(false);
  };

  useEffect(() => {
    getKeyDetails();
    _.isEmpty(tenantDetails) && getAllData({ merchantId });
  }, []);

  useEffect(() => {
    getMerchantShops(merchantId);
  }, [tenantDetails]);

  const breadcrumbs = [
    {
      label: <FormattedMessage id="Tenants" />,
      link: admin_dashboard_route_strings.adminDashboard
    },
    {
      label: tenantDetails.name
    }
  ];

  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    height: "28px",
    borderRadius: "6px"
  };
  const Loading = () => {
    return (
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} md={4}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={24}
            style={{ borderRadius: "6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={44}
            style={{ marginTop: "14px", borderRadius: "6px" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={24}
            style={{ borderRadius: "6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={44}
            style={{ marginTop: "14px", borderRadius: "6px" }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={24}
            style={{ borderRadius: "6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={44}
            style={{ marginTop: "14px", borderRadius: "6px" }}
          />
        </Grid>
      </Grid>
    );
  };

  const itemWidth = { xs: 12, sm: 6, md: 2 };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px"
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
        {/* <div>
          <ButtonGroup
            size="large"
            aria-label="large button group"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={<FormattedMessage id="manageRoles" />}>
              <Button
                color="primary"
                disableElevation
                onClick={() => {}}
                variant="text"
                style={buttonStyles}
              >
                <div style={{ marginRight: "6px" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "var(--future-tax-80)",
                      fontWeight: 500,
                    }}
                  >
                    {tenantDetails.users?.length}
                  </span>
                </div>
                <UserIcon />
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="tenantDetails" />}>
              <Button
                variant="text"
                color="primary"
                disableElevation
                style={buttonStyles}
                onClick={() => {}}
              >
                <PencilIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div> */}
      </div>
      <div style={{ marginBottom: "30px" }}>
        <SectionHeader
          title={tenantDetails.name}
          fontSize={"20px"}
          withInfo={false}
        />
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={3}>
            <TenantCard
              disabled
              title={<FormattedMessage id="Unique Tenant Attibutes" />}
              value={"-"}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TenantCard
              onClick={() => {
                navigate(
                  admin_dashboard_route_strings.tenantSubscriptions_dynamic(
                    merchantId
                  ),
                  { state: { merchantId } }
                );
              }}
              title={<FormattedMessage id="Subscriptions" />}
              disabled={!isSuperUser || isFetchingMerchant}
              isLoading={isSuperUser && isFetchingMerchant}
              // value={userManagement?.[0]?.userSubscriptions?.length || 0}
              value={
                !isSuperUser
                  ? "-"
                  : _.filter(userSubscriptions, (obj) =>
                  _.has(obj, "subscription")
                ).length || 0
              }
              tooltipContent={
                <FormattedMessage id="subscriptions.tile.tooltip" />
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TenantCard
              onClick={() => {
                navigate(
                  admin_dashboard_route_strings.userManagement_dynamic(
                    merchantId
                  )
                );
              }}
              title={<FormattedMessage id="Users" />}
              value={!isSuperUser ? "-" : userManagement.length}
              disabled={
                !isSuperUser ||
                userManagement.length === 0 ||
                isFetchingUserMgmtData
              }
              isLoading={isSuperUser && isFetchingUserMgmtData}
              tooltipContent={<FormattedMessage id="users.tile.tooltip" />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TenantCard
              onClick={() => {
                navigate(
                  admin_dashboard_route_strings.tenantShops_dynamic(merchantId)
                );
              }}
              title={<FormattedMessage id="shops" />}
              value={!isSuperUser ? "-" : tenantShops?.length}
              isLoading={isSuperUser && isFetchingShops}
              disabled={!isSuperUser}
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginBottom: "30px" }}>
        <SectionHeader
          title={<FormattedMessage id="API and Access Key" />}
          fontSize={"16px"}
        />
      </div>
      <div style={{ marginBottom: "30px" }}>
        {isGettingAPIKey ? (
          <Loading />
        ) : apiCredentials?.data?.length > 0 ? (
          apiCredentials?.data?.map((item, idx) => (
            <Grid container columnSpacing={2} rowSpacing={2} key={item.id}>
              <Grid item xs={12} md={4}>
                <Input
                  backgroundColor="var(--future-tax-8)"
                  disabled
                  label={<FormattedMessage id="keyId" />}
                  icon={<CopyIcon />}
                  value={item.accessKey}
                  onIconClick={() => {
                    navigator.clipboard.writeText(item.accessKey);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InfoItem
                  label={<FormattedMessage id="active" />}
                  value={
                    item.enabled ? (
                      <FormattedMessage id="yes" />
                    ) : (
                      <FormattedMessage id="no" />
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InfoItem
                  label={<FormattedMessage id="createdOn" />}
                  value={formattedDate(item.createdAt) || "-"}
                />
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography fontSize={"16px"} color="var(--future-tax-70)">
            <FormattedMessage id="no keys" />
          </Typography>
        )}
      </div>
      <div style={{ marginBottom: "30px" }}>
        <SectionHeader title="Details" fontSize={"16px"} />
      </div>
      <div>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="businessPartner" />}
              value={tenantDetails.businessPartner || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="tenantName" />}
              value={tenantDetails.name || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="legalForm" />}
              value={tenantDetails.legalForm || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="tenantVatId" />}
              value={tenantDetails.vatID || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="createdOn" />}
              value={formattedDate(tenantDetails.createdAt) || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="activated" />}
              value={
                tenantDetails.activated ? (
                  <FormattedMessage id="yes" />
                ) : (
                  <FormattedMessage id="no" />
                )
              }
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="lastUploadedDate" />}
              value={
                tenantDetails.lastUploadedDate
                  ? formattedDate(tenantDetails.lastUploadedDate)
                  : "-"
              }
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="dataActuality" />}
              value={
                tenantDetails.dataActuality
                  ? formattedDate(tenantDetails.dataActuality)
                  : "-"
              }
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="email" />}
              value={tenantDetails.email || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="phoneNumber" />}
              value={tenantDetails.phoneNumber || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="street" />}
              value={tenantDetails.street || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="houseNumber" />}
              value={tenantDetails.houseNumber || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="postalCode" />}
              value={tenantDetails.postalCode || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="city" />}
              value={tenantDetails.city || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="status" />}
              value={
                tenantDetails.users?.[0]?.userSubscriptions?.map((item) => {
                  return item.status === "ENABLED" ? (
                    <FormattedMessage id="active" />
                  ) : (
                    <FormattedMessage id="cancelled" />
                  );
                }) || "-"
              }
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="trialPeriod" />}
              value={tenantDetails.trialPeriod || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="daysLeft" />}
              value={tenantDetails.daysLeftInTrialPeriod || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="paymentMethod" />}
              value={
                tenantDetails.paymentMethod ? (
                  <FormattedMessage id={tenantDetails.paymentMethod} />
                ) : (
                  "-"
                )
              }
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="channelPartner" />}
              value={tenantDetails.channelPartner || "-"}
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="product" />}
              value={
                tenantDetails.users?.[0]?.userSubscriptions?.length > 0
                  ? tenantDetails.users?.[0]?.userSubscriptions?.map((item) => {
                    return <div>{item.name}</div>;
                  })
                  : "-"
              }
            />
          </Grid>
          <Grid item xs={itemWidth.xs} sm={itemWidth.sm} md={itemWidth.md}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="subcriptionStart" />}
              value={
                tenantDetails.users?.[0]?.userSubscriptions?.length > 0
                  ? tenantDetails.users?.[0]?.userSubscriptions?.map((item) => {
                    return <div>{formattedDate(item.startDate)}</div>;
                  })
                  : "-"
              }
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default TenantDetails;
