import React from "react";

const RightArrowIcon = ({ size, color = "#000E25" }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.152 13.849a1.035 1.035 0 0 1 0-1.518L10.2 9.5H3.333a1 1 0 1 1 0-2h6.868L7.15 4.67A1.035 1.035 0 0 1 8.56 3.152l4.982 4.615a1 1 0 0 1 0 1.467L8.56 13.849a1.035 1.035 0 0 1-1.407 0z"
        fill={color}
      />
    </svg>
  );
};

export default RightArrowIcon;
