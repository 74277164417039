import { FormattedMessage } from "react-intl";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import "./styles.css";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Link,
  TableRow,
  Tooltip
} from "@mui/material";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import DisableIcon from "../../../../assets/icons/DisableIcon";
import DisableIcon2 from "../../../../assets/icons/DisableIcon2";
import { useSelector } from "react-redux";
import AddIcon from "../../../../assets/icons/AddIcon";
import CheckBoxIcon from "../../../../assets/icons/CheckBoxIcon";
import React, { useEffect, useState } from "react";
import TableCell from "../../../../components/TabeleCell";
import { useNavigate, useParams } from "react-router-dom";
import { sortData } from "../../../../configuration/helpers";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import * as _ from "lodash";
import ShopModal from "../../components/ShopModal/ShopModal";
import { getAllData } from "../../../../services/adminDashboard";
import { useDispatch } from "react-redux";
import TableComponent from "../../components/Table/Table";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import CheckIcon from "../../../../assets/icons/CheckIcon";
import { IOSSwitch } from "../../components/Toggle";


const TenantShops = () => {
  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );
  const tenantShops = useSelector((state) => state.adminDashboard.tenantShops);
  const isFetchingShops = useSelector(
    (state) => state.adminDashboard.isFetchingShops
  );

  const { merchantId } = useParams();

  const navigate = useNavigate();

  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("email");
  const [visibleData, setVisibleData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState({
    isOpen: false,
    type: "new",
    row: {}
  });

  const dispatch = useDispatch();

  useEffect(() => {
    _.isEmpty(tenantDetails) && getAllData({ merchantId });
    // setVisibleData(tenantShops);
  }, []);

  const breadcrumbs = [
    {
      label: <FormattedMessage id="Tenants" />,
      link: admin_dashboard_route_strings.adminDashboard
    },
    {
      label: tenantDetails.name,
      link: admin_dashboard_route_strings.tenantDetails_dynamic(merchantId)
    },
    {
      label: <FormattedMessage id="shops" />
    }
  ];

  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    height: "28px",
    borderRadius: "6px",
    background: " var(--future-tax-4)"
  };

  const columns = [
    {
      id: "shopId",
      label: "shopId",
      align: "left",
      sortLabel: false
    },
    {
      id: "shopName",
      label: "shopName",
      align: "left",
      sortLabel: false
    },
    {
      id: "created",
      label: "created",
      align: "left",
      sortLabel: false
    },
    {
      id: "shopUrl",
      label: "shopUrl",
      align: "left",
      sortLabel: false
    },
    {
      id: "shopLogo",
      label: "shopLogo",
      align: "left",
      sortLabel: false
    },
    {
      id: "status",
      label: "activated",
      align: "left",
      sortLabel: false
    },
    {
      id: "actions",
      label: "actions",
      align: "center"
    }
  ];

  useEffect(() => {
    setVisibleData(sortData(tenantShops, order, orderBy));
  }, [orderBy, order]);


  const Row = (item) => {
    item = item.row;
    return (
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "var(--future-tax-8)"
          }
        }}
        key={item.id}
      >
        <TableCell align="center" width={100}>
          {item.id}
        </TableCell>
        <TableCell width={300}>{item.name || "-"}</TableCell>
        <TableCell width={300}>{item.created || "-"}</TableCell>
        <TableCell>
          <Link href={item.url} target="_blank" underline="hover">
            {item.url || "-"}
          </Link>
        </TableCell>
        <TableCell>
          {item.logo && <CheckIcon />}
          {!item.logo && <CloseIcon />}
        </TableCell>
        <TableCell width={100}>
          <IOSSwitch
            checked={Boolean(item.status === "ENABLED")}
            onClick={() => {
              setIsModalOpen({
                isOpen: true,
                type: "activate",
                row: item
              });
            }}
          />
        </TableCell>
        <TableCell align="center" width={120}>
          <ButtonGroup
            size="small"
            aria-label="small button group"
          >
            <Tooltip title={<FormattedMessage id="Edit Shop" />}>
              <Button
                variant="text"
                color="primary"
                disableElevation
                disabled={item.status === "DISABLED"}
                style={buttonStyles}
                onClick={() => {
                  setIsModalOpen({
                    isOpen: true,
                    type: "edit",
                    row: item
                  });
                }}
              >

                <PencilIcon
                  color={item.status === "DISABLED" ?
                    "var(--disabled_content)" :
                    "var(--primary)"}
                />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px"
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div
        style={{
          marginBottom: "30px",
          background: "white",
          boxShadow:
            " 2px 1px 2px 0 rgba(0, 0, 0, 0.06), -1px -1px 1px 0 rgba(255, 255, 255, 0.6), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.6), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: "6px"
        }}
      >
        <Box style={{ padding: "16px" }}>
          <SectionHeader
            title={
              <span>
                <FormattedMessage id="shopManagementOfTenant" />{" "}
                {tenantDetails.name}
              </span>
            }
            tooltipContent={
              <FormattedMessage
                id="tooltip.manageShops"
                values={{
                  b: (...chunks) => {
                    return (
                      <span>
                        {tenantDetails.name}
                        {chunks[0][0] === "en" &&
                          tenantDetails.name.substr(
                            tenantDetails.name.length - 1
                          ) !== "s" &&
                          "'s"}
                      </span>
                    );
                  }
                }}
              />
            }
            actionButtons={
              <div>
                <IconButton>
                  <CheckBoxIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    setIsModalOpen({
                      isOpen: true,
                      type: "new"
                    })
                  }
                >
                  <AddIcon />
                </IconButton>
              </div>
            }
          />
        </Box>
        <Divider />

        <TableComponent
          withSearch
          rows={tenantShops}
          columns={columns}
          RowComponent={Row}
          order={order}
          orderBy={orderBy}
          checkboxSelection
          tableContainerStyle={{
            backgroundColor: "var(--future-tax-0)",
            maxHeight: "calc(100vh - 22rem)"
          }}
          loading={isFetchingShops}
          // isEmpty={isEmpty}
          // onRequestSort={handleRequestSort}
        />
      </div>
      {isModalOpen.isOpen &&
        <ShopModal
          modalOpen={isModalOpen.isOpen}
          onClose={() => {
            setIsModalOpen({
              ...isModalOpen,
              isOpen: false,
              row: {}
            });
          }}
          type={isModalOpen.type}
          row={isModalOpen.row}
        />}
    </div>
  );
};

export default TenantShops;
