import {
  USER_ROLES_LIST,
  SET_HAS_MADE_API_CALL,
  SET_DATA_FILTERS,
  SET_TENANT_DETAILS,
  SET_USERS_DATA_LIST,
  SET_INVITE_DATA,
  IS_USER_DETAILS_EDITABLE,
  SET_DASHBOARD_DATA,
  SET_PAGINATION,
  SET_IS_FETCHING_INVITE_DATA,
  SET_ORDER,
  SET_USER_MANAGEMENT,
  SET_SHOP_DETAILS,
  SET_TENANT_SHOPS,
  SET_IS_FETCHING_USER_MANAGEMENT_DATA,
  IS_FILTER_APPLIED,
  SET_USER_SUBSCRIPTIONS,
  IS_FETCHING_MERCHANT,
  IS_FETCHING_SHOPS,
} from "../actions/adminDashboard";
import * as _ from "lodash";
import queryString from "query-string";

const queryParams = queryString.parse(window.location.search);

const initialState = {
  userRolesList: [],
  hasMadeApiCall: false,
  dataFilters: {
    name: "",
    // legalForm: "",
    // vatID: "",
    // createdDate: "",
    // activated: "",
    // lastUploadedDate: "",
    // dataActuality: "",
    email: "",
    // phoneNumber: "",
    // street: "",
    // houseNumber: "",
    // postalCode: "",
    // city: "",
    // trialPeriod: "",
    // paymentMethod: "",
    // channelPartner: "",
  },
  tenantDetails: {},
  usersDataList: [],
  inviteData: [],
  isFetchingInviteData: false,
  isFetchingUserMgmtData: false,
  isUserDetailsEditable: false,
  dashboardData: [],
  pagination: {
    page: parseInt(queryParams.page) || 0,
    rowsPerPage: parseInt(queryParams.rows) || 25,
    totalRecords: 25,
  },
  order: {
    direction: "DESC",
    orderBy: "createdDate",
  },
  userManagement: [],
  tenantShops: [],
  shopDetails: [],
  isFilterApplied: false,
  userSubscriptions: {},
  isFetchingMerchant: false,
  isFetchingShops: false,
};

const adminDashboard = (state = initialState, action) => {
  switch (action.type) {
    case USER_ROLES_LIST:
      return {
        ...state,
        userRolesList: action.payload,
      };
    case SET_HAS_MADE_API_CALL:
      return {
        ...state,
        hasMadeApiCall: action.payload,
      };
    case SET_DATA_FILTERS:
      return {
        ...state,
        dataFilters: action.payload,
      };
    case SET_TENANT_DETAILS:
      return {
        ...state,
        tenantDetails: action.payload,
      };
    case SET_USERS_DATA_LIST:
      return {
        ...state,
        usersDataList: action.payload,
      };
    case SET_INVITE_DATA:
      return {
        ...state,
        inviteData: action.payload,
        isFetchingInviteData: false,
      };
    case SET_IS_FETCHING_INVITE_DATA:
      return {
        ...state,
        isFetchingInviteData: action.payload,
      };
    case IS_USER_DETAILS_EDITABLE:
      return {
        ...state,
        isUserDetailsEditable: action.payload,
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };
    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case SET_USER_MANAGEMENT:
      return {
        ...state,
        userManagement: action.payload,
        isFetchingUserMgmtData: false,
      };
    case SET_SHOP_DETAILS:
      return {
        ...state,
        shopDetails: action.payload,
      };
    case SET_TENANT_SHOPS:
      return {
        ...state,
        tenantShops: action.payload,
      };
    case SET_IS_FETCHING_USER_MANAGEMENT_DATA:
      return { ...state, isFetchingUserMgmtData: action.payload };
    case IS_FILTER_APPLIED:
      return { ...state, isFilterApplied: action.payload };
    case SET_USER_SUBSCRIPTIONS:
      return { ...state, userSubscriptions: action.payload };
    case IS_FETCHING_MERCHANT:
      return { ...state, isFetchingMerchant: action.payload };
    case IS_FETCHING_SHOPS:
      return { ...state, isFetchingShops: action.payload };

    default:
      return state;
  }
};

export default adminDashboard;
