import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Verify from "./pages/Verify";
import ForgotPassword from "./pages/ForgotPassword";
import FileVatDashboard from "./pages/FileVat/Dashboard";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import AdminDashboard from "./pages/admin-dashboard/admin-dashboard";

import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import VersionsList from "./pages/FileVat/VersionsList";
import VersionDetail from "./pages/FileVat/VersionDetail";
import TaxCodeList from "./pages/FileVat/TaxCodeList";
import TaxCodeDetail from "./pages/FileVat/TaxCodeDetail";
import TenantDetails from "./pages/admin-dashboard/pages/TenantDetails/TenantDetails";
import TenantSubscriptions from "./pages/admin-dashboard/pages/TenantSubscriptions/TenantSubscriptions";
import TenantRoles from "./pages/admin-dashboard/pages/TenantSubscriptions/TenantRoles";
import UserDetails from "./pages/admin-dashboard/pages/UserDetails/UserDetails";
import UserManagement from "./pages/admin-dashboard/pages/UserManagement/UserManagement";
import { admin_dashboard_route_strings } from "./route_strings";
import ShopDetails from "./pages/admin-dashboard/pages/ShopDetails/ShopDetails";
import TenantShops from "./pages/admin-dashboard/pages/TenantShops/TenantShops";
import Forbidden from "./pages/admin-dashboard/pages/Forbidden/Forbidden";
import { getUserRoles } from "./services/adminDashboard";
import Spinner from "./components/Spinner/Spinner";

function RoutesLinks() {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const isSuperUser = useSelector((state) => state.user.isSuperUser);

  const Loading = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "100%",
        }}
      >
        <Spinner size={"100px"} />
      </div>
    );
  };

  useEffect(() => {
    getUserRoles();
  }, []);
  return (
    <Router>
      {/* The <Header/> will be shown on all routes regardless of user being authenticated */}
      <Header />
      <Box
        style={{
          marginTop: isAuthenticated && "6rem",
        }}
      >
        {/* All the routes/pages to be shown go in the  the <Routes></Routes> component below. */}
        <Routes>
          {isAuthenticated && (
            <>
              <Route
                path={admin_dashboard_route_strings.adminDashboard}
                exact
                element={<Sidebar main={<AdminDashboard />} />}
              />
              <Route
                path={admin_dashboard_route_strings.tenantDetails}
                exact
                element={<Sidebar main={<TenantDetails />} />}
              />
              <Route
                path={admin_dashboard_route_strings.tenantSubscriptions}
                exact
                element={
                  <Sidebar
                    main={
                      isSuperUser === null ? (
                        <Loading />
                      ) : isSuperUser ? (
                        <TenantSubscriptions />
                      ) : (
                        <Forbidden homePageRoute="/dashboard" />
                      )
                    }
                  />
                }
              />
              <Route
                path={admin_dashboard_route_strings.roles}
                // path={admin_dashboard_route_strings.tenantSubscriptions}
                exact
                element={<Sidebar main={<TenantRoles />} />}
              />
              <Route
                path={admin_dashboard_route_strings.userManagement}
                exact
                element={
                  <Sidebar
                    main={
                      isSuperUser === null ? (
                        <Loading />
                      ) : isSuperUser ? (
                        <UserManagement />
                      ) : (
                        <Forbidden homePageRoute="/dashboard" />
                      )
                    }
                  />
                }
              />
              <Route
                path={admin_dashboard_route_strings.userDetails}
                exact
                element={
                  <Sidebar
                    main={
                      isSuperUser === null ? (
                        <Loading />
                      ) : isSuperUser ? (
                        <UserDetails />
                      ) : (
                        <Forbidden />
                      )
                    }
                  />
                }
              />
              <Route
                path={admin_dashboard_route_strings.shopDetails}
                exact
                element={
                  <Sidebar
                    main={
                      isSuperUser === null ? (
                        <Loading />
                      ) : isSuperUser ? (
                        <ShopDetails />
                      ) : (
                        <Forbidden />
                      )
                    }
                  />
                }
              />
              <Route
                path={admin_dashboard_route_strings.tenantShops}
                exact
                element={
                  <Sidebar
                    main={
                      isSuperUser === null ? (
                        <Loading />
                      ) : isSuperUser ? (
                        <TenantShops />
                      ) : (
                        <Forbidden homePageRoute="/dashboard" />
                      )
                    }
                  />
                }
              />
            </>
          )}
          {isAuthenticated && (
            <>
              <Route
                path="/filevat-tax-forms"
                exact
                element={<Sidebar main={<FileVatDashboard />} />}
              />
              <Route
                path="/filevat-version-list/:country"
                exact
                element={<Sidebar main={<VersionsList />} />}
              />
              <Route
                path="/filevat-tax-code-list"
                exact
                element={<Sidebar main={<TaxCodeList />} />}
              />
              <Route
                path="/filevat-tax-code-detail/:country"
                exact
                element={<Sidebar main={<TaxCodeDetail />} />}
              />
              <Route
                path="/filevat-version-detail/:country"
                exact
                element={<Sidebar main={<VersionDetail />} />}
              />
            </>
          )}
          <Route path="/" element={<Login />} />
          <Route path="/register" exact element={<Register />} />
          <Route path="/verify-email" exact element={<Verify />} />
          <Route path="/forgot-password" exact element={<ForgotPassword />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default RoutesLinks;
