import { enqueueSnackbar } from "notistack";
import { AUTH_BASE_URL, URLS } from "../configuration/urls";
import store from "../redux/store";
import { ECAxiosAPI } from "../configuration/interceptor";
import { sortData } from "../configuration/helpers";
import jwt_decode from "jwt-decode";
import { setUserGroups } from "../redux/actions/user";
import axios from "axios";

const state = store.getState();
let tenantData = {};

export const headers = {
  accept: "*/*",
  Authorization: `Bearer ${localStorage.getItem("getToken")}`
};

export const getMerchant = async (merchantId, date) => {
  store.dispatch({ type: "IS_FETCHING_MERCHANT", payload: true });
  await ECAxiosAPI.get(URLS.GET_MERCHANT(merchantId, date))
  .then((res) => {
    tenantData = {
      merchantId: res.data.id,
      vatID: res.data.vatNumber,
      name: res.data.name,
      channelPartner: res.data.channelPartnerId,
      legalForm: res.data.legalForm,
      postalCode: res.data.primaryAddress.postalCode,
      street: res.data.primaryAddress.street,
      houseNumber: res.data.primaryAddress.houseNumber,
      email: res.data.email,
      city: res.data.primaryAddress.city,
      country: res.data.primaryAddress.country,
      phoneNumber: res.data.phoneNumber || "",
      refereInfo: res.data.refereInfo || "",
      dataActuality: res.data.dataActuality || "",
      lastUploadedDate: res.data.lastUploadedDate || "",
      hasAccessKey: false,
      activated: true,
      users: [],
      businessPartner: res.data.businessPartner || "",
      firstName: "",
      lastName: ""
    };
    store.dispatch({
      type: "SET_USER_SUBSCRIPTIONS",
      payload: res.data.productReports
    });
    store.dispatch({ type: "IS_FETCHING_MERCHANT", payload: false });
    // store.dispatch({ type: "SET_TENANT_DETAILS", payload: tenantData });
  })
  .catch((err) => {
    store.dispatch({ type: "IS_FETCHING_MERCHANT", payload: false });
    console.log("err: ", err);
  });
};

export const getUsers = async (merchantId) => {
  await ECAxiosAPI.get(URLS.GET_MERCHANT_USERS(merchantId))
  .then((res) => {
    store.dispatch({ type: "SET_USERS_DATA_LIST", payload: res.data });
    store.dispatch({ type: "SET_USER_MANAGEMENT", payload: res.data });
    store.dispatch({
      type: "SET_TENANT_DETAILS",
      payload: {
        ...tenantData,
        firstName: res.data?.[0]?.firstName,
        lastName: res.data?.[0]?.lastName,
        paymentMethod:
          res.data?.[0]?.userSubscriptions[0]?.paymentMethod || "",
        users: [
          {
            userSubscriptions: res.data?.[0]?.userSubscriptions,
            userGroups: res.data?.[0]?.userGroups
          }
        ]
      }
    });
  })
  .catch((err) => {
    console.log("err: ", err);
  });
};

export const getInviteStatus = async (merchantId) => {
  store.dispatch({ type: "SET_INVITE_DATA", payload: [] });
  store.dispatch({ type: "SET_IS_FETCHING_INVITE_DATA", payload: true });
  await ECAxiosAPI.post(URLS.GET_INVITE_REPORT, {
    merchant_id: merchantId,
    sortBy: "email",
    sortDirection: "DESC",
    page: 0,
    size: 100,
    invite_status: ["INVITED", "REGISTERED"]
  })
  .then((res) => {
    store.dispatch({ type: "SET_INVITE_DATA", payload: res.data.content });
  })
  .catch((err) => {
    console.log("err: ", err);
  });
  store.dispatch({ type: "SET_IS_FETCHING_INVITE_DATA", payload: false });
};

export const getMerchantShops = async (merchantId) => {
  store.dispatch({ type: "IS_FETCHING_SHOPS", payload: true });
  await ECAxiosAPI.get(URLS.GET_MERCHANT_SHOPS(merchantId), {
    headers: {
      accept: "*/*",
      Authorization: `Bearer ${localStorage.getItem("getToken")}`
    }
  })
  .then((res) => {
    store.dispatch({ type: "SET_TENANT_SHOPS", payload: res.data });
    store.dispatch({ type: "IS_FETCHING_SHOPS", payload: false });
  })
  .catch((err) => {
    console.log("err: ", err);
    store.dispatch({ type: "IS_FETCHING_SHOPS", payload: false });
  });
};

export const getShopDetails = async (merchantId, shopId) => {
  await ECAxiosAPI.get(URLS.GET_SHOP_DETAILS(merchantId, shopId), {
    headers
  })
  .then((res) => {
    store.dispatch({ type: "SET_SHOP_DETAILS", payload: res.data });
  })
  .catch((err) => {
    console.log("err: ", err);
  });
};

export const getUserManagementData = async ({ merchantId, sort, date }) => {
  store.dispatch({
    type: "SET_IS_FETCHING_USER_MANAGEMENT_DATA",
    payload: true
  });
  let results = [];
  await ECAxiosAPI.get(URLS.GET_MERCHANT_USERS(merchantId, date))
  .then((res) => {
    results = [...results, ...res.data];
  })
  .catch((err) => {
    // enqueueSnackbar(err, {
    //   variant: "error",
    // });
    console.log(err);
  });

  await ECAxiosAPI.post(
    URLS.GET_INVITE_REPORT,
    {
      merchant_id: merchantId,
      sortBy: "email",
      sortDirection: "DESC",
      page: 0,
      size: 100,
      invite_status: ["INVITED", "REGISTERED"]
    },
    {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("getToken")}`
      }
    }
  )
  .then((res) => {
    let inviteTransform = res.data.content.map((item, idx) => {
      return {
        id: item.id,
        firstName: item.merchant.name,
        lastName: item.merchant.lastName || "",
        email: item.email,
        status: item.status
      };
    });
    results = [...results, ...inviteTransform];
  })
  .catch((err) => {
    enqueueSnackbar(err, {
      variant: "error"
    });
    console.log(err);
  });

  store.dispatch({
    type: "SET_USER_MANAGEMENT",
    payload: sort ? sortData(results, sort.order, sort.orderBy) : results
  });

  store.dispatch({
    type: "SET_IS_FETCHING_USER_MANAGEMENT_DATA",
    payload: false
  });
};

export const getAllUserRoles = async () => {
  await ECAxiosAPI.get(AUTH_BASE_URL + URLS.ALL_USER_ROLES)
  .then(function(res) {
    store.dispatch({
      type: "USER_ROLES_LIST",
      payload: res.data
    });
  })
  .catch((err) => {
    console.log("err: ", err);
  });
};

export const getUserRoles = async () => {
  const token = localStorage.getItem("getToken");
  const headers = {
    accept: "*/*",
    Authorization: `Bearer ${token}`
  };
  if (token) {
    const user = jwt_decode(token.replaceAll("\"", ""));
    return await ECAxiosAPI.get(`${AUTH_BASE_URL}/user/${user.sub}`, { headers })
    .then((res) => {
      store.dispatch({
        type: "SET_USER_GROUPS",
        payload: res.data.userGroups
      });
    })
    .catch((err) => {
      enqueueSnackbar(err.message, {
        variant: "error"
      });
    });
  }
};

export const getAllData = async ({ merchantId, shopId, date }) => {
  await getMerchant(merchantId);
  await getUsers(merchantId);
  await getInviteStatus(merchantId);
  await getMerchantShops(merchantId);
  await getUserManagementData({ merchantId, date });
  await getAllUserRoles();
  shopId && (await getShopDetails(merchantId, shopId));
};
