import React, { useState, useMemo, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  InputLabel,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  Close,
  RadioButtonUnchecked,
  RadioButtonChecked,
  DoneOutline,
  WarningAmber,
  AddCircleOutline,
} from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import countries from "../../../configuration/countries.json";
import { useDispatch, useSelector } from "react-redux";
import { fileVatActions } from "../../../redux/actions/filevat";
const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
export default function SelectModal({
  title,
  setSelectModalValue,
  addSelectedOption,
  isSelectModalVisible,
  selectedTaxItem,
  placeholder,
  selectModalType,
}) {
  const { taxTypeList, loading } = useSelector((state) => state.fileVat);
  const [optionsList, setOptionsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    <span className="text-gray">{placeholder}</span>
  );
  const [selectedOptionData, setSelectedOptionData] = useState("");
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const displayedOptions = useMemo(
    () =>
      optionsList.filter((item) =>
        selectModalType === "country" || selectModalType === "taxCode"
          ? containsText(
              `${item.country_code} ${item.country_name}`,
              searchText
            )
          : containsText(`${item.taxName} ${item.taxType}`, searchText)
      ),
    [searchText]
  );
  useEffect(() => {
    if (selectModalType === "country" || selectModalType === "taxCode") {
      setOptionsList(countries);
    } else {
      dispatch(fileVatActions.getTaxTypesList());
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectModalType === "taxType" && taxTypeList.length > 0) {
      setOptionsList(taxTypeList);
    }
  }, [taxTypeList]);
  function addSelectOption() {
    addSelectedOption(selectedOptionData);
    resetAll();
  }
  function renderPrimaryButton() {
    const disableUploadBtn =
      loading || Object.keys(selectedOptionData).length === 0;

    return [
      <Button disabled={loading} key={"cancel"} onClick={() => onCancel()}>
        Cancel
      </Button>,
      selectModalType === "taxCode" ? (
        <Button
          className="upload-btn"
          variant="contained"
          key={"upload"}
          onClick={() => addSelectOption()}
          disabled={disableUploadBtn}
          startIcon={<AddCircleOutline size={24} />}
        >
          add Country
        </Button>
      ) : (
        <Button
          className="upload-btn"
          variant="contained"
          key={"upload"}
          onClick={() => addSelectOption()}
          disabled={disableUploadBtn}
          // startIcon={loading ? <CircularProgress size={24} /> : ""}
        >
          Select
        </Button>
      ),
    ];
  }

  const onCancel = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    resetAll();
  };
  const resetAll = () => {
    setSelectModalValue("", false);
    setSelectedOption("");
    setSelectedOptionData("");
  };

  const handleSelectedOption = (selectedValue) => {
    setSelectedOption(
      selectModalType === "country" || selectModalType === "taxCode"
        ? `${selectedValue.country_code} - ${selectedValue.country_name}`
        : `${selectedValue.taxName} - ${selectedValue.taxType}`
    );
    setSelectedOptionData(selectedValue);
  };
  const checkSelectedData = (item) => {
    if (selectModalType === "country" || selectModalType === "taxCode") {
      return selectedTaxItem.includes(item.country_code);
    } else {
      return selectedTaxItem.some((car) => car.taxType === item.taxType);
    }
  };
  function renderModalContent() {
    return (
      <React.Fragment>
        {optionsList && (
          <Grid container>
            <Grid item md={12}>
              <div className="select-box">
                <div className="select-search">
                  <InputLabel>Select {title}</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      MenuProps={{ autoFocus: false }}
                      // labelId="search-select-label"
                      id="select-period"
                      value={selectedOption}
                      // label="Options"
                      onChange={(e) => handleSelectedOption(e.target.value)}
                      // disabled={loading}
                      displayEmpty
                      onClose={() => setSearchText("")}
                      // This prevents rendering empty string in Select's value
                      // if search text would exclude currently selected option.
                      renderValue={() => selectedOption}
                    >
                      <ListSubheader className="header-search-field">
                        <TextField
                          size="small"
                          // Autofocus on textfield
                          autoFocus
                          placeholder="Type to filter..."
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => setSearchText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                            }
                          }}
                        />
                      </ListSubheader>

                      {displayedOptions.map((item, i) => (
                        <MenuItem
                          disabled={checkSelectedData(item)}
                          key={i}
                          value={item}
                        >
                          <div className="select-period-options">
                            <div className="select-period-date-icon">
                              <span>
                                {" "}
                                {selectedOption === item ? (
                                  <RadioButtonChecked fontSize="small" />
                                ) : (
                                  <RadioButtonUnchecked fontSize="small" />
                                )}
                              </span>
                              <span className="select-period-text">
                                {selectModalType === "country" ||
                                selectModalType === "taxCode"
                                  ? `${item.country_code}-${item.country_name}`
                                  : `${item.taxName}-${item.taxType}`}
                              </span>
                            </div>

                            <span>
                              {checkSelectedData(item) ? (
                                <DoneOutline />
                              ) : (
                                <WarningAmber />
                              )}
                            </span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isSelectModalVisible}
        fullWidth={true}
        maxWidth={"md"}
        onClose={onCancel}
        // style={{ maxHeight: "max-content" }}
        className="tax-modal"
      >
        <div className="dialog-title">
          Add <b> {title}</b>
          {isSelectModalVisible ? (
            <IconButton
              // disabled={loading}
              aria-label="close"
              onClick={() => onCancel()}
              sx={{
                position: "absolute",
                right: 7,
                top: 5,
              }}
            >
              <Close className="close-icon" />
            </IconButton>
          ) : null}
        </div>
        <DialogContent dividers style={{ padding: "0px" }}>
          {isSelectModalVisible && renderModalContent()}
        </DialogContent>

        <DialogActions className="dialog-footer">
          {renderPrimaryButton()}
        </DialogActions>
      </Dialog>
    </>
  );
}
