import React, { useState, useEffect } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { URLS, AUTH_BASE_URL } from "../configuration/urls";
import {
  AlertTitle,
  CircularProgress,
  Alert,
  Container,
  Typography,
  Box,
  Grid,
  Link,
  TextField,
  Button,
  Avatar,
  CssBaseline,
  Collapse,
  IconButton,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { setIsAuthenticated, setUsername } from "../redux/actions/user";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { setUserGroups } from "../redux/actions/user";
import { admin_dashboard_route_strings } from "../route_strings";
import { Controller, useForm } from "react-hook-form";

function SignIn(props) {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const allowedRoles = ["ADMIN", "BO_ADMIN"];

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    if (localStorage.getItem("getToken") && isAuthenticated) {
      navigate(admin_dashboard_route_strings.adminDashboard);
    }
  }, []);

  const deleteSessionTokens = () => {
    localStorage.removeItem("getToken");
    localStorage.removeItem("refreshToken");
  };

  const getUserRoles = async () => {
    const token = localStorage.getItem("getToken");
    const headers = {
      accept: "*/*",
      Authorization: `Bearer ${token}`,
    };
    const user = jwt_decode(token.replaceAll('"', ""));
    return await axios
      .get(`${AUTH_BASE_URL}/user/${user.sub}`, { headers })
      .then((res) => {
        dispatch(setUserGroups(res.data.userGroups));
        if (
          res.data.userGroups.some((obj) => allowedRoles.includes(obj.name))
        ) {
          props.setIsAuthenticated(true);
          navigate(admin_dashboard_route_strings.adminDashboard);
        } else {
          setError({
            code: 400,
            message: "accessForbidden",
          });
          deleteSessionTokens();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const authenticateUser = async (event) => {
    const loginData = {
      username: getValues().email,
      password: getValues().password,
      idpType: "KEYCLOAK",
    };

    return await axios
      .post(URLS.LOGIN, loginData)
      .then((res) => {
        const token = res?.data?.access_token;
        const refreshToken = res?.data?.refresh_token;
        localStorage.setItem("getToken", token);
        localStorage.setItem("refreshToken", refreshToken);
        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
        setError({});
        props.setUsername(getValues().email);
        getUserRoles();
      })
      .catch((err) => {
        setError({
          code: err?.response?.status,
          message: err?.response?.data?.message || "error_login_general",
        });
        setLoading(false);
      });
  };
  const onSubmit = async () => {
    setLoading(true);
    dispatch(setUserGroups([]));
    localStorage.removeItem("getToken");
    await authenticateUser();
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={`eclear-bg section center-v-h`}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
        className={`glass-surface`}
      >
        {loading && (
          <CircularProgress
            size={48}
            sx={{
              color: "green",
              position: "absolute",
              top: 34,
              // left: -6,
              zIndex: 1,
            }}
          />
        )}
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <FormattedMessage id="Sign In" />
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          style={{ display: "flex", flexDirection: "column", width: "30rem" }}
        >
          <Collapse in={Object.keys(error).length > 0}>
            <Alert
              severity="error"
              sx={{ background: "var(--error-4)" }}
              action={
                <IconButton onClick={() => setError({})}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m4.5 11.5 7-7M4.5 4.5l7 7"
                      stroke="#E01600"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </IconButton>
              }
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5683 4.15415L7.43678 11.8514H8.56836L10.5681 11.8514V13.8514H13.0707C13.0699 13.8514 13.0691 13.8514 13.0683 13.8514H10.5684L10.5684 19.5486L15.6998 11.8514H14.5683V12.3514C14.5683 12.3581 14.5682 12.3647 14.5681 12.3714V11.8514H12.5681V9.85137L12.5683 9.85138V4.15415ZM6.50252 13.8514H8.56836L8.56836 21.2C8.56836 22.6841 10.4932 23.2669 11.3164 22.032L17.8822 12.1834C18.5467 11.1866 17.8321 9.85138 16.6341 9.85138H14.5683V2.50276C14.5683 1.01866 12.6434 0.435856 11.8202 1.67071L5.25445 11.5193C4.58989 12.5162 5.30448 13.8514 6.50252 13.8514Z"
                    fill="#E01600"
                  />
                </svg>
              }
            >
              <AlertTitle sx={{ color: "var(--error-108)", fontSize: "14px" }}>
                <FormattedMessage id="error" />
              </AlertTitle>
              <Typography sx={{ color: "var(--error-108)", fontSize: "14px" }}>
                <FormattedMessage id={error?.message || "nullOrUndefined"} />
              </Typography>
            </Alert>
          </Collapse>
          <Controller
            name={"email"}
            control={control}
            rules={{
              required: true,
              validate: {
                emailFormat: (value) => {
                  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    value
                  );
                },
              },
            }}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <React.Fragment>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={<FormattedMessage id="Email Address" />}
                  autoComplete="email"
                  autoFocus
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  value={value}
                  error={error}
                />
                <Collapse
                  in={error}
                  style={{
                    padding: "0px 10px",
                    marginTop: "-12px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: "var(--error-100)",
                    }}
                  >
                    {error?.type === "emailFormat" ? (
                      <FormattedMessage id="Email is invalid" />
                    ) : (
                      <FormattedMessage id="Email is required" />
                    )}
                  </span>
                </Collapse>
              </React.Fragment>
            )}
          />

          <Controller
            name={"password"}
            control={control}
            rules={{ required: true }}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <React.Fragment>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={<FormattedMessage id="Password" />}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  value={value}
                  error={error}
                />
                <Collapse
                  in={error}
                  style={{
                    padding: "0px 10px",
                    marginTop: "-12px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: "var(--error-100)",
                    }}
                  >
                    <FormattedMessage id="Password is required" />
                  </span>
                </Collapse>
              </React.Fragment>
            )}
          />
          <Button
            disableElevation
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            <FormattedMessage id="Sign In" />
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/forgot-password")}
                variant="body2"
              >
                <FormattedMessage id="Forgot password" />
                {"?"}
              </Link>
            </Grid>
            <Grid item>
              <Link
                onClick={() => {
                  navigate("/register");
                }}
                style={{ cursor: "pointer" }}
                variant="body2"
              >
                <FormattedMessage id="Don't have an account? Sign Up" />
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => {};

export default connect(mapStateToProps, {
  setIsAuthenticated,
  setUsername,
})(SignIn);
