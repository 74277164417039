export const USER_ROLES_LIST = "USER_ROLES_LIST";
export const SET_HAS_MADE_API_CALL = "SET_HAS_MADE_API_CALL";
export const SET_DATA_FILTERS = "SET_DATA_FILTERS";
export const SET_TENANT_DETAILS = "SET_TENANT_DETAILS";
export const SET_USERS_DATA_LIST = "SET_USERS_DATA_LIST";
export const SET_INVITE_DATA = "SET_INVITE_DATA";
export const SET_IS_FETCHING_INVITE_DATA = "SET_IS_FETCHING_INVITE_DATA";
export const IS_USER_DETAILS_EDITABLE = "IS_USER_DETAILS_EDITABLE";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_ORDER = "SET_ORDER";
export const SET_USER_MANAGEMENT = "SET_USER_MANAGEMENT";
export const SET_SHOP_DETAILS = "SET_SHOP_DETAILS";
export const SET_TENANT_SHOPS = "SET_TENANT_SHOPS";
export const SET_IS_FETCHING_USER_MANAGEMENT_DATA =
  "SET_IS_FETCHING_USER_MANAGEMENT_DATA";
export const IS_FILTER_APPLIED = "IS_FILTER_APPLIED";
export const SET_USER_SUBSCRIPTIONS = "SET_USER_SUBSCRIPTIONS";
export const IS_FETCHING_MERCHANT = "IS_FETCHING_MERCHANT";
export const IS_FETCHING_SHOPS = "IS_FETCHING_SHOPS";

export const setUserRolesList = (payload) => {
  return {
    type: "USER_ROLES_LIST",
    payload,
  };
};

export const setHasMadeApiCall = (payload) => {
  return {
    type: "SET_HAS_MADE_API_CALL",
    payload,
  };
};

export const setDataFilters = (payload) => {
  return {
    type: "SET_DATA_FILTERS",
    payload,
  };
};

export const setTenantDetails = (payload) => {
  return {
    type: "SET_TENANT_DETAILS",
    payload,
  };
};

export const setUsersDataList = (payload) => {
  return {
    type: "SET_USERS_DATA_LIST",
    payload,
  };
};

export const setInviteData = (payload) => {
  return {
    type: "SET_INVITE_DATA",
    payload,
  };
};

export const setIsFetchingInviteData = (payload) => {
  return {
    type: "SET_IS_FETCHING_INVITE_DATA",
    payload,
  };
};

export const setIsUserDetailsEditable = (payload) => {
  return {
    type: "IS_USER_DETAILS_EDITABLE",
    payload,
  };
};

export const setDashboardData = (payload) => {
  return {
    type: "SET_DASHBOARD_DATA",
    payload,
  };
};

export const setPagination = (payload) => {
  return {
    type: "SET_PAGINATION",
    payload,
  };
};

export const setOrder = (payload) => {
  return {
    type: "SET_ORDER",
    payload,
  };
};

export const setUserManagement = (payload) => {
  return {
    type: "SET_USER_MANAGEMENT",
    payload,
  };
};

export const setShopDetails = (payload) => {
  return {
    type: "SET_SHOP_DETAILS",
    payload,
  };
};

export const setTenantShops = (payload) => {
  return {
    type: "SET_TENANT_SHOPS",
    payload,
  };
};

export const setIsFetchingUserMgmtData = (payload) => {
  return {
    type: "SET_IS_FETCHING_USER_MANAGEMENT_DATA",
    payload,
  };
};

export const setIsFilterApplied = (payload) => {
  return {
    type: "IS_FILTER_APPLIED",
    payload,
  };
};

export const setUserSubscriptions = (payload) => {
  return {
    type: "SET_USER_SUBSCRIPTIONS",
    payload,
  };
};

export const setIsFetchingMerchant = (payload) => {
  return {
    type: "IS_FETCHING_MERCHANT",
    payload,
  };
};

export const setIsFetchingShops = (payload) => {
  return {
    type: "IS_FETCHING_SHOPS",
    payload,
  };
};
