import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import "./style.css";
import { Cards } from "./components/Cards";
import { getData, error_codes } from "../../configuration/api";
import { URLS } from "../../configuration/urls";
import SelectModal from "./components/SelectModal";
import { fileVatActions } from "../../redux/actions/filevat";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
  const { taxCountriesList, loading, tempTaxCountriesList } = useSelector(
    (state) => state.fileVat
  );
  const [countryTaxList, setCountryTaxList] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectModalState, setSelectModalState] = useState({
    isSelectModalVisible: false,
    type: "country",
  });
  const setSelectModalValue = (type, isSelectModalVisible) => {
    setSelectModalState({
      type,
      isSelectModalVisible,
    });
  };
  const addSelectedOption = (selectedOption) => {
    const newCountry = {
      ...countryTaxList,
      [selectedOption.country_code]: [],
    };
    setCountryTaxList(newCountry);
  };
  const addSelectedTaxOption = (country, selectedOption) => {
    // const newTaxType = [...taxTypeList, selectedOption];
    // setTaxTypeList(newTaxType);

    const newCountry = {
      ...countryTaxList,
      [country]: [...countryTaxList[country], selectedOption],
    };
    setCountryTaxList(newCountry);
    dispatch(fileVatActions.setTaxCountryListState(newCountry));
  };

  useEffect(() => {
    dispatch(fileVatActions.getTaxCountriesList());
  }, [dispatch]);
  useEffect(() => {
    const newCountry = {
      ...taxCountriesList,
      ...tempTaxCountriesList,
    };
    setCountryTaxList(newCountry);
  }, [taxCountriesList]);

  const keys = Object.keys(countryTaxList).sort();
  return (
    <>
      <Card sx={{ minWidth: 275 }} className="card overview-card">
        <CardHeader
          className="header"
          action={[
            <IconButton onClick={() => setSelectModalValue("country", true)}>
              <AddCircleOutline className="icon" />
            </IconButton>
          ]}
          title="Tax Forms Overview"
        />

        <CardContent className="content">
          {keys && keys.length > 0 ? (
            keys.map((key, index) => {
              return (
                <Cards
                  taxTypeList={countryTaxList[key]}
                  country={key}
                  addSelectedOption={(e) => addSelectedTaxOption(key, e)}
                ></Cards>
              );
            })
          ) : (
            <span style={{ margin: "0 auto" }}>No Tax Forms available</span>
          )}
        </CardContent>
      </Card>
      {selectModalState.isSelectModalVisible && (
        <SelectModal
          isSelectModalVisible={selectModalState.isSelectModalVisible}
          selectModalType={selectModalState.type}
          setSelectModalValue={setSelectModalValue}
          addSelectedOption={addSelectedOption}
          title="Country"
          selectedTaxItem={keys}
          placeholder={" Search the country to add tax types"}
        />
      )}
    </>
  );
}
