import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AddCircleOutline,
  ArrowBackIos,
  ArrowForward,
  InfoOutlined,
} from "@mui/icons-material";

import { checkIfImageExists, getCountryName } from "./common/helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import ConfirmDialog from "./components/ConfirmDialog";
import { useDispatch } from "react-redux";
import { fileVatActions } from "../../redux/actions/filevat";
import { useSelector } from "react-redux";
import TaxCodeDetailTable from "./components/TaxCodeDetailTable";
import TaxCodeModal from "./components/TaxCodeModal";
export default function TaxCodeDetail() {
  const { taxCodeDeletedSuccess, taxCodeCreated } = useSelector(
    (state) => state.fileVat
  );
  const [defaultVersion, setDefaultVersion] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { country } = useParams();
  const [dialogData, setDialogData] = useState({
    title: "",
    type: "",
    content: "",
  });
  const [taxCodeModalState, setTaxCodeModalState] = useState({
    isSelectModalVisible: false,
    id: null,
  });
  const setTaxCodeModalValue = (id, isTaxCodeModalVisible) => {
    setTaxCodeModalState({
      id,
      isTaxCodeModalVisible,
    });
  };

  const handleDialogResponse = () => {
    setDialogOpen(false);
    switch (dialogData.type) {
      case "defaultVersion":
        dispatch(
          fileVatActions.updateTaxVersion({
            ...defaultVersion,
            defaultTax: "YES",
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="breadcrumb">
        <Link to={"/filevat-tax-code-list"} className="link">
          <ArrowBackIos />
          <span className="text"> Tax Code</span>
        </Link>
        <span className="link current">
          <IconButton>
            <ArrowForward />
          </IconButton>
          <span className="text">{country.toUpperCase()}</span>
        </span>
      </div>
      <Card sx={{ minWidth: 275 }} className="card version-list-card">
        <CardHeader
          className="header"
          action={
            <IconButton onClick={() => setTaxCodeModalValue(null, true)}>
              <AddCircleOutline className="icon" />
            </IconButton>
          }
          title={
            <div className="country-flex">
              <span className="flag">
                <img alt="" src={checkIfImageExists(country)} width={30} />
              </span>
              <span className="title">Tax Codes {getCountryName(country)}</span>
              <span className="text-gray ml-1"> Overview</span>
              <Tooltip title={"information"} arrow>
                <InfoOutlined className="info-icon" />
              </Tooltip>
            </div>
          }
        />

        {/* <CardContent className="content"> */}
        <TaxCodeDetailTable
          setTaxCodeModalValue={setTaxCodeModalValue}
        ></TaxCodeDetailTable>
        {/* </CardContent> */}
      </Card>
      {/* {isEditable && <TaxCodeModal />} */}
      {taxCodeModalState.isTaxCodeModalVisible && (
        <TaxCodeModal
          isTaxCodeModalVisible={taxCodeModalState.isTaxCodeModalVisible}
          id={taxCodeModalState.id}
          setTaxCodeModalValue={setTaxCodeModalValue}
          // addSelectedOption={addSelectedOption}
          // title="Country"
          // selectedTaxItem={keys}
          // placeholder={" Search the country to add tax types"}
        />
      )}
      <ConfirmDialog
        open={dialogOpen}
        data={dialogData}
        handleClose={() => setDialogOpen(false)}
        handleOk={() => handleDialogResponse()}
      />
    </>
  );
}
