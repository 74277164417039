import axios from "axios";
import defaultFlag from "../../../assets/flags/default.svg";
import countries from "../../../configuration/countries.json";
import { getRefreshToken } from "../../../services/auth";
import { useSnackbar } from "notistack";
import { ECAxiosAPI } from "../../../configuration/interceptor";
// CHECK IF IMAGE EXISTS
export const checkIfImageExists = (country) => {
  try {
    const src = require(`../../../assets/flags/${country.toLowerCase()}.svg`);
    return src;
  } catch (err) {
    return defaultFlag;
  }
};

export const getCountryName = (countryCode) => {
  try {
    let countryName = countries.find(
      (item) => item.country_code === countryCode.toUpperCase()
    );
    return countryName ? countryName.country_name : "";
  } catch (err) {
    return "";
  }
};

export const getStatusColor = (status) => {
  return status === "ACTIVE"
    ? "bg-green"
    : status === "DRAFT"
    ? "bg-orange"
    : "bg-grey";
};

export const trimString = (string) =>
  string && string.length > 20 ? string.substring(0, 19) + "..." : string;

//Code for FIleVat

export function callAPI(options, withAuth = true) {
  return new Promise((resolve, reject) => {
    const { method, url, data } = options;

    ECAxiosAPI({
      method,
      url,
      data,
    })
      .then(function (response) {
        if (
          typeof response !== "undefined" &&
          typeof response.data !== "undefined"
          // typeof response.data.success !== "undefined" &&
          // response.data.success === true
        ) {
          if (response.status === 200) {
            resolve(response);
            //checking if deleted success
          } else if (response.status === 204) {
            resolve(response);
          } else {
            reject(response.message);
          }
        } else {
          reject("UNKNOWN_ERROR");
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

let useSnackbarRef;
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg) {
    this.toast(msg, "success");
  },
  warning(msg) {
    this.toast(msg, "warning");
  },
  info(msg) {
    this.toast(msg, "info");
  },
  error(msg) {
    this.toast(msg, "error");
  },
  toast(msg, variant = "default") {
    useSnackbarRef.enqueueSnackbar(msg, { variant, autoHideDuration: 3000 });
  },
};
