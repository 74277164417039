import { SET_LANG, SET_DARK_MODE } from "../actions/settings";

const savedDarkMode = localStorage.getItem("isDarkMode");
const savedLang = localStorage.getItem("lang");

const initialState = {
  lang: !savedLang ? "de" : savedLang,
  isDarkMode:
    !savedDarkMode || savedDarkMode === "false" ? false : savedDarkMode,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG:
      localStorage.setItem("lang", action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    case SET_DARK_MODE:
      localStorage.setItem("isDarkMode", action.payload);
      return {
        ...state,
        isDarkMode: action.payload,
      };
    default:
      return state;
  }
};

export default settings;
