import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AddCircleOutline,
  Cancel,
  Close,
  DoneOutline,
  RadioButtonChecked,
  RadioButtonUnchecked,
  WarningAmber,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectModal from "./SelectModal";
import { render } from "@testing-library/react";
import { ReactComponent as CalculatorBlackIcon } from "../../../assets/icons/calculator-black.svg";
import { useSelector, useDispatch } from "react-redux";
import { fileVatActions } from "../../../redux/actions/filevat";
import { useParams } from "react-router-dom";
// const containsText = (text, searchText) =>
//   text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
export default function FormulaModal({
  isFormulaModalVisible,
  setIsFormulaModalVisible,
  handleOnSelect,
  formula,
}) {
  const { loading, taxCodeCountryList } = useSelector((state) => state.fileVat);
  const [tags, setTags] = useState(formula ? formula : []);
  const [fieldValue, setFieldValue] = useState("");
  // const [optionsList, setOptionsList] = useState([
  //   { country_code: "DE", country_name: "Germany" },
  // ]);
  const [selectedOption, setSelectedOption] = useState(
    <span className="text-gray">placeholder</span>
  );
  const [selectedOptionData, setSelectedOptionData] = useState("");
  const [searchText, setSearchText] = useState("");
  const tagRef = useRef();
  const dispatch = useDispatch();
  const { country } = useParams();
  const handleInput = (value) => {
    handleOnChange(fieldValue + value);
  };

  useEffect(() => {
    setTags(formula ? formula : []);
  }, [isFormulaModalVisible]);

  const handleOnChange = (value) => {
    // Reqex is used to accept only number and  operators
    const inputCheck = /^[0-9\-./+x÷()\b]+$/;
    if (value === "" || inputCheck.test(value)) {
      const operatorCheck = /^[-/+x÷()\b]+$/;
      if (operatorCheck.test(value)) {
        setTags([...tags, { key: value }]);
        // result[1] === "/" ? "÷" : result[1]
        tagRef.current.value = "";
        setFieldValue("");
      } else {
        //regex is used to slip value if the next value is operator
        const result = value.replace(/\s+/g, "").split(/(?=[+(-)-/÷x])/);

        if (result.length > 1) {
          setTags([...tags, { key: result[0] }, { key: result[1] }]);
          // result[1] === "/" ? "÷" : result[1]
          tagRef.current.value = "";
          setFieldValue("");
        } else {
          setFieldValue(value);
          // setFieldValue(value === "/" ? "÷" : value);
        }
      }

      //this will be used on final submission
      // const re = /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/;
      // const stringVal = tags.join("");
      // const finalString = stringVal.replace('÷', '/');
      // console.log('finalString',finalString)
      // console.log("myREsult", re.test(finalString));
    }
  };
  const handleOnSubmit = (e) => {
    // e.preventDefault();
    if (
      (e.key === " " || e.key === "Enter") &&
      tagRef.current.value.trim() !== ""
    ) {
      // Get input value
      setTags([...tags, { key: tagRef.current.value }]);
      tagRef.current.value = "";
      setFieldValue("");
    }
    if (e.key === "Backspace") {
      if (fieldValue.length === 0) {
        setTags(tags.slice(0, tags.length - 1));
        // setFieldValue("");
      }
    }
  };
  // set tags when value from select box is added
  const handleSelectValue = (item, type) => {
    setTags([...tags, { id: item.id, key: `${item.vatTaxCode}.${type}` }]);

    tagRef.current.value = "";
  };
  const handleFormulaSelect = () => {
    if (fieldValue.length > 0) {
      handleOnSelect([...tags, { key: fieldValue }]);
      setFieldValue("");
    } else {
      handleOnSelect(tags);
    }
  };
  function renderPrimaryButton() {
    const disableUploadBtn = loading || tags.length === 0;

    return [
      <Button disabled={loading} key={"cancel"} onClick={() => onCancel()}>
        Cancel
      </Button>,
      <Button
        className="upload-btn"
        variant="contained"
        key={"upload"}
        onClick={() => handleFormulaSelect()}
        disabled={loading}
        // startIcon={loading ? <CircularProgress size={24} /> : ""}
      >
        Select
      </Button>,
    ];
  }
  const onCancel = (event, reason) => {
    if (reason && reason === "backdropClick") return;

    resetAll();
  };
  const resetAll = () => {
    setIsFormulaModalVisible(false);
    // setTags([]);
  };

  useEffect(() => {
    dispatch(
      fileVatActions.getTaxCodeCountryList({
        ...{ page: 0, size: 25 },
        countryCode: country,
        ascending: true,
        orderBy: "vatTaxCode",
        //dnt search after "."
        searchedText: searchText.split(".")[0],
      })
    );
  }, [searchText]);

  // const displayedOptions = useMemo(
  //   () =>
  //     taxCodeCountryList.filter((item) =>
  //       containsText(`${item.vatTaxCode}.Value`, searchText)
  //     ),
  //   [taxCodeCountryList]
  // );
  // var replacedval = "<strong>"+currval+"</strong>"
  // var finalval = textcontent.replace(currval, replacedval);
  // const handleSelectedOption = (selectedValue) => {
  //   setSelectedOption(
  //     `${selectedValue.country_code} - ${selectedValue.country_name}`
  //   );
  //   setSelectedOptionData(selectedValue);
  // };
  // const checkSelectedData = (item) => {
  //   return false;
  //   // return selectedTaxItem.includes(item.country_code);
  // };
  const makeBold = (item, keyword) => {
    // var re = new RegExp(keyword, "g");

    const val = "<strong>" + keyword + "</strong>";
    return item.replace(keyword, val);
  };
  const getSelectLabel = (item, key) => {
    return (
      <>
        <span>{country.toUpperCase()}-</span>
        <span
          dangerouslySetInnerHTML={{
            __html: makeBold(`${item.vatTaxCode}.${key}`, searchText),
          }}
        ></span>
      </>
    );
  };

  return (
    <Dialog
      open={isFormulaModalVisible}
      fullWidth={true}
      maxWidth={"md"}
      onClose={onCancel}
      // style={{ maxHeight: "max-content" }}
      className="formula-modal tax-modal"
    >
      <div className="dialog-title">
        Mapping
        <IconButton
          // disabled={loading}
          aria-label="close"
          onClick={() => onCancel()}
          sx={{
            position: "absolute",
            right: 7,
            top: 5,
          }}
        >
          <Close className="close-icon" />
        </IconButton>
      </div>
      <div className="formula-result-field">
        <TextField
          inputRef={tagRef}
          fullWidth
          // type='number'
          // variant="standard"
          // size="small"
          // sx={{ margin: "1rem 0" }}
          // margin="none"
          onKeyDown={handleOnSubmit}
          className="formula-text"
          onChange={(e) => handleOnChange(e.target.value)}
          value={fieldValue}
          placeholder={tags.length < 5 ? "Enter your formula" : ""}
          InputProps={{
            startAdornment: (
              <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                {tags.map((data, index) => {
                  return (
                    <Chip
                      className="formula-tags"
                      key={index}
                      tabIndex={-1}
                      label={data.key}
                      // onDelete={() => handleDelete(data)}
                    />
                  );
                })}
              </Box>
            ),
            // endAdornment: <CalculatorBlackIcon />,
          }}
        />
        <CalculatorBlackIcon />
      </div>

      <DialogContent dividers style={{ padding: "0px 14px", minHeight: 250 }}>
        <div className="cal-btn-list">
          <Button disabled={loading} onClick={() => handleInput("+")}>
            +
          </Button>
          <Button disabled={loading} onClick={() => handleInput("-")}>
            -
          </Button>
          <Button disabled={loading} onClick={() => handleInput("x")}>
            x
          </Button>
          <Button disabled={loading} onClick={() => handleInput("/")}>
            /
          </Button>
          <Button disabled={loading} onClick={() => handleInput("(")}>
            (
          </Button>
          <Button disabled={loading} onClick={() => handleInput(")")}>
            )
          </Button>
          <Button disabled={loading} onClick={() => handleInput("1")}>
            1
          </Button>
          <Button disabled={loading} onClick={() => handleInput("2")}>
            2
          </Button>
          <Button disabled={loading} onClick={() => handleInput("3")}>
            3
          </Button>
          <Button disabled={loading} onClick={() => handleInput("4")}>
            4
          </Button>
          <Button disabled={loading} onClick={() => handleInput("5")}>
            5
          </Button>
          <Button disabled={loading} onClick={() => handleInput("6")}>
            6
          </Button>
          <Button disabled={loading} onClick={() => handleInput("7")}>
            7
          </Button>
          <Button disabled={loading} onClick={() => handleInput("8")}>
            8
          </Button>
          <Button disabled={loading} onClick={() => handleInput("9")}>
            9
          </Button>
          <Button disabled={loading} onClick={() => handleInput("0")}>
            0
          </Button>
          <Button disabled={loading} onClick={() => handleInput(".")}>
            .
          </Button>
        </div>

        <ListSubheader className="header-search-field">
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to filter..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {country.toUpperCase()} -{" "}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {loading ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <SearchIcon />
                  )}
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {taxCodeCountryList.map((item, i) => (
          <>
            <MenuItem
              // disabled={checkSelectedData(item)}
              key={i}
              value={item}
              onClick={() => handleSelectValue(item, "Value")}
            >
              <div className="select-period-options">
                <Chip
                  className="formula-tags"
                  key={i}
                  // label={`${country.toUpperCase()}-${item.vatTaxCode}.Value`}

                  // label={makeBold(
                  //   `${country.toUpperCase()}-${item.vatTaxCode}.Value`,
                  //   searchText
                  // )}
                  label={getSelectLabel(item, "Value")}
                  // onDelete={() => handleDelete(data)}
                ></Chip>
                <span>
                  {/* {checkSelectedData(item) ? <DoneOutline /> : <WarningAmber />} */}
                  <AddCircleOutline className="icon" />
                </span>
              </div>
            </MenuItem>
            <MenuItem
              // disabled={checkSelectedData(item)}
              key={i}
              value={item}
              onClick={() => handleSelectValue(item, "Tax-Value")}
            >
              <div className="select-period-options">
                <Chip
                  className="formula-tags"
                  key={i}
                
                  label={getSelectLabel(item, "Tax-Value")}
                  // onDelete={() => handleDelete(data)}
                ></Chip>
                <span>
                  {/* {checkSelectedData(item) ? <DoneOutline /> : <WarningAmber />} */}
                  <AddCircleOutline className="icon" />
                </span>
              </div>
            </MenuItem>
            <MenuItem
              // disabled={checkSelectedData(item)}
              key={i}
              value={item}
              onClick={() => handleSelectValue(item, "Percentage")}
            >
              <div className="select-period-options">
                <Chip
                  className="formula-tags"
                  key={i}
                  // label={`${country.toUpperCase()}-${
                  //   item.vatTaxCode
                  // }.Percentage`}
                  label={getSelectLabel(item, "Percentage")}
                  // onDelete={() => handleDelete(data)}
                />

                <span>
                  <AddCircleOutline className="icon" />
                </span>
              </div>
            </MenuItem>
          </>
        ))}
      </DialogContent>

      <DialogActions className="dialog-footer">
        {renderPrimaryButton()}
      </DialogActions>
    </Dialog>
  );
}
