// import "./styles.css";
import React from "react";
// import {
//   Dialog,
//   Button,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormattedMessage } from "react-intl";
import { CircularProgress } from "@mui/material";
import KeyIcon from "../../../assets/icons/KeyIcon";

export default function ConfirmDialog({
  open,
  data,
  handleClose = false,
  handleOk,
  isLoading = false
}) {
  const { title, content, type } = data;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ minWidth: "350px" }}>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {handleClose && (
          <Button
            disabled={isLoading}
            className="no-bg-btn"
            variant="outlined"
            onClick={handleClose}
          >
            <FormattedMessage id="Cancel" />
          </Button>
        )}
        <Button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem"
          }}
          variant="contained"
          disabled={isLoading}
          className="blue-bg-btn"
          onClick={handleOk}
          autoFocus
        >
          <FormattedMessage id="Submit" />
          {isLoading && <CircularProgress size="1.5rem" color="white" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
