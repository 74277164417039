import "./styles.css";
import React, { useEffect, useState } from "react";
import { Box, Collapse, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  faChartLine,
  faFileContract,
  faQrcode,
  faBarsStaggered
} from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@emotion/react";


export default function SideBar(props) {
  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();
  const [dropDownIndex, setDropDownIndex] = React.useState(false);

  const [activeLink, setActiveLink] = useState(
    window.location.href.substr(window.location.href.lastIndexOf("/"))
  );

  const items = [
    {
      label: intl.formatMessage({ id: "SPOT Tenants" }),
      url: "/dashboard",
      icon: faChartLine,
      children: []
    },
    {
      label: "FILEVAT",
      url: null,
      icon: faFileContract,
      children: [
        {
          label: "Tax Forms",
          url: "/filevat-tax-forms",
          icon: faBarsStaggered
        },
        {
          label: "VAT Tax Codes",
          url: "/filevat-tax-code-list",
          icon: faQrcode
        }
      ]
    }
  ];


  const navigate = useNavigate();

  useEffect(() => {
    setActiveLink(
      window.location.href.substr(window.location.href.lastIndexOf("/"))
    );
  }, [window.location.href]);

  const EMenuItem = ({
    to,
    disabled,
    onClick,
    endIcon,
    children,
    name,
    sublevel,
    childItems
  }) => {
    const childUrl = childItems?.some((item) =>
      window.location.pathname.includes(item.url)
    );
    const isActive = window.location.pathname.includes(to) || childUrl;

    return (
      <React.Fragment>
        <ListItemButton
          component="a"
          onClick={() => onClick?.()}
          selected={window.location.pathname.includes(to)}
          disabled={disabled}
          style={{
            borderLeft: `4px solid ${
              isActive ? "var(--primary)" : "transparent"
            }`,
            backgroundColor: "var(--future-tax-8)",
            paddingTop: isTabletScreen || isMobileScreen ? "9.5px" : "4px",
            paddingBottom: isTabletScreen || isMobileScreen ? "9.5px" : "4px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            paddingLeft: sublevel * 25 || "auto"
          }}
        >
          <span
            style={{
              color: isActive ? "var(--primary)" : "var(--future-tax-80)",
              flexGrow: 1,
              fontWeight: isActive ? 500 : 400,
              margin: "4px 0px",
              fontSize: "16px",
              minHeight: "24px",
              display: "flex",
              alignItems: "center"
            }}
          >
            {name}
          </span>
          <ListItemIcon sx={{ minWidth: "auto" }}>
            <ArrowIcon
              color={isActive ? "var(--primary)" : "var(--future-tax-80)"}
            />
          </ListItemIcon>
        </ListItemButton>
        {children}
      </React.Fragment>
    );
  };

  const ArrowIcon = ({ color }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.819 13.349a1.035 1.035 0 0 1 0-1.518L9.866 9H3a1 1 0 1 1 0-2h6.867L6.818 4.17a1.035 1.035 0 0 1 1.408-1.518l4.982 4.615a1 1 0 0 1 0 1.467l-4.982 4.615a1.035 1.035 0 0 1-1.407 0z"
        fill={color}
      />
    </svg>
  );

  return (
    <Box sx={{ display: "flex", overflow: "hidden", maxWidth: "100vw" }}>
      <CssBaseline />
      <List>
        {items.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            className={"sidebar-item"}
          >
            <EMenuItem
              key={index}
              to={item.url}
              name={item.label}
              onClick={() => {
                navigate(item.url);
                setDropDownIndex(dropDownIndex !== index && index);
              }}
              disabled={item.disabled}
              childItems={item.children}
            />
            {item.children && item.children.length > 0 && (
              <Collapse
                in={dropDownIndex === index}
                timeout="auto"
                unmountOnExit
              >
                {item.children.map((subItem) => (
                  <EMenuItem
                    key={subItem}
                    to={subItem.url}
                    name={<FormattedMessage id={subItem.label} />}
                    endIcon={<ArrowIcon />}
                    onClick={() => {
                      navigate(subItem.url);
                      setActiveLink(subItem.url);
                    }}
                    sublevel={1}
                  />
                ))}
              </Collapse>
            )}
          </ListItem>
        ))}
      </List>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p: 3,
          paddingRight: 3,
          paddingLeft: 3,
          paddingTop: 1,
          overflow: "hidden",
          maxWidth: "100vw"
        }}
      >
        {props.main}
      </Box>
    </Box>
  );
}
