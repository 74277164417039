import "./styles.css";
import { FormattedMessage } from "react-intl";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import { Grid } from "@mui/material";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import { InfoItem } from "../../components/Input";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import { getAllData } from "../../../../services/adminDashboard";
import * as _ from "lodash";

const ShopDetails = () => {
  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );
  const shopDetails = useSelector((state) => state.adminDashboard.shopDetails);

  const { merchantId, shopId } = useParams();

  useEffect(() => {
    _.isEmpty(tenantDetails) && getAllData({ merchantId, shopId });
  }, []);

  const breadcrumbs = [
    {
      label: <FormattedMessage id="Tenants" />,
      link: admin_dashboard_route_strings.adminDashboard
    },
    {
      label: tenantDetails.name,
      link: admin_dashboard_route_strings.tenantDetails_dynamic(merchantId)
    },
    {
      label: <FormattedMessage id="shops" />,
      link: admin_dashboard_route_strings.tenantShops_dynamic(merchantId)
    },
    {
      label: shopDetails.name
    }
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px"
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div style={{ marginBottom: "30px" }}>
        <SectionHeader
          title={
            <span>
              <FormattedMessage id="Details of Shop" /> {shopDetails.name}
            </span>
          }
        />
      </div>

      <div>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={2}>
            <InfoItem
              spacing="0px"
              label={<FormattedMessage id="country" />}
              value={shopDetails.country}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ShopDetails;
