import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, TextField, InputAdornment, Checkbox } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as Duplicate } from "../../../assets/icons/duplicate.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/icons/checked.svg";
import { ReactComponent as UnCheckIcon } from "../../../assets/icons/unCheck.svg";
import { getStatusColor } from "../common/helper";
import { fileVatActions } from "../../../redux/actions/filevat";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "./ConfirmDialog";
import moment from "moment";

function VersionTable({
  state,
  country,
  handleCheckBox,
  defaultVersion,
  isEditable,
  setCurrentDefaultVersion,
}) {
  const { taxVersionCopied, taxVersionList } = useSelector(
    (state) => state.fileVat
  );
  const [ascending, setAscending] = useState(false);
  const [orderBy, setOrderBy] = useState("filingMonth");
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 25,
    // totalRecords: 25,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [versionTaxList, setVersionTaxList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    type: "",
    id: null,
    content: "",
  });

  useEffect(() => {
    setVersionTaxList(taxVersionList);
  }, [taxVersionList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property ? !ascending : false;
    setAscending(isAsc);
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
  };

  const handleChangePageSize = (event) => {
    setPagination({
      ...pagination,
      pageSize: +event.target.value,
      page: 0,
    });
  };
  const deleteVersion = (id) => {
    setDialogOpen(true);
    setDialogData({
      type: "deleteVersion",
      id: id,
      title: "Are you sure that you want to delete the version?”",
      content: "This action cannot be undone!",
    });
  };
  const duplicateVersion = (row) => {
    setIsDuplicated(true);
    dispatch(
      fileVatActions.copyTaxVersion({
        versionId: row.id,
      })
    );
  };
  useEffect(() => {
    if (Object.keys(taxVersionCopied).length > 0 && isDuplicated) {
      setIsDuplicated(false);
      navigate(`/filevat-version-detail/${country.toLowerCase()}`, {
        state: {
          ...state,
          selectedVersionItem: taxVersionCopied,
          viewType: "edit",
        },
      });
    }
  }, [taxVersionCopied]);

  const handleSearch = (e) => {
    if (e.trim().length > 2) {
      setSearchedText(e);
    } else {
      setSearchedText("");
    }
  };
  const handleDialogResponse = () => {
    setDialogOpen(false);
    switch (dialogData.type) {
      case "deleteVersion":
        dispatch(
          fileVatActions.deleteTaxVersion({
            id: dialogData.id,
          })
        );
        break;
      default:
        break;
    }
  };
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Version Name",
      sort: false,
    },
    {
      id: "validFrom",
      numeric: true,
      disablePadding: false,
      label: "Valid from",
      sort: false,
    },
    {
      id: "validTo",
      numeric: true,
      disablePadding: false,
      label: "Valid to",
      sort: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      sort: false,
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Description",
      sort: false,
    },
    {
      id: "defaultTax",
      numeric: false,
      disablePadding: false,
      label: "Default",
      sort: false,
    },
    {
      disablePadding: false,
      label: "Action",
      sort: false,
    },
  ];
  const SortIndicator = ({ direction, active, colId, orderBy }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2px",
        marginLeft: "10px",
        height: "100%",
      }}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "ASC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="scale(-1,-1)"
        style={{ marginTop: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "DESC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
  return (
    <div className="version-table">
      {/* <Link to="/">
        <h2 className="back-btn">
          <ArrowBackIos />
          Back
        </h2>{" "}
      </Link> */}

      <div className="search-header">
        <TextField
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search className="search-icon" />
              </InputAdornment>
            ),
          }}
          placeholder="Filter by Text and/or Keywords"
        />
      </div>
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={"left"}>
                  {/* <TableSortLabel
                    active={orderBy === headCell.id}
                    disabled={!headCell.sort}
                    hideSortIcon={!headCell.sort}
                    direction={ascending ? "asc" : "desc"}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={() => (
                      <SortIndicator
                        direction={ascending ? "ASC" : "DESC"}
                        active={orderBy === headCell.id}
                        colId={headCell.id}
                        orderBy={orderBy}
                      />
                    )}
                  > */}
                  {headCell.label}
                  {/* </TableSortLabel> */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {versionTaxList && versionTaxList.length > 0 ? (
              versionTaxList
                ?.filter(
                  (row) =>
                    row?.name?.match(new RegExp(searchedText, "i")) ||
                    row?.status?.match(new RegExp(searchedText, "i")) ||
                    row?.description?.match(new RegExp(searchedText, "i")) ||
                    row?.validFrom?.match(new RegExp(searchedText, "i")) ||
                    row?.validTo?.match(new RegExp(searchedText, "i"))
                )

                ?.map((row) => (
                  <TableRow
                    className={`${
                      row.status === "INACTIVE" && "table-text-grey"
                    }`}
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" scope="row">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            `/filevat-version-detail/${country.toLowerCase()}`,
                            {
                              state: {
                                ...state,
                                selectedVersionItem: row,
                                viewType: "view",
                              },
                            }
                          )
                        }
                      >
                        {row.name}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      {row.validFrom && moment(row.validFrom).format("YYYY-MM")}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      {row.validTo && moment(row.validTo).format("YYYY-MM")}
                    </TableCell>
                    <TableCell align="left">
                      <span
                        className={`status-text ${getStatusColor(row.status)}`}
                      >
                        {row.status.charAt(0) +
                          row.status.slice(1).toLowerCase()}
                      </span>
                    </TableCell>

                    <TableCell align="left" width={"40%"}>
                      {row.description}
                    </TableCell>
                    <TableCell align="left">
                      {isEditable ? (
                        <Checkbox
                          icon={<UnCheckIcon />}
                          checkedIcon={<CheckedIcon />}
                          checked={
                            defaultVersion
                              ? defaultVersion.id === row.id
                              : row.defaultTax === "YES"
                          }
                          onChange={() => handleCheckBox(row)}
                        />
                      ) : (
                        row.defaultTax === "YES" && [
                          setCurrentDefaultVersion(row),
                          <CheckedIcon />,
                        ]
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <IconButton onClick={() => deleteVersion(row.id)}>
                        <DeleteIcon />
                      </IconButton>

                      <IconButton onClick={() => duplicateVersion(row)}>
                        <Duplicate />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          navigate(
                            `/filevat-version-detail/${country.toLowerCase()}`,
                            {
                              state: {
                                ...state,
                                selectedVersionItem: row,
                                viewType: "edit",
                              },
                            }
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="no-records">No versions added yet.</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        // count={overviewList.totalElements}
        rowsPerPage={pagination.pageSize}
        page={pagination.pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
      /> */}
      <ConfirmDialog
        open={dialogOpen}
        data={dialogData}
        handleClose={() => setDialogOpen(false)}
        handleOk={() => handleDialogResponse()}
      />
    </div>
  );
}
// const mapStateToProps = (state) => ({
//   overviewList: state.uber.overviewList,
//   loading: state.uber.loading,
// });

export default VersionTable;
