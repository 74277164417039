import React from "react";

const CheckboxChecked = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.2" y="2" width="12" height="12" rx="2" fill="#000E25" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-8zm1 2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-6z"
        fill="#000E25"
      />
      <path
        d="m10.3 5.9-2.7 4.2-1.5-1.2"
        stroke="#F2F4F7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CheckboxChecked;
