import { IS_AUTHENTICATED, USERNAME, SET_USER_GROUPS } from "../actions/user";
import * as _ from "lodash";

const initialState = {
  isAuthenticated:
    localStorage.getItem("getToken") && localStorage.getItem("getToken") !== "",
  username: "",
  userGroups: [],
  isSuperUser: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case SET_USER_GROUPS:
      return {
        ...state,
        userGroups: action.payload,
        isSuperUser: _.some(action.payload, { name: "BO_ADMIN" })
          ? true
          : false,
      };
    default:
      return state;
  }
};

export default user;
