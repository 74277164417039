import { fileVatService } from "../../services/filevat";

export const GET_TAX_COUNTRIES_LIST = "GET_TAX_COUNTRIES_LIST";
export const GET_TAX_COUNTRIES_LIST_SUCCESS = "GET_TAX_COUNTRIES_LIST_SUCCESS";
export const GET_TAX_COUNTRIES_LIST_ERROR = "GET_TAX_COUNTRIES_LIST_ERROR";

export const GET_TAX_TYPE_LIST = "GET_TAX_TYPE_LIST";
export const GET_TAX_TYPE_LIST_SUCCESS = "GET_TAX_TYPE_LIST_SUCCESS";
export const GET_TAX_TYPE_LIST_ERROR = "GET_TAX_TYPE_LIST_ERROR";

export const GET_TAX_VERSION_LIST = "GET_TAX_VERSION_LIST";
export const GET_TAX_VERSION_LIST_SUCCESS = "GET_TAX_VERSION_LIST_SUCCESS";
export const GET_TAX_VERSION_LIST_ERROR = "GET_TAX_VERSION_LIST_ERROR";

export const GET_TAX_ATTRIBUTE_LIST = "GET_TAX_ATTRIBUTE_LIST";
export const GET_TAX_ATTRIBUTE_LIST_SUCCESS = "GET_TAX_ATTRIBUTE_LIST_SUCCESS";
export const GET_TAX_ATTRIBUTE_LIST_ERROR = "GET_TAX_ATTRIBUTE_LIST_ERROR";

export const CREATE_TAX_VERSION = "CREATE_TAX_VERSION";
export const CREATE_TAX_VERSION_SUCCESS = "CREATE_TAX_VERSION_SUCCESS";
export const CREATE_TAX_VERSION_ERROR = "CREATE_TAX_VERSION_ERROR";

export const UPDATE_TAX_VERSION = "UPDATE_TAX_VERSION";
export const UPDATE_TAX_VERSION_SUCCESS = "UPDATE_TAX_VERSION_SUCCESS";
export const UPDATE_TAX_VERSION_ERROR = "UPDATE_TAX_VERSION_ERROR";

export const CREATE_TAX_ATTRIBUTE = "CREATE_TAX_ATTRIBUTE";
export const CREATE_TAX_ATTRIBUTE_SUCCESS = "CREATE_TAX_ATTRIBUTE_SUCCESS";
export const CREATE_TAX_ATTRIBUTE_ERROR = "CREATE_TAX_ATTRIBUTE_ERROR";

export const UPDATE_TAX_ATTRIBUTE = "UPDATE_TAX_ATTRIBUTE";
export const UPDATE_TAX_ATTRIBUTE_SUCCESS = "UPDATE_TAX_ATTRIBUTE_SUCCESS";
export const UPDATE_TAX_ATTRIBUTE_ERROR = "UPDATE_TAX_ATTRIBUTE_ERROR";

export const DELETE_TAX_VERSION = "DELETE_TAX_VERSION";
export const DELETE_TAX_VERSION_SUCCESS = "DELETE_TAX_VERSION_SUCCESS";
export const DELETE_TAX_VERSION_ERROR = "DELETE_TAX_VERSION_ERROR";

export const DELETE_TAX_ATTRIBUTE = "DELETE_TAX_ATTRIBUTE";
export const DELETE_TAX_ATTRIBUTE_SUCCESS = "DELETE_TAX_ATTRIBUTE_SUCCESS";
export const DELETE_TAX_ATTRIBUTE_ERROR = "DELETE_TAX_ATTRIBUTE_ERROR";

export const SET_TAX_COUNTRIES_LIST_STATE = "SET_TAX_COUNTRIES_LIST_STATE";

export const COPY_TAX_VERSION = "COPY_TAX_VERSION";
export const COPY_TAX_VERSION_SUCCESS = "COPY_TAX_VERSION_SUCCESS";
export const COPY_TAX_VERSION_ERROR = "COPY_TAX_VERSION_ERROR";

export const GET_VALIDATE_TAX_VERSION = "GET_VALIDATE_TAX_VERSION";
export const GET_VALIDATE_TAX_VERSION_SUCCESS =
  "GET_VALIDATE_TAX_VERSION_SUCCESS";
export const GET_VALIDATE_TAX_VERSION_ERROR = "GET_VALIDATE_TAX_VERSION_ERROR";

export const SET_VALIDATE_TAX_VERSION_STATE = "SET_VALIDATE_TAX_VERSION_STATE";

export const GET_TAX_CODE_LIST = "GET_TAX_CODE_LIST";
export const GET_TAX_CODE_LIST_SUCCESS = "GET_TAX_CODE_LIST_SUCCESS";
export const GET_TAX_CODE_LIST_ERROR = "GET_TAX_CODE_LIST_ERROR";

export const GET_TAX_CODE_COUNTRY_LIST = "GET_TAX_CODE_COUNTRY_LIST";
export const GET_TAX_CODE_COUNTRY_LIST_SUCCESS =
  "GET_TAX_CODE_COUNTRY_LIST_SUCCESS";
export const GET_TAX_CODE_COUNTRY_LIST_ERROR =
  "GET_TAX_CODE_COUNTRY_LIST_ERROR";

export const DELETE_TAX_CODE = "DELETE_TAX_CODE";
export const DELETE_TAX_CODE_SUCCESS = "DELETE_TAX_CODE_SUCCESS";
export const DELETE_TAX_CODE_ERROR = "DELETE_TAX_CODE_ERROR";

export const CREATE_TAX_CODE = "CREATE_TAX_CODE";
export const CREATE_TAX_CODE_SUCCESS = "CREATE_TAX_CODE_SUCCESS";
export const CREATE_TAX_CODE_ERROR = "CREATE_TAX_CODE_ERROR";

export const GET_TAX_CODE = "GET_TAX_CODE";
export const GET_TAX_CODE_SUCCESS = "GET_TAX_CODE_SUCCESS";
export const GET_TAX_CODE_ERROR = "GET_TAX_CODE_ERROR";

export const UPDATE_TAX_CODE = "UPDATE_TAX_CODE";
export const UPDATE_TAX_CODE_SUCCESS = "UPDATE_TAX_CODE_SUCCESS";
export const UPDATE_TAX_CODE_ERROR = "UPDATE_TAX_CODE_ERROR";

export const SET_FORMULA_ERROR_STATE = "SET_FORMULA_ERROR_STATE";

export const fileVatActions = {
  getTaxTypesList,
  getTaxCountriesList,
  getTaxVersionList,
  getTaxAttributeList,
  createTaxVersion,
  updateTaxVersion,
  createTaxAttribute,
  updateTaxAttribute,
  deleteTaxVersion,
  deleteTaxAttribute,
  setTaxCountryListState,
  copyTaxVersion,
  getValidateTaxVersion,
  setValidateTaxVersion,
  getTaxCodeList,
  getTaxCodeCountryList,
  deleteTaxCode,
  createTaxCode,
  getTaxCode,
  updateTaxCode,
  setFormulaErrorState,
};
function getTaxTypesList(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getTaxTypesList(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_TAX_TYPE_LIST };
  }
  function success(payload) {
    return {
      type: GET_TAX_TYPE_LIST_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: GET_TAX_TYPE_LIST_ERROR, error };
  }
}
function getTaxCountriesList(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getTaxCountriesList(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_TAX_COUNTRIES_LIST };
  }
  function success(payload) {
    return {
      type: GET_TAX_COUNTRIES_LIST_SUCCESS,
      payload,

      // currentPage: data.currentPage,
      // totalPages: data.totalPages,
      // totalItems: data.totalItems,
    };
  }
  function failure(error) {
    return { type: GET_TAX_COUNTRIES_LIST_ERROR, error };
  }
}
function getTaxVersionList(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getTaxVersionList(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_TAX_VERSION_LIST };
  }
  function success(payload) {
    return {
      type: GET_TAX_VERSION_LIST_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: GET_TAX_VERSION_LIST_ERROR, error };
  }
}
function getTaxAttributeList(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getTaxAttributeList(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_TAX_ATTRIBUTE_LIST };
  }
  function success(payload) {
    return {
      type: GET_TAX_ATTRIBUTE_LIST_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: GET_TAX_ATTRIBUTE_LIST_ERROR, error };
  }
}

function createTaxVersion(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.createTaxVersion(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: CREATE_TAX_VERSION };
  }
  function success(payload) {
    return {
      type: CREATE_TAX_VERSION_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: CREATE_TAX_VERSION_ERROR, error };
  }
}
function updateTaxVersion(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.updateTaxVersion(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: UPDATE_TAX_VERSION };
  }
  function success(payload) {
    return {
      type: UPDATE_TAX_VERSION_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: UPDATE_TAX_VERSION_ERROR, error };
  }
}

function createTaxAttribute(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.createTaxAttribute(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: CREATE_TAX_ATTRIBUTE };
  }
  function success(payload) {
    return {
      type: CREATE_TAX_ATTRIBUTE_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: CREATE_TAX_ATTRIBUTE_ERROR, error };
  }
}

function updateTaxAttribute(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.updateTaxAttribute(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: UPDATE_TAX_ATTRIBUTE };
  }
  function success(payload) {
    return {
      type: UPDATE_TAX_ATTRIBUTE_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: UPDATE_TAX_ATTRIBUTE_ERROR, error };
  }
}
function deleteTaxVersion(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.deleteTaxVersion(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: DELETE_TAX_VERSION };
  }
  function success(payload) {
    return {
      type: DELETE_TAX_VERSION_SUCCESS,
    };
  }
  function failure(error) {
    return { type: DELETE_TAX_VERSION_ERROR, error };
  }
}
function deleteTaxAttribute(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.deleteTaxAttribute(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: DELETE_TAX_ATTRIBUTE };
  }
  function success(payload) {
    return {
      type: DELETE_TAX_ATTRIBUTE_SUCCESS,
    };
  }
  function failure(error) {
    return { type: DELETE_TAX_ATTRIBUTE_ERROR, error };
  }
}
function setTaxCountryListState() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: SET_TAX_COUNTRIES_LIST_STATE };
  }
}
function copyTaxVersion(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.copyTaxVersion(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: COPY_TAX_VERSION };
  }
  function success(payload) {
    return {
      type: COPY_TAX_VERSION_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: COPY_TAX_VERSION_ERROR, error };
  }
}
function getValidateTaxVersion(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getValidateTaxVersion(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_VALIDATE_TAX_VERSION };
  }
  function success(payload) {
    return {
      type: GET_VALIDATE_TAX_VERSION_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: GET_VALIDATE_TAX_VERSION_ERROR, error };
  }
}
function setValidateTaxVersion(payload) {
  return (dispatch) => {
    dispatch(request(payload));
  };
  function request(payload) {
    return { type: SET_VALIDATE_TAX_VERSION_STATE, payload };
  }
}
function getTaxCodeList(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getTaxCodeList(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_TAX_CODE_LIST };
  }

  function success(payload) {
    return {
      type: GET_TAX_CODE_LIST_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: GET_TAX_CODE_LIST_ERROR,
      error,
    };
  }
}
function getTaxCodeCountryList(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getTaxCodeCountryList(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_TAX_CODE_COUNTRY_LIST };
  }

  function success(payload) {
    return {
      type: GET_TAX_CODE_COUNTRY_LIST_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: GET_TAX_CODE_COUNTRY_LIST_ERROR,
      error,
    };
  }
}
function deleteTaxCode(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.deleteTaxCode(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: DELETE_TAX_CODE };
  }
  function success(payload) {
    return {
      type: DELETE_TAX_CODE_SUCCESS,
    };
  }
  function failure(error) {
    return { type: DELETE_TAX_CODE_ERROR, error };
  }
}
function createTaxCode(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.createTaxCode(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: CREATE_TAX_CODE };
  }
  function success(payload) {
    return {
      type: CREATE_TAX_CODE_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: CREATE_TAX_CODE_ERROR, error };
  }
}
function getTaxCode(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.getTaxCode(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: GET_TAX_CODE };
  }

  function success(payload) {
    return {
      type: GET_TAX_CODE_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return {
      type: GET_TAX_CODE_ERROR,
      error,
    };
  }
}
function updateTaxCode(options) {
  return (dispatch) => {
    dispatch(request(options));

    fileVatService.updateTaxCode(options).then(
      (response) => dispatch(success(response)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: UPDATE_TAX_CODE };
  }
  function success(payload) {
    return {
      type: UPDATE_TAX_CODE_SUCCESS,
      payload,
    };
  }
  function failure(error) {
    return { type: UPDATE_TAX_CODE_ERROR, error };
  }
}
function setFormulaErrorState() {
  return (dispatch) => {
    dispatch(request());
  };
  function request() {
    return { type: SET_FORMULA_ERROR_STATE };
  }
}
