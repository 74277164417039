import React from "react";

const KeyIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#scw4sghloa)" fill="#324664">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.5 7.366a1.01 1.01 0 0 0 .103-.068c.557.57 1.263.95 2.018 1.113A1.5 1.5 0 0 1 12 7.5h1c.299 0 .577.087.812.238A4 4 0 1 0 7.61 5.58a1.01 1.01 0 0 0-.11.055L2.31 8.63l-.007.004-.007.004-.86.496a1 1 0 0 0 1 1.732l.5.866a1 1 0 1 0 1.733-1l-.5-.866.866-.5.5.866a1 1 0 1 0 1.732-1l-.5-.866 1.732-1zm1.232-1.865a2 2 0 1 1 3.464-2 2 2 0 0 1-3.464 2z"
        />
        <path d="M11.5 10a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2v2a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-2h-2a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h2v-2z" />
      </g>
      <defs>
        <clipPath id="scw4sghloa">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KeyIcon;
