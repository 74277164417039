import "./styles.css";
import {
  MenuItem,
  Select,
  Typography,
  Checkbox,
  Tooltip,
  Collapse,
} from "@mui/material";
import React from "react";
import { InfoItem } from "../../pages/admin-dashboard/components/Input";
import CheckboxUnchecked from "../../assets/icons/CheckboxUnchecked";
import CheckboxChecked from "../../assets/icons/CheckboxChecked";

export const Dropdown = ({
  options,
  disabled,
  value,
  containerStyle,
  onChange,
  label,
  type = "edit",
}) => {
  return (
    <div>
      <Collapse in={type === "edit"}>
        {label && <InfoItem spacing="0px" label={label} />}
        <Select
          onChange={onChange}
          variant="standard"
          disableUnderline
          value={value || options?.[0]?.name}
          label={label}
          className="dropdown"
          style={{
            color: disabled ? "var(--future-tax-60)" : "var(--future-tax-100)",
            fontWeight: "500",
            fontSize: "16px",
            ...containerStyle,
          }}
          IconComponent={(props) =>
            disabled ? (
              <div></div>
            ) : (
              <svg
                {...props}
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                strokeWidth={1}
                stroke="#000E25"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  pointerEvents: "none",
                  marginLeft: "20px",
                }}
              >
                <path
                  d="M4.17 4.818a1.035 1.035 0 0 0-1.518 1.408l4.615 4.982a1 1 0 0 0 1.467 0l4.615-4.982a1.035 1.035 0 0 0-1.518-1.408L8 8.945 4.17 4.818z"
                  fill="#000E25"
                />
              </svg>
            )
          }
        >
          {options?.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              <Typography
                style={{
                  color: "var(--future-tax-100)",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                {item.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </Collapse>
      <Collapse in={type !== "edit"}>
        <InfoItem spacing="0px" label={label} value={value} />
      </Collapse>
    </div>
  );
};

export const MultiSelectDropdown = ({
  options,
  disabled,
  value,
  containerStyle,
  onChange,
  error,
}) => {
  return (
    <Tooltip
      title={"This field is required"}
      arrow
      open={error ? true : false}
      placement="bottom"
    >
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        renderValue={(selected) => selected.join(", ")}
        variant="standard"
        disableUnderline
        className="dropdown"
        style={{
          color: disabled ? "var(--future-tax-60)" : "var(--future-tax-100)",
          fontWeight: "500",
          fontSize: "16px",
          paddingRight: "0px",
          border: error
            ? "1px solid var(--error-100)"
            : "1px solid rgba(0,0,0,0)",
          transition: "border 0.15s",
          ...containerStyle,
        }}
        IconComponent={() =>
          disabled ? (
            <div></div>
          ) : (
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              strokeWidth={1}
              stroke="#000E25"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                userSelect: "none",
                cursor: "pointer",
              }}
            >
              <path
                d="M4.17 4.818a1.035 1.035 0 0 0-1.518 1.408l4.615 4.982a1 1 0 0 0 1.467 0l4.615-4.982a1.035 1.035 0 0 0-1.518-1.408L8 8.945 4.17 4.818z"
                fill="#000E25"
              />
            </svg>
          )
        }
      >
        {options?.map((item) => (
          <MenuItem key={item.id} value={item.name}>
            <Checkbox
              checked={value.indexOf(item.name) > -1}
              size="small"
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "16px" },
              }}
              icon={<CheckboxUnchecked />}
              checkedIcon={<CheckboxChecked />}
            />
            <Typography
              style={{
                color: "var(--future-tax-100)",
                fontWeight: "500",
                fontSize: "16px",
              }}
            >
              {item.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};
