import React from "react";

const PencilIcon = ({ size, color = "#324664" }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.504 1.373a2.926 2.926 0 0 1 4.122 4.123l-6.861 8.148a.997.997 0 0 1-.454.306l-5.995 1.998a1 1 0 0 1-1.265-1.265l2-6a1 1 0 0 1 .309-.452l8.144-6.858zm2.592 2.835-6.16 7.314-2.459-2.46 7.315-6.159a.926.926 0 0 1 1.304 1.305zM3.437 10.85l1.712 1.712-1.629.542-.626-.626.543-1.628z"
        fill={color}
      />
    </svg>
  );
};

export default PencilIcon;
