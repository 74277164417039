export const admin_dashboard_route_strings = {
  adminDashboard: "/dashboard",

  tenantDetails: "/dashboard/details/:merchantId",
  tenantDetails_dynamic: (merchantId) => {
    return `/dashboard/details/${merchantId}`;
  },

  tenantSubscriptions: "/dashboard/details/:merchantId/subscriptions",
  tenantSubscriptions_dynamic: (merchantId) => {
    return `/dashboard/details/${merchantId}/subscriptions`;
  },

  userManagement: "/dashboard/details/:merchantId/users",
  userManagement_dynamic: (merchantId) => {
    return `/dashboard/details/${merchantId}/users`;
  },

  userDetails: "/dashboard/details/:merchantId/users/:userId",
  userDetails_dynamic: (merchantId, userId) => {
    return `/dashboard/details/${merchantId}/users/${userId}`;
  },

  tenantShops: "/dashboard/details/:merchantId/shops",
  tenantShops_dynamic: (merchantId) => {
    return `/dashboard/details/${merchantId}/shops`;
  },

  shopDetails: "/dashboard/details/:merchantId/shops/:shopId",
  shopDetails_dynamic: (merchantId, shopId) => {
    return `/dashboard/details/${merchantId}/shops/${shopId}`;
  },

  roles: "/dashboard/details/:merchantId/roles",
  roles_dynamic: (merchantId) => {
    return `/dashboard/details/${merchantId}/roles`;
  },
};
