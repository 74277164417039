import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import {
  Button,
  CircularProgress,
  Tooltip,
  Modal,
  IconButton,
  Divider,
} from "@mui/material";
import { CSVLink } from "react-csv";
import { FormattedMessage } from "react-intl";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { formattedMessage } from "../../../configuration/helpers";

import { ModalHeader } from "../../../components/SectionHeader/SectionHeaders";
import Alert from "./Alert";
import { Input } from "./Input";

const AccessKeyModal = ({ modalOpen, setModalOpen, keyDetails, onClose }) => {
  const [isSecretKeyVisible, setIsSecretKeyVisible] = useState(false);

  const [isDownloadingKey, setIsDownloadingKey] = useState(false);

  const tableRef = useRef();

  const modalStyle = {
    position: "absolute",
    top: "25%",
    left: "50%",
    transform: "translate(-50%, -25%)",
    width: "76%",
    bgcolor: "infoLight.main",
    borderRadius: "6px",
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsSecretKeyVisible(false);
    // onClose();
  };

  const emojis = ["🥳​", "​😇", "😊​​", "🎉​​", "👍​​​"];
  const [tooltipText, setTooltipText] = useState("accessKey.copy");
  function ClipboardButton(props) {
    function handleClick() {
      navigator.clipboard.writeText(JSON.stringify(keyDetails));
      setTooltipText("accessKey.copied");
    }

    return (
      <Tooltip
        placement="top"
        arrow
        title={
          <span>
            <FormattedMessage id={tooltipText} />{" "}
            {tooltipText === "accessKey.copied" &&
              emojis[Math.floor(Math.random() * (4 - 0) + 0)]}
          </span>
        }
        onMouseEnter={() => {
          setTooltipText("accessKey.copy");
        }}
      >
        <IconButton
          aria-label="copy-key"
          onClick={handleClick}
          style={props.style}
        >
          <ContentCopyIcon style={{ fontSize: "18px" }} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Modal
      open={modalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <ModalHeader
          onClose={handleCloseModal}
          title={formattedMessage("generateAccessKey")}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px 16px 24px",
          }}
        >
          <Input
            onIconClick={() => {
              navigator.clipboard.writeText(JSON.stringify(keyDetails));
            }}
            iconTitle={formattedMessage("accessKey.copy")}
            value={keyDetails.access_key_id}
            label={<FormattedMessage id="keyId" />}
            disabled
            icon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 7.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4zM3 5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H3z"
                  fill="#000E25"
                />
                <path
                  d="M14 5.5A3.5 3.5 0 0 0 10.5 2H6v2h4.25c.966 0 1.75.784 1.75 1.75V10h2V5.5z"
                  fill="#000E25"
                />
              </svg>
            }
          />
          <Input
            iconTitle={formattedMessage("accessKey.copy")}
            onIconClick={() => {
              navigator.clipboard.writeText(JSON.stringify(keyDetails));
            }}
            value={keyDetails.access_key_secret}
            label={<FormattedMessage id="secretAccessKey" />}
            disabled
            icon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 7.5a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4zM3 5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H3z"
                  fill="#000E25"
                />
                <path
                  d="M14 5.5A3.5 3.5 0 0 0 10.5 2H6v2h4.25c.966 0 1.75.784 1.75 1.75V10h2V5.5z"
                  fill="#000E25"
                />
              </svg>
            }
          />
          <Alert
            content={formattedMessage("neverPostKeys")}
            type="error"
            style={{ marginTop: "16px" }}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            height: " 72px",
            alignSelf: " stretch",
            flexGrow: " 0",
            display: " flex",
            flexDirection: " row",
            justifyContent: "space-between",
            alignItems: " center",
            padding: " 20px",
          }}
        >
          <Button
            variant="text"
            sx={{ borderRadius: "6px", height: " 32px" }}
            onClick={() => setModalOpen(false)}
          >
            <FormattedMessage id="Cancel" />
          </Button>
          <CSVLink
            style={{ textDecoration: "none" }}
            data={[
              {
                [formattedMessage("keyId")]: keyDetails.access_key_id,
                [formattedMessage("secretAccessKey")]:
                  keyDetails.access_key_secret,
              },
            ]}
            filename={`${formattedMessage("accessKeyDetails")}_${
              keyDetails.access_key_id
            }.csv`}
            target="_blank"
          >
            <Button
              disableElevation
              startIcon={
                isDownloadingKey ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#g7jtve8a4a)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 8.5a6 6 0 1 1-12 0 6 6 0 0 1 12 0zm2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm-9-2v2.676l-.793-.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l2.5-2.5a1 1 0 0 0-1.414-1.414L9 9.175V6.5a1 1 0 1 0-2 0z"
                        fill="#FCFDFF"
                      />
                    </g>
                    <defs>
                      <clipPath id="g7jtve8a4a">
                        <path
                          fill="#fff"
                          transform="translate(0 .5)"
                          d="M0 0h16v16H0z"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }
              disabled={isDownloadingKey}
              color="primary"
              variant="contained"
              sx={{
                height: " 32px",
                flexGrow: " 0",
                display: " flex",
                flexDirection: " row",
                justifyContent: " center",
                alignItems: " center",
                gap: " 8px",
                borderRadius: " 6px",
                fontSize: "14px",
              }}
            >
              <FormattedMessage id={"downloadCsvFile"} />
            </Button>
          </CSVLink>
        </Box>
      </Box>
    </Modal>
  );
};

export default AccessKeyModal;
