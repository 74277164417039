import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Draggable, DragDropContext, Droppable } from "react-beautiful-dnd";
import {
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
  Chip,
} from "@mui/material";
import { AddCircleOutline, Search } from "@mui/icons-material";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as CalculatorIcon } from "../../../assets/icons/calculator.svg";
import { ReactComponent as CalculatorBlackIcon } from "../../../assets/icons/calculator-black.svg";

import { ReactComponent as OrderIcon } from "../../../assets/icons/order.svg";
import { fileVatActions } from "../../../redux/actions/filevat";
import { useDispatch } from "react-redux";
import FormulaModal from "./FormulaModal";

function TaxAttributeTable({
  state,
  country,
  isBoxEditableActive,
  setIsBoxEditableActive,
  isEditable,
  attributeTaxList,
  setAttributeTaxList,
  setBoxInput,
  handleDeleteAttribute,
  boxErrors,
}) {
  const [ascending, setAscending] = useState(false);
  const [editableRowId, setEditableRowId] = useState(null);
  const [orderBy, setOrderBy] = useState("filingMonth");
  const [searchedText, setSearchedText] = useState("");
  const [isFormulaModalVisible, setIsFormulaModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 25,
    // totalRecords: 25,
  });
  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property ? !ascending : false;
    setAscending(isAsc);
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPagination({
      ...pagination,
      pageNumber: newPage,
    });
  };

  const handleChangePageSize = (event) => {
    setPagination({
      ...pagination,
      pageSize: +event.target.value,
      page: 0,
    });
  };
  const getStatusColor = (status) => {
    return status === "ACTIVE"
      ? "bg-green"
      : status === "DRAFT"
      ? "bg-orange"
      : "bg-grey";
  };
  const headCells = [
    {
      id: "newRow",
      numeric: false,
      disablePadding: true,
      label: [
        !isBoxEditableActive && !searchedText && (
          <IconButton className="add-icon" onClick={() => addNewRow(0, true)}>
            <AddCircleOutline />
          </IconButton>
        ),
      ],
      sort: false,
    },
    ,
    {
      id: "boxNumber",
      numeric: false,
      disablePadding: true,
      label: "Box Number",
      sort: false,
    },
    {
      id: "boxName",
      numeric: true,
      disablePadding: false,
      label: "Box Name",
      sort: false,
    },
    {
      id: "mapping",
      numeric: true,
      disablePadding: false,
      label: "Mapping",
      sort: false,
    },
    {
      id: "type",
      numeric: true,
      disablePadding: false,
      label: "Type",
      sort: false,
    },
    {
      disablePadding: false,
      label: "Action",
      sort: false,
    },
  ];
  const SortIndicator = ({ direction, active, colId, orderBy }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2px",
        marginLeft: "10px",
        height: "100%",
      }}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "ASC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="scale(-1,-1)"
        style={{ marginTop: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "DESC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
  const onDragEnd = (result) => {
    if (!result.destination) return;

    // attributeTaxList is updating on response
    dispatch(
      fileVatActions.updateTaxAttribute({
        ...attributeTaxList[result.source.index],
        order: result.destination.index + 1,
      })
    );
  };
  const addNewRow = (selectedIndex, header = false) => {
    const destinationIndex = header
      ? 0
      : selectedIndex === 0
      ? 1
      : selectedIndex + 1;
    const newRow = {
      boxNumber: "",
      boxName: "",
      type: "",
      order: "",
    };
    let items = [];
    items = Array.from(attributeTaxList);

    items.splice(destinationIndex, 0, newRow);
    setEditableRowId(destinationIndex);
    setIsBoxEditableActive(true);

    for (let index = 0; index < items.length; index++) {
      items[index] = { ...items[index], order: index + 1 };
    }

    setAttributeTaxList(items);
    setBoxInput(newRow);
  };
  const editRow = (e, index) => {
    if (e.detail === 2) {
      setEditableRowId(index);
      setIsBoxEditableActive(true);
      setBoxInput(attributeTaxList[index]);
    }
  };
  const deleteAttribute = (id, index) => {
    if (id) {
      handleDeleteAttribute(id);
    } else {
      const selectedRow = JSON.parse(JSON.stringify(attributeTaxList));
      const copyWithoutElement = selectedRow.slice(0, index);

      setAttributeTaxList(copyWithoutElement);
      setBoxInput({});
    }
  };

  const handleSearch = (e) => {
    if (e.trim().length > 2) {
      setSearchedText(e);
    } else {
      setSearchedText("");
    }
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    // const selectedRow = [...attributeTaxList];
    const selectedRow = JSON.parse(JSON.stringify(attributeTaxList));
    selectedRow[index][name] = value;
    setAttributeTaxList(selectedRow);
    setBoxInput(selectedRow[index]);
  };
  const handleFormulaChange = (tags, index) => {
    setIsFormulaModalVisible(false);
    // const selectedRow = [...attributeTaxList];
    const selectedRow = JSON.parse(JSON.stringify(attributeTaxList));
    selectedRow[index].formula = tags;
    setAttributeTaxList(selectedRow);
    setBoxInput(selectedRow[index]);
  };
  return (
    <div className={`attribute-section ${isEditable && "disable-op"}`}>
      <div className="search-header">
        <TextField
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search className="search-icon" />
              </InputAdornment>
            ),
          }}
          placeholder="Filter by Text and/or Keywords"
          // value={comment}
          // onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          size="small"
          aria-label="simple table"
          className="attribute-table"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={"left"}>
                  {
                    headCell.id === "newRow"
                      ? headCell.label
                      : // <TableSortLabel
                        //   active={orderBy === headCell.id}
                        //   disabled={!headCell.sort}
                        //   hideSortIcon={!headCell.sort}
                        //   direction={ascending ? "asc" : "desc"}
                        //   onClick={createSortHandler(headCell.id)}
                        //   IconComponent={() => (
                        //     <SortIndicator
                        //       direction={ascending ? "ASC" : "DESC"}
                        //       active={orderBy === headCell.id}
                        //       colId={headCell.id}
                        //       orderBy={orderBy}
                        //     />
                        //   )}
                        // >
                        headCell.label
                    // </TableSortLabel>
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {attributeTaxList && attributeTaxList.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {attributeTaxList
                      ?.filter(
                        (row) =>
                          row?.boxNumber?.match(
                            new RegExp(searchedText, "i")
                          ) ||
                          row?.boxName?.match(new RegExp(searchedText, "i")) ||
                          row?.type?.match(new RegExp(searchedText, "i"))
                      )

                      ?.map((taxItem, index) => (
                        <Draggable
                          key={taxItem.order}
                          draggableId={"order-" + taxItem.order}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <TableRow
                              className="drag-table-row"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              onClick={(e) =>
                                !isBoxEditableActive && editRow(e, index)
                              }
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ width: "5%" }}
                              >
                                <IconButton className="order-icon">
                                  <OrderIcon />
                                </IconButton>
                                {!isBoxEditableActive && !searchedText && (
                                  <IconButton
                                    onClick={() => addNewRow(index)}
                                    className="add-icon"
                                  >
                                    <AddCircleOutline />
                                  </IconButton>
                                )}
                              </TableCell>
                              <TableCell style={{ width: "15%" }}>
                                {editableRowId === index &&
                                isBoxEditableActive &&
                                !searchedText ? (
                                  <TextField
                                    fullWidth
                                    value={taxItem.boxNumber}
                                    name="boxNumber"
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    inputProps={{
                                      maxLength: 25,
                                    }}
                                    error={boxErrors.boxNumber}
                                    helperText={boxErrors.boxNumber}
                                  ></TextField>
                                ) : (
                                  taxItem.boxNumber
                                )}
                              </TableCell>{" "}
                              <TableCell style={{ width: "20%" }}>
                                {editableRowId === index &&
                                isBoxEditableActive &&
                                !searchedText ? (
                                  <TextField
                                    name="boxName"
                                    value={taxItem.boxName}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    inputProps={{
                                      maxLength: 100,
                                    }}
                                    error={boxErrors.boxName}
                                    helperText={boxErrors.boxName}
                                  />
                                ) : (
                                  taxItem.boxName
                                )}
                              </TableCell>
                              <TableCell style={{ width: "40%" }}>
                                {editableRowId === index &&
                                isBoxEditableActive &&
                                !searchedText ? (
                                  <>
                                    <IconButton
                                      className="calculator-btn"
                                      onClick={() =>
                                        setIsFormulaModalVisible(editableRowId)
                                      }
                                    >
                                      <CalculatorIcon />
                                    </IconButton>

                                    {taxItem.formula &&
                                      taxItem.formula.map((data, index) => {
                                        return (
                                          <Chip
                                            className="formula-tags"
                                            key={index}
                                            tabIndex={-1}
                                            label={data.key}
                                            // onDelete={() => handleDelete(data)}
                                          />
                                        );
                                      })}

                                    <FormulaModal
                                      isFormulaModalVisible={
                                        isFormulaModalVisible === index
                                      }
                                      formula={taxItem.formula}
                                      setIsFormulaModalVisible={
                                        setIsFormulaModalVisible
                                      }
                                      handleOnSelect={(tags) =>
                                        handleFormulaChange(tags, index)
                                      }
                                    ></FormulaModal>
                                  </>
                                ) : (
                                  <>
                                   
                                    <IconButton
                                      className="calculator-btn bg-grey"
                                   
                                    >
                                      <CalculatorBlackIcon />
                                    </IconButton>
                                    {taxItem.formula &&
                                      taxItem.formula.map((data, index) => {
                                        return (
                                          <Chip
                                            className="formula-tags"
                                            key={index}
                                            tabIndex={-1}
                                            label={data.key}
                                            // onDelete={() => handleDelete(data)}
                                          />
                                        );
                                      })}{" "}
                                  </>
                                )}
                              </TableCell>
                              <TableCell style={{ width: "10%" }}>
                                {editableRowId === index &&
                                isBoxEditableActive &&
                                !searchedText ? (
                                  <TextField
                                    select
                                    name="type"
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    fullWidth
                                    value={taxItem.type}
                                    error={boxErrors.type}
                                    helperText={boxErrors.type}
                                  >
                                    <MenuItem value="String">String</MenuItem>
                                    <MenuItem value="Decimal">Decimal</MenuItem>
                                    <MenuItem value="Integer">Integer</MenuItem>
                                  </TextField>
                                ) : (
                                  taxItem.type
                                )}
                              </TableCell>
                              <TableCell style={{ width: "30%" }}>
                                {taxItem.id && (
                                  <IconButton
                                    onClick={() =>
                                      deleteAttribute(taxItem.id, index)
                                    }
                                  >
                                    <DeleteIcon />{" "}
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <TableRow>
              <TableCell colSpan={6}>
                <div className="no-records">No entries added yet.</div>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        // count={overviewList.totalElements}
        rowsPerPage={pagination.pageSize}
        page={pagination.pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
      /> */}
    </div>
  );
}

export default TaxAttributeTable;
