import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  AddCircleOutline,
  ArrowBackIos,
  ArrowForward,
  InfoOutlined,
} from "@mui/icons-material";

import { checkIfImageExists, getCountryName } from "./common/helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import VersionTable from "./components/VersionTable";
import ConfirmDialog from "./components/ConfirmDialog";
import { useDispatch } from "react-redux";
import { fileVatActions } from "../../redux/actions/filevat";
import { useSelector } from "react-redux";
export default function VersionsList() {
  const { versionDeletedSuccess, taxVersionDefault } = useSelector(
    (state) => state.fileVat
  );
  const [defaultVersion, setDefaultVersion] = useState(null);
  const [currentDefaultVersion, setCurrentDefaultVersion] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { state } = useLocation();
  const { country } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogData, setDialogData] = useState({
    title: "",
    type: "",
    content: "",
  });

  //useEffect
  useEffect(() => {
    dispatch(
      fileVatActions.getTaxVersionList({
        taxId: state && state.id,
        countryCode: country.toUpperCase(),
      })
    );
  }, []);
  // need to check this out
  /* i am working on deletion attribute changes, test it */
  useEffect(() => {
    if (Object.keys(taxVersionDefault).length > 0) {
      dispatch(
        fileVatActions.getTaxVersionList({
          taxId: state.id,
          countryCode: country.toUpperCase(),
        })
      );
      handleCancel();
    }
  }, [taxVersionDefault]);

  useEffect(() => {
    if (versionDeletedSuccess === true) {
      dispatch(
        fileVatActions.getTaxVersionList({
          taxId: state.id,
          countryCode: country.toUpperCase(),
        })
      );
    }
  }, [versionDeletedSuccess]);

  //End
  function handleCheckBox(item) {
    setDefaultVersion(item);
  }
  function handleCancel() {
    setIsEditable(false);
    setDefaultVersion(null);
  }

  const handleSubmit = () => {
    if (defaultVersion) {
      if (
        currentDefaultVersion &&
        defaultVersion.id === currentDefaultVersion.id
      ) {
        handleCancel();
      } else {
        setDialogOpen(true);
        setDialogData({
          type: "defaultVersion",
          title:
            "Are you sure you want to change the default version to this one? ",
          ...(currentDefaultVersion && {
            content: `For the current default version ${currentDefaultVersion.name}, the default flag will be removed!`,
          }),
        });
      }
    } else {
      handleCancel();
    }
  };
  const handleDialogResponse = () => {
    setDialogOpen(false);
    switch (dialogData.type) {
      case "defaultVersion":
        dispatch(
          fileVatActions.updateTaxVersion({
            ...defaultVersion,
            defaultTax: "YES",
          })
        );
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="breadcrumb">
        <Link to={"/filevat-tax-forms"} className="link">
          <ArrowBackIos />
          <span className="text"> Tax Forms Overview</span>
        </Link>
        <span className="link current">
          <IconButton>
            <ArrowForward />
          </IconButton>
          <span className="text"> {state && state.taxName}</span>
        </span>
      </div>
      <Card
        sx={{ minWidth: 275, ...(isEditable && { marginBottom: "150px" }) }}
        className="card version-list-card"
      >
        <CardHeader
          className="header"
          action={[
            <IconButton
              disabled={isEditable}
              onClick={() => setIsEditable(true)}
            >
              <EditIcon />
            </IconButton>,
            <IconButton
              onClick={() =>
                navigate(`/filevat-version-detail/${country.toLowerCase()}`, {
                  state: { ...state, viewType: "create" },
                })
              }
            >
              <AddCircleOutline className="icon" />
            </IconButton>,
          ]}
          title={
            <div className="country-flex">
              <span className="flag">
                <img alt="" src={checkIfImageExists(country)} width={30} />
              </span>
              <span className="title">
                {getCountryName(country)} / {state && state.taxName}
              </span>
              <span className="text-gray ml-1"> Version</span>
              <Tooltip title={"information"} arrow>
                <InfoOutlined className="info-icon" />
              </Tooltip>
            </div>
          }
        />

        {/* <CardContent className="content"> */}
        <VersionTable
          state={state}
          country={country}
          defaultVersion={defaultVersion}
          handleCheckBox={handleCheckBox}
          isEditable={isEditable}
          setCurrentDefaultVersion={setCurrentDefaultVersion}
        ></VersionTable>
        {/* </CardContent> */}
      </Card>
      {isEditable && (
        <div className="edit-footer">
          {defaultVersion &&
            currentDefaultVersion &&
            defaultVersion.id !== currentDefaultVersion.id && (
              <Alert
                className="overlap-error-default"
                icon={<InfoOutlined />}
                severity="info"
                variant="filled"
                style={{ marginBottom: "10px" }}
              >
                <AlertTitle className="alert-title">Attention</AlertTitle>
                By saving you’re agreeing to set the Version{" "}
                {defaultVersion.name} as default
              </Alert>
            )}

          <div className="edit-footer-btn">
            <Button
              variant="text"
              // disabled={loading}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              // disabled={
              //   loading ||
              //   Object.keys(errors).length !== 0 ||
              //   (versionInput.status === "ACTIVE" &&
              //     validationSuccess.length !== 0)
              // }
              onClick={() => handleSubmit()}
            >
              Save changes
            </Button>
          </div>
        </div>
      )}
      <ConfirmDialog
        open={dialogOpen}
        data={dialogData}
        handleClose={() => setDialogOpen(false)}
        handleOk={() => handleDialogResponse()}
      />
    </>
  );
}
