import "./styles.css";
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { URLS } from "../configuration/urls";
import {
  AlertTitle,
  CircularProgress,
  Collapse,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { NumbersRounded } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { passwordStrength } from "check-password-strength";

function SetNewPassword(props) {
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      code: "",
      newPassword: "",
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    await submitCode();
    setLoading(false);
  };

  const submitCode = async () => {
    // const userData = JSON.parse(localStorage.getItem("userData"));
    const formData = {
      email: props.email,
      code: getValues().code,
      password: getValues().newPassword,
    };
    await axios
      .post(`${URLS.RESET_PASSWORD_WITH_CODE}`, formData, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        // const token = res?.data?.access_token;
        // localStorage.setItem("token", JSON.stringify(token));
        // axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
        setShowLoginMessage(true);
        setError({});
        enqueueSnackbar(<FormattedMessage id="passwordResetSuccess" />, {
          variant: "success",
        });
        navigate("/");
      })
      .catch((err) => {
        setError(err);
        setShowLoginMessage(false);
        console.log("Response", err);
        // if (localStorage.getItem("userData")) {
        //   localStorage.clear();
        // } else {
        //   navigate("/");
        // }
      });
  };

  const getPasswordStrength = () => {
    switch (passwordStrength(getValues().newPassword).value) {
      case "Too weak":
        return { value: 0, color: "var(--future-tax-80)" };
      case "Weak":
        return { value: 25, color: "var(--error-100)" };
      case "Medium":
        return { value: 50, color: "var(--warning-100)" };
      case "Strong":
        return { value: 100, color: "var(--success-100)" };
      default:
        return { value: 0, color: "var(--future-tax-80)" };
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={`eclear-bg section center-v-h`}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
        className={`glass-surface`}
      >
        {loading && (
          <CircularProgress
            size={48}
            sx={{
              color: "green",
              position: "absolute",
              top: 34,
              // left: -6,
              zIndex: 1,
            }}
          />
        )}
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <NumbersRounded />
        </Avatar>
        <Typography component="h1" variant="h5">
          <FormattedMessage id="Enter 6-digit code from email" />
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
          style={{ display: "flex", flexDirection: "column", width: "30rem" }}
        >
          <Collapse in={Object.keys(error).length > 0}>
            <Alert
              severity="error"
              sx={{ background: "var(--error-4)" }}
              action={
                <IconButton onClick={() => setError({})}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="m4.5 11.5 7-7M4.5 4.5l7 7"
                      stroke="#E01600"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </IconButton>
              }
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.5683 4.15415L7.43678 11.8514H8.56836L10.5681 11.8514V13.8514H13.0707C13.0699 13.8514 13.0691 13.8514 13.0683 13.8514H10.5684L10.5684 19.5486L15.6998 11.8514H14.5683V12.3514C14.5683 12.3581 14.5682 12.3647 14.5681 12.3714V11.8514H12.5681V9.85137L12.5683 9.85138V4.15415ZM6.50252 13.8514H8.56836L8.56836 21.2C8.56836 22.6841 10.4932 23.2669 11.3164 22.032L17.8822 12.1834C18.5467 11.1866 17.8321 9.85138 16.6341 9.85138H14.5683V2.50276C14.5683 1.01866 12.6434 0.435856 11.8202 1.67071L5.25445 11.5193C4.58989 12.5162 5.30448 13.8514 6.50252 13.8514Z"
                    fill="#E01600"
                  />
                </svg>
              }
            >
              <AlertTitle sx={{ color: "var(--error-108)", fontSize: "14px" }}>
                <FormattedMessage id="error" />
              </AlertTitle>
              <Typography sx={{ color: "var(--error-108)", fontSize: "14px" }}>
                <FormattedMessage id={error?.message || "nullOrUndefined"} />
              </Typography>
            </Alert>
          </Collapse>
          <Controller
            name={"code"}
            control={control}
            rules={{
              required: true,
              validate: {
                codeValidation: (value) => {
                  return /^\d{6}$/.test(value);
                },
              },
            }}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <React.Fragment>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label={<FormattedMessage id="Verification code" />}
                  autoFocus
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  value={value}
                  error={error}
                  inputProps={{
                    maxLength: 6,
                    pattern: "[0-9]*",
                    type: "number",
                  }}
                />
                <Collapse
                  in={error}
                  style={{
                    padding: "0px 10px",
                    marginTop: "-12px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: "var(--error-100)",
                    }}
                  >
                    {error?.type === "codeValidation" ? (
                      <FormattedMessage id="Code must be 6 digits long" />
                    ) : (
                      <FormattedMessage id="Code is required" />
                    )}
                  </span>
                </Collapse>
              </React.Fragment>
            )}
          />

          <Controller
            name={"newPassword"}
            control={control}
            rules={{
              required: true,
              validate: {
                length: (value) => {
                  return value.length >= 8;
                },
              },
            }}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <React.Fragment>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label={<FormattedMessage id="Password" />}
                  type="password"
                  id="newPassword"
                  ref={ref}
                  name={name}
                  onChange={onChange}
                  value={value}
                  error={error}
                  style={{ marginBottom: "20px" }}
                />
                <Collapse
                  in={error}
                  style={{
                    padding: "0px 10px",
                    marginTop: "-12px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      color: "var(--error-100)",
                    }}
                  >
                    {error?.type === "length" ? (
                      <FormattedMessage id="Password must be at least 8 characters long" />
                    ) : (
                      <FormattedMessage id="Password is required" />
                    )}
                  </span>
                </Collapse>
                <Collapse in={!error}>
                  <Box sx={{ color: getPasswordStrength().color }}>
                    <LinearProgress
                      variant="determinate"
                      value={getPasswordStrength().value}
                      color="inherit"
                      style={{ borderRadius: "10px", marginTop: "-12px" }}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        color: "inherit",
                        textAlign: "right",
                      }}
                    >
                      {getValues().newPassword.length > 0 ? (
                        <FormattedMessage
                          id={passwordStrength(getValues().newPassword).value}
                        />
                      ) : (
                        <FormattedMessage id="Password strength" />
                      )}
                    </div>
                  </Box>
                </Collapse>
              </React.Fragment>
            )}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              disableElevation
              onClick={props.goBack}
              disabled={loading}
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              disableElevation
              type="submit"
              disabled={loading}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              <FormattedMessage id="Submit" />
            </Button>
          </div>
        </Box>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(SetNewPassword);
