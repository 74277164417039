import React from "react";
import {
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { ReactComponent as EditText } from "../../../assets/icons/editText.svg";
import { ReactComponent as BlockIcon } from "../../../assets/icons/blockIcon.svg";
import { getStatusColor } from "../common/helper";

import { SingleDatePicker } from "../components/SingleDatePicker";
import moment from "moment";

export default function VersionFormField({
  state,
  isEditable,
  handleInputChange,
  versionInput,
  errors,
  isBoxEditableActive,
}) {
  return (
    <div className={`form-fields ${isBoxEditableActive && "disable-op"}`}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={2}>
          <InputLabel>Name </InputLabel>
          {isEditable ? (
            <TextField
              error={errors.name}
              fullWidth
              placeholder="Version name"
              name="name"
              inputProps={{
                maxLength: 25,
              }}
              onChange={handleInputChange}
              value={versionInput.name}
              InputProps={{
                endAdornment: <EditText />,
              }}
              helperText={errors.name}
            ></TextField>
          ) : (
            <span className="text">{versionInput.name}</span>
          )}
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Type </InputLabel>
          {isEditable ? (
            <TextField
              fullWidth
              placeholder="Type"
              name="type"
              value={state.taxName}
              disabled={true}
              InputProps={{
                endAdornment: <BlockIcon />,
              }}
            ></TextField>
          ) : (
            <span className="text">{state.taxName}</span>
          )}
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Valid from to </InputLabel>
          {isEditable ? (
            <SingleDatePicker
              handleInputChange={handleInputChange}
              validFrom={versionInput.validFrom}
              validTo={versionInput.validTo}
            />
          ) : (
            <span className="text">
              {versionInput.validFrom &&
                moment(versionInput.validFrom).format("YYYY.MM")}{" "}
              -{" "}
              {versionInput.validTo &&
                moment(versionInput.validTo).format("YYYY.MM")}
            </span>
          )}
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Status </InputLabel>
          {isEditable ? (
            <Select
              id="select-dateRange"
              name="status"
              fullWidth
              onChange={handleInputChange}
              value={versionInput.status}
            >
              <MenuItem value="DRAFT">
                <span className="select-status-text bg-orange">Draft</span>
              </MenuItem>
              <MenuItem value="ACTIVE">
                <span className="select-status-text bg-green">Active</span>
              </MenuItem>
              {/* <MenuItem disabled value="INACTIVE">
                {" "}
                <span className="select-status-text bg-grey">Inactive</span>
              </MenuItem> */}
            </Select>
          ) : (
            <span className="text">
              <span
                style={{ padding: "5px" }}
                className={`select-status-text ${getStatusColor(
                  versionInput.status
                )}`}
              >
                {versionInput.status}
              </span>
            </span>
          )}
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Default Version</InputLabel>
          {isEditable ? (
            <Select
              id="select-dateRange"
              disabled
              fullWidth
              value={versionInput.defaultTax}
            >
              <MenuItem value="YES">
                <span>Yes</span>
              </MenuItem>
              <MenuItem value="NO">
                <span>No</span>
              </MenuItem>
              {/* <MenuItem disabled value="INACTIVE">
                {" "}
                <span className="select-status-text bg-grey">Inactive</span>
              </MenuItem> */}
            </Select>
          ) : (
            <span className="text">
              <span>
                {versionInput.defaultTax.charAt(0) +
                  versionInput.defaultTax.slice(1).toLowerCase()}
              </span>
            </span>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Description </InputLabel>
          {isEditable ? (
            <TextField
              className="multi-row-text"
              fullWidth
              placeholder="Please enter text"
              name="description"
              value={versionInput.description}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EditText />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 255,
              }}
              multiline
              //  rows={4}

              helperText={`${
                versionInput.description ? versionInput.description.length : "0"
              }/${255}`}
            ></TextField>
          ) : (
            <span className="text">{versionInput.description}</span>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
