import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { URLS } from "../configuration/urls";
import { AlertTitle, CircularProgress } from "@mui/material";
import { NumbersRounded } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { admin_dashboard_route_strings } from "../route_strings";

function Verify(props) {
  const [error, setError] = useState({});
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  // const [isEmailSuccess, setIsEmailSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShowLoginMessage(false);
    if (localStorage.getItem("getToken")) {
      navigate(admin_dashboard_route_strings.adminDashboard);
    }
    if (!localStorage.getItem("userData")) {
      navigate("/");
    }
  }, []);

  const submitCode = async () => {
    setLoading(true);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const formData = {
      email: userData?.email,
      code,
    };
    await axios
      .post(`${URLS.VERIFY_EMAIL_BY_CODE}`, formData)
      .then((res) => {
        localStorage.clear();
        setError({});
        setShowLoginMessage(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setShowLoginMessage(false);
        setLoading(false);
        console.log("Response", err);
      });
  };

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }

    if (event.key === "Enter") {
      submitCode();
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      className={`eclear-bg section center-v-h`}
    >
      <CssBaseline />

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
        className={`glass-surface`}
      >
        {loading && (
          <CircularProgress
            size={48}
            sx={{
              color: "green",
              position: "absolute",
              top: 34,
              // left: -6,
              zIndex: 1,
            }}
          />
        )}
        {Object.keys(error).length > 0 && (
          <Alert severity="warning" onClose={() => setError({})}>
            <AlertTitle>{error?.code}</AlertTitle>
            {error?.message}
          </Alert>
        )}
        {!showLoginMessage ? (
          <>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <NumbersRounded />
            </Avatar>
            <Typography component="h1" variant="h5">
              <FormattedMessage id="Enter 6-digit code from email" />
            </Typography>
            <br></br>
            <TextField
              name="code"
              required
              fullWidth
              id="code"
              label={<FormattedMessage id="Verification code" />}
              autoFocus
              onChange={(e) => setCode(e.target.value)}
              inputProps={{
                maxLength: 6,
                pattern: "[0-9]*",
                inputMode: "numeric",
              }}
              onKeyPress={handleKeyPress}
            />
            <Button
              onClick={() => submitCode()}
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              <FormattedMessage id="Submit" />
            </Button>
          </>
        ) : (
          <>
            <Typography component="h1" variant="h5">
              <FormattedMessage id="You have signed up successfully!" />
            </Typography>
            <Typography component="p" variant="p">
              <FormattedMessage id="Your account has been verified." />
            </Typography>
            <Link href="/" variant="body2" marginTop="20px" fontSize="20px">
              <FormattedMessage id="Click here to Login" />
            </Link>
          </>
        )}
        <Grid container justifyContent="center">
          <Grid item>
            <Link
              onClick={() => {
                navigate("/register");
              }}
              style={{ cursor: "pointer" }}
              variant="body2"
            >
              <FormattedMessage id="Cancel" />
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Verify);
