import React from "react";

const DisableIcon2 = ({ color = "var(--primary)", size = "16px" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 8a6 6 0 1 1 12 0A6 6 0 0 1 2 8zm2.432 1.81A4 4 0 0 1 9.81 4.432L4.432 9.81zm1.326 1.503a4 4 0 0 0 5.555-5.555l-5.555 5.555z"
        fill={color}
      />
    </svg>
  );
};

export default DisableIcon2;
