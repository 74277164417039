import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  TableCell,
  TableRow,
  Tooltip,
  Button,
  Modal,
  Typography
} from "@mui/material";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import { FormattedMessage } from "react-intl";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import * as _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { URLS } from "../../../../configuration/urls";
import { formattedDate } from "../../../../configuration/helpers";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import { useParams } from "react-router-dom";
import {
  getAllData,
  getMerchant,
  getUserManagementData
} from "../../../../services/adminDashboard";
import Toggle from "../../components/Toggle";
import TableComponent from "../../components/Table/Table";
import { ECAxiosAPI } from "../../../../configuration/interceptor";
import Alert from "../../components/Alert";
import moment from "moment/moment";


const TenantSubscriptions = () => {
  const [switches, setSwitches] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("service");
  const [errorMessage, setErrorMessage] = useState();

  const { merchantId } = useParams();

  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );

  const userSubscriptions = useSelector(
    (state) => state.adminDashboard.userSubscriptions
  );

  const SubscriptionsTableRow = (item) => {
    item = item.row;
    const [isActive, setIsActive] = useState(item.isActive);

    const toggleSalesOrder = async ({ product, date }) => {
      await ECAxiosAPI.post(`${URLS.ACTIVATE_SUBSCRIPTION}`, {
        merchantId: Number(merchantId),
        activate: !isActive,
        productId: product.productId,
        ...(date &&
          {
            attributes: {
              "attr_start_date": moment(date).format("YYYY-MM-DDTHH:mm:ss.0")
            }
          }
        )
      })
      .then((res) => {
        setErrorMessage();
        setIsActive(!isActive);
        getMerchant(merchantId);
        enqueueSnackbar(
          <FormattedMessage
            id="salesOrder.status"
            values={{
              s: (...chunks) => {
                return (
                  <span
                    style={{
                      textTransform: "lowercase",
                      display: "inline-block"
                    }}
                  >
                      <FormattedMessage
                        id={!isActive ? "activated" : "deactivated"}
                      />
                    </span>
                );
              },
              p: (...chunks) => {
                return <span>{product.productName}</span>;
              }
            }}
          />,
          {
            variant: "success"
          }
        );
      })
      .catch((err) => {
        setErrorMessage(err);
        if (err.response) {
        }
      });
    };

    return (
      <React.Fragment>
        <TableRow key={item.id} hover>
          <TableCell
            sx={{
              borderBottom: "solid 1px var(--future-tax-8)"
            }}
          >
            {item.productName}
          </TableCell>
          <TableCell>{item?.subscription?.salesOrderId || "-"}</TableCell>
          <TableCell>
            {item?.subscription?.startDate
              ? formattedDate(item?.subscription?.startDate)
              : "-"}
          </TableCell>
          <TableCell
            align="left"
            sx={{
              borderBottom: "solid 1px var(--future-tax-8)"
            }}
          >
            <Toggle
              isChecked={isActive}
              item={item}
              onConfirm={(date) => toggleSalesOrder({ product: item, date })}
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  useEffect(() => {
    _.isEmpty(userSubscriptions) && getAllData({ merchantId });
  }, []);

  const sortData = (data, order, orderBy) => {
    const flattenedData = _.map(data, (item) => {
      return {
        ...item,
        startDate: _.get(item, "subscription.startDate", null)
      };
    });

    if (order === "ASC") {
      return _.sortBy(flattenedData, [(obj) => _.toLower(obj[orderBy])]);
    } else {
      return _.sortBy(flattenedData, [
        (obj) => _.toLower(obj[orderBy])
      ]).reverse();
    }
  };

  useEffect(() => {
    setSwitches(sortData(userSubscriptions, order, orderBy));
  }, [userSubscriptions]);

  const handleRequestSort = (property, order) => {
    setOrderBy(property);
    setOrder(order);
  };

  useEffect(() => {
    switches.length > 0 && setSwitches(sortData(switches, order, orderBy));
  }, [order, orderBy]);

  const breadcrumbs = [
    {
      label: <FormattedMessage id="Tenants" />,
      link: admin_dashboard_route_strings.adminDashboard
    },
    {
      label: tenantDetails.name,
      link: admin_dashboard_route_strings.tenantDetails_dynamic(merchantId)
    },
    {
      label: "Subscriptions"
    }
  ];

  const columns = [
    {
      id: "productName",
      label: "products",
      align: "left",
      sortLabel: true,
      width: 700
    },
    { id: "salesOrder", label: "salesOrder", align: "left", sortLabel: true },
    {
      id: "startDate",
      label: "subscribedSince",
      align: "left",
      sortLabel: true
    },
    { id: "toggle", label: "active", align: "left", sortLabel: false }
  ];

  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    height: "28px",
    borderRadius: "6px"
  };

  const AlertBadge = () => {
    return (
      <Box
        id="alertBadge"
        style={{
          paddingInline: "18px",
          paddingBottom: "18px"
        }}
      >
        <Alert
          type="error"
          onClose={() => {
            setErrorMessage();
          }}
          content={
            <>
              {errorMessage?.response?.data?.message}
            </>
          }
          iconTextMessage={<FormattedMessage id="error" />}
        />
      </Box>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px"
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div
        style={{
          marginBottom: "30px",
          background: "white",
          boxShadow:
            " 2px 1px 2px 0 rgba(0, 0, 0, 0.06), -1px -1px 1px 0 rgba(255, 255, 255, 0.6), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.6), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: "6px"
        }}
      >
        <Box style={{ padding: "16px" }}>
          <SectionHeader
            title={`Subscriptions of Tenant ${tenantDetails.name}`}
            tooltipContent={<FormattedMessage id="subscriptions.tooltip" />}
          />
        </Box>
        <Divider />
        <TableComponent
          alertBadge={errorMessage && <AlertBadge />}
          order={order}
          orderBy={orderBy}
          rows={switches}
          columns={columns}
          RowComponent={SubscriptionsTableRow}
          onRequestSort={handleRequestSort}
        />
      </div>
    </div>
  );
};
export default TenantSubscriptions;
