export const getBaseURL = () => {
  switch (true) {
    case window.location.href.includes("localhost"):
      return "https://dev.eclear-solutions.com";
    case window.location.href.includes("dev-bo"):
      return "https://dev.eclear-solutions.com";
    case window.location.href.includes("acc-bo"):
      return "https://acc.eclear-solutions.com";
    case window.location.href.includes("ref-bo"):
      return "https://ref.eclear-solutions.com";
    default:
      return `https://eclear-solutions.com`;
  }
};
export const EC_AUTH_BE_V2 = () => {
  return window.location.host.includes("localhost")
    ? `https://dev.eclear-solutions.com/ec-authorization-be/v2/`
    : `https://${window.location.host}/ec-authorization-be/v2/`;
};

export const FILE_VAT_BASE_URL = `${getBaseURL()}/ec-vattp-be/rest/v1`;
export const AUTH_BASE_URL = `${getBaseURL()}/ec-authorization-be/v1`;
export const AUTH_V2_BASE_URL = `${getBaseURL()}/ec-authorization-be/v2`;
export const SPOT_BASE_URL = `${getBaseURL()}/ec-spot-be/v1`;
export const URLS = {
  CREATE_USER: AUTH_BASE_URL + "/user-manager/register",
  VERIFY_EMAIL_BY_CODE: AUTH_BASE_URL + "/user-manager/verify",
  SEND_EMAIL_FORGOT_PASSWORD_CODE:
    AUTH_BASE_URL + "/user-manager/request-password-recovery",
  RESET_PASSWORD_WITH_CODE: AUTH_BASE_URL + "/user-manager/recover-password",
  LOGIN: AUTH_BASE_URL + "/user-manager/login",
  EC_ADMIN: SPOT_BASE_URL + "/admin/dashboard/",
  GET_COMPANY_INFO: SPOT_BASE_URL + "/company",
  //FILE_VAT URLS
  FILE_VAT_GET_TAX_COUNTRY: FILE_VAT_BASE_URL + "/tax/country",
  FILE_VAT_GET_TAX_TYPE: FILE_VAT_BASE_URL + "/tax/list",
  FILE_VAT_GET_TAX_VERSION_LIST: FILE_VAT_BASE_URL + "/tax/versions",
  FILE_VAT_GET_TAX_VERSION: FILE_VAT_BASE_URL + "/tax/version",
  FILE_VAT_GET_TAX_ATTRIBUTE_LIST: FILE_VAT_BASE_URL + "/tax/version/content",
  FILE_VAT_TAX_ATTRIBUTE: FILE_VAT_BASE_URL + "/tax/version/attribute",
  FILE_VAT_GET_TAX_CODE: FILE_VAT_BASE_URL + "/tax/code",

  REFRESHTOKEN: "/user-manager/refresh-token",
  ALL_USER_ROLES: "/group/selector",
  LIST_ALL_PRODUCTS: "/product",

  //__________Admin Dashboard URLS__________
  MERCHANT_ACCESS_KEY: (merchantId) => {
    return `${SPOT_BASE_URL}/admin/media/${merchantId}/access/list`;
  },
  CREATE_ACCESS_KEY_ADMIN: (merchantId) => {
    return `${SPOT_BASE_URL}/admin/media/${merchantId}/access/create`;
  },
  ADD_DELETE_USER_ROLE: (user_id) => {
    return `/user/${user_id}/group`;
  },
  GET_MERCHANT_USERS: (merchantId, date) => {
    return `${AUTH_V2_BASE_URL}/merchant/${merchantId}/users?date=${date}`;
  },
  GET_INVITE_REPORT: `${AUTH_BASE_URL}/invite/report`,
  INVITE_USER: `${AUTH_BASE_URL}/invite`,
  EDIT_USER_DATA: (merchantId) => {
    return `${AUTH_V2_BASE_URL}/merchant/${merchantId}/users`;
  },
  DELETE_INVITE: (invite_id) => {
    return `${AUTH_BASE_URL}/invite/${invite_id}`;
  },
  GET_MERCHANT: (merchantId) => {
    return `${AUTH_BASE_URL}/merchant/${merchantId}?with-product-report=true`;
  },
  GET_MERCHANT_SHOPS: (merchantId) => {
    return `${AUTH_BASE_URL}/merchant/${merchantId}/shop`;
  },
  GET_SHOP_DETAILS: (merchantId, shopId) => {
    return `${AUTH_BASE_URL}/merchant/${merchantId}/shop/${shopId}`;
  },
  UPDATE_SHOP_DETAILS: (merchantId, shopId) => {
    return `${AUTH_BASE_URL}/merchant/${merchantId}/shop/${shopId}`;
  },
  CREATE_SHOP: (merchantId) => {
    return `${AUTH_BASE_URL}/merchant/${merchantId}/shop`;
  },
  CREATE_SALES_ORDER: `${AUTH_V2_BASE_URL}/subscription-management/create-sales-order`,

  ACTIVATE_SUBSCRIPTION: `${AUTH_V2_BASE_URL}/subscription-management/create-update-subscription`,
  MERCHANT_SHOP_ACTIVATION_ENABLE: (merchantId, shopId) => {
    return `${AUTH_BASE_URL}/merchant/${merchantId}/shop/${shopId}/enable`;
  },
  MERCHANT_SHOP_ACTIVATION_DISABLE: (merchantId, shopId) => {
    return `${AUTH_BASE_URL}/merchant/${merchantId}/shop/${shopId}/disable`;
  },
  GET_SHOP_LOGO: (shopId, fileId) => {
    return `${AUTH_V2_BASE_URL}/file/shop/${shopId}/logo/${fileId}`;
  }
};
