import "../assets/styles/admin-dashboard.css";
import React, { useState } from "react";
import { Grid, Paper } from "@mui/material";
import InputField from "./InputField";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import { FormattedMessage } from "react-intl";
import {
  setDataFilters,
  setIsFilterApplied,
} from "../../../redux/actions/adminDashboard";

import * as _ from "lodash";

const DataFilters = ({ getAdminData }) => {
  const dispatch = useDispatch();

  const dataFilters = useSelector((state) => state.adminDashboard.dataFilters);
  const pagination = useSelector((state) => state.adminDashboard.pagination);
  const order = useSelector((state) => state.adminDashboard.order);
  const isFilterApplied = useSelector(
    (state) => state.adminDashboard.isFilterApplied
  );

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        borderRadius: "10px",
        padding: "0px 24px",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const pag = {
            page: 0,
            rowsPerPage: pagination.rowsPerPage,
            totalRecords: pagination.totalRecords,
          };
          getAdminData(dataFilters, pag, order);
          dispatch(setIsFilterApplied(true));
        }}
      >
        <Grid container spacing={3} sx={{ marginTop: "0px" }}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <InputField
                clearButton
                label={"Email"}
                placeholder={null}
                value={dataFilters.userMailAddress}
                name={"userMailAddress"}
                onChange={(e) => {
                  dispatch(
                    setDataFilters({
                      ...dataFilters,
                      userMailAddress: e.target.value,
                    })
                  );
                }}
                handleClear={(e) => {
                  dispatch(
                    setDataFilters({
                      ...dataFilters,
                      userMailAddress: "",
                    })
                  );
                }}
              />
            </label>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <label
              style={{
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <InputField
                clearButton
                label={"name"}
                placeholder=""
                value={dataFilters.name}
                name={"name"}
                onChange={(e) => {
                  dispatch(
                    setDataFilters({
                      ...dataFilters,
                      name: e.target.value,
                    })
                  );
                }}
                handleClear={(e) => {
                  dispatch(
                    setDataFilters({
                      ...dataFilters,
                      name: "",
                    })
                  );
                }}
              />
            </label>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: "24px", display: "flex", gap: 2 }}>
          <Button
            disableElevation
            // className="btn-ad"
            disabled={
              dataFilters && !_?.some(dataFilters, (value) => !_.isEmpty(value))
            }
            variant="contained"
            startIcon={<SearchRoundedIcon />}
            type="submit"
          >
            <FormattedMessage id="Search" />
          </Button>
          <Button
            disableElevation
            // className="btn-ad"
            disabled={
              // dataFilters.userMailAddress === "" && dataFilters.name === ""
              !isFilterApplied
            }
            variant="contained"
            startIcon={<RestartAltRoundedIcon />}
            onClick={() => {
              dispatch(
                setDataFilters({
                  ...dataFilters,
                  name: "",
                  userMailAddress: "",
                })
              );
              getAdminData({}, pagination, order);
              dispatch(setIsFilterApplied(false));
            }}
          >
            <FormattedMessage id="Reset Filters" />
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default DataFilters;
