import React from "react";

const CheckIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.112 4.601a2.674 2.674 0 1 1 4.818 2.322l-6.339 13.1a2 2 0 0 1-2.694.918l-5.594-2.797a2.677 2.677 0 1 1 2.394-4.788l2.591 1.295 4.824-10.05zm2.713.554a.674.674 0 0 0-.91.312L11.432 16.89a.5.5 0 0 1-.675.23l-3.954-1.977a.677.677 0 0 0-.606 1.212l4.688 2.343a1 1 0 0 0 1.347-.458L18.13 6.052a.674.674 0 0 0-.305-.897z"
        fill="#000E25"
      />
    </svg>
  );
};

export default CheckIcon;
