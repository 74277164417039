import "./styles.css";
import React from "react";

const Spinner = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ec-spinner"
    >
      <mask
        id="mask0_564_98"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="-1"
        width="60"
        height="61"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30 52.4998C25.5499 52.4998 21.1998 51.1802 17.4997 48.7079C13.7996 46.2356 10.9157 42.7216 9.21271 38.6102C7.50974 34.4989 7.06416 29.9749 7.93233 25.6103C8.8005 21.2458 10.9434 17.2367 14.0901 14.09C17.2368 10.9433 21.2459 8.80039 25.6105 7.93222C29.975 7.06405 34.499 7.50963 38.6104 9.21259C42.7217 10.9156 46.2357 13.7994 48.7081 17.4995C50.4824 20.155 51.663 23.1454 52.1882 26.2671C52.5318 28.3095 54.1789 29.9999 56.25 29.9999V29.9999C58.321 29.9999 60.0241 28.3144 59.7659 26.2595C59.1869 21.6515 57.5432 17.2227 54.9441 13.3328C51.6476 8.39931 46.9623 4.55414 41.4805 2.28351C35.9987 0.0128851 29.9667 -0.581216 24.1473 0.576342C18.3279 1.7339 12.9824 4.59112 8.7868 8.78668C4.59123 12.9823 1.73401 18.3277 0.576455 24.1472C-0.581101 29.9666 0.0129986 35.9986 2.28363 41.4803C4.55425 46.9621 8.39943 51.6475 13.3329 54.9439C18.2664 58.2404 24.0666 59.9998 30 59.9998L30 52.4998Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_564_98)">
        <g filter="url(#filter0_f_564_98)">
          <circle cx="56" cy="30" r="30" fill="#000E25" />
          <g opacity="0.7">
            <circle cx="30" r="25" fill="#000E25" />
          </g>
          <g opacity="0.5">
            <circle cy="30" r="20" fill="#000E25" />
          </g>
          <g opacity="0.2">
            <circle cx="15" cy="55" r="15" fill="#000E25" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_564_98"
          x="-56"
          y="-61"
          width="178"
          height="167"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="18"
            result="effect1_foregroundBlur_564_98"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Spinner;
