import React from "react";

const UploadIcon = ({ width = "16", height = "16", color = "#fff" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#3d2o8k6rca)">
        <circle cx="8" cy="8" r="7" stroke={color} stroke-width="2" />
        <path d="M7 7a1 1 0 0 1 2 0v4a1 1 0 1 1-2 0V7z" fill={color} />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.707 8.707a1 1 0 0 1-1.414 0L8 6.414 5.707 8.707a1 1 0 0 1-1.414-1.414l3-3a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="3d2o8k6rca">
          <path fill={color} d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
