import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackIos,
  ArrowForward,
  BoltOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as Duplicate } from "../../assets/icons/duplicate.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { ReactComponent as AttentionIcon } from "../../assets/icons/attention.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TaxAttributeTable from "./components/TaxAttributeTable";
import VersionFormField from "./components/VersionFormField";
import { useDispatch, useSelector } from "react-redux";
import { fileVatActions } from "../../redux/actions/filevat";
import ConfirmDialog from "./components/ConfirmDialog";
import { isEmpty } from "lodash";
import moment from "moment";
import { checkIfImageExists, getCountryName } from "./common/helper";

const initialValues = {
  name: "",
  validFrom: "",
  validTo: "",
  status: "DRAFT",
  description: "",
  defaultTax: "NO",
};
export default function VersionDetail() {
  const {
    taxVersionCreated,
    loading,
    taxAttributeList,
    taxBoxUpdatedList,
    versionDeletedSuccess,
    attributeDeletedSuccess,
    taxVersionCopied,
    validationSuccess,
    taxBoxFormulaError,
  } = useSelector((state) => state.fileVat);
  const { state } = useLocation();
  const { country } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [isBoxEditableActive, setIsBoxEditableActive] = useState(false);
  const [errors, setErrors] = useState({});
  const [boxErrors, setBoxErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [dialogData, setDialogData] = useState({
    title: "",
    type: "",
    content: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [versionInput, setVersionInput] = useState(
    state.viewType !== "create" && state.selectedVersionItem
      ? state.selectedVersionItem
      : initialValues
  );
  const [boxInput, setBoxInput] = useState({});
  const [attributeTaxList, setAttributeTaxList] = useState([]);

  function getSortedAttribute(value) {
    return [...value].sort((a, b) => (a.order > b.order ? 1 : -1));
  }
  function handleInputChange(e) {
    if (e.name === "validFromTo") {
      setVersionInput((versionInput) => ({
        ...versionInput,
        validFrom: e.validFrom,
        validTo: e.validTo,
      }));
      if (versionInput.status === "ACTIVE") {
        dispatch(
          fileVatActions.getValidateTaxVersion({
            taxId: state.id,
            id: versionInput.id,
            countryCode: country.toUpperCase(),
            validFrom: e.validFrom,
            validTo: e.validTo,
          })
        );
      }
    } else {
      const { name, value } = e.target;
      if (name === "status" && value === "ACTIVE") {
        dispatch(
          fileVatActions.getValidateTaxVersion({
            taxId: state.id,
            id: versionInput.id,
            countryCode: country.toUpperCase(),
            validFrom: versionInput.validFrom,
            validTo: versionInput.validTo,
          })
        );
      } else if (name === "status" && value === "DRAFT") {
        dispatch(fileVatActions.setValidateTaxVersion());
      }
      setVersionInput((versionInput) => ({ ...versionInput, [name]: value }));
      validateVersionFields({ [name]: value });
    }
  }
  function handleCancel() {
    if (
      state.viewType === "create" &&
      Object.keys(taxVersionCreated).length === 0
    ) {
      dispatch(fileVatActions.setValidateTaxVersion());
      navigate(`/filevat-version-list/${country.toLowerCase()}`, {
        state: state,
      });
    } else {
      setDialogOpen(true);
      setDialogData({
        type: "editCancel",
        title: "Are you sure to cancel your changes?",
        content: "All changes will be lost!",
      });
    }
  }
  function handleBackNavigation() {
    // this will also work on create type
    if (!isEditable) {
      // navigate(-1);
      navigate(`/filevat-version-list/${country.toLowerCase()}`, {
        state: state,
      });
    } else {
      setDialogOpen(true);
      setDialogData({
        type: "cancelEditBack",
        title:
          "There are unsaved changes! Please save or cancel the changes prior to navigate back",
        content: "All changes will be lost!",
      });
    }
  }
  useEffect(() => {
    if (state.viewType !== "view") {
      setIsEditable(true);
    }
  }, []);
  useEffect(() => {
    setAttributeTaxList(getSortedAttribute(taxAttributeList));
  }, [taxAttributeList]);

  useEffect(() => {
    if (versionDeletedSuccess === true) {
      navigate(`/filevat-version-list/${country.toLowerCase()}`, {
        state: state,
      });
    }
  }, [versionDeletedSuccess]);

  useEffect(() => {
    if (
      state.viewType !== "create" ||
      Object.keys(taxVersionCreated).length > 0 ||
      attributeDeletedSuccess === true
    ) {
      dispatch(
        fileVatActions.getTaxAttributeList({
          versionId: state.selectedVersionItem.id,
        })
      );
    }
  }, [dispatch, attributeDeletedSuccess]);
  useEffect(() => {
    if (
      // state.viewType === "create" &&
      Object.keys(taxVersionCreated).length > 0
    ) {
      setIsEditable(false);
      setVersionInput(taxVersionCreated);
      navigate(
        ".",

        {
          state: {
            ...state,
            selectedVersionItem: taxVersionCreated,
          },
        }
      );
    }
  }, [taxVersionCreated]);
  useEffect(() => {
    if (
      // state.viewType === "create" &&
      Object.keys(taxBoxUpdatedList).length > 0
    ) {
      setIsBoxEditableActive(false);
      setAttributeTaxList(getSortedAttribute(taxBoxUpdatedList));
      // no need for this, list will be used instead
      // setBoxInput(taxBoxCreated);
    }
  }, [taxBoxUpdatedList]);
  const validateVersionFields = (value) => {
    const message = {};
    if (value.name !== undefined && !value.name.trim()) {
      message.name = "The name must not be empty";
    }
    setErrors(message);
    return message;
  };
  const validateAttributeFields = () => {
    const message = {};

    if (!boxInput.boxNumber.trim()) {
      message.boxNumber = "The number must not be empty";
    }
    if (boxInput.boxNumber.trim() && attributeTaxList.length > 1) {
      let result = attributeTaxList.find(
        (e) => e.boxNumber === boxInput.boxNumber && e.order !== boxInput.order
      );
      if (!isEmpty(result)) {
        message.boxNumber =
          "The Box Number must be unique within the current version";
      }
    }
    if (!boxInput.boxName.trim()) {
      message.boxName = "The name must not be empty";
    }
    if (!boxInput.type.trim()) {
      message.type = "The Type must not be empty";
    }

    setBoxErrors(message);
    return message;
  };
  const handleSubmitForm = () => {
    const validationRes = validateVersionFields(versionInput);
    if (Object.keys(validationRes).length === 0) {
      if (
        state.viewType === "create" &&
        Object.keys(taxVersionCreated).length === 0
      ) {
        dispatch(
          fileVatActions.createTaxVersion({
            ...versionInput,
            countryCode: country.toUpperCase(),
            taxId: state.id,
          })
        );
      } else {
        setDialogOpen(true);
        setDialogData({
          type: "update",
          title: "Are you sure that you want to save the changes?",
          content: "Version will be updated",
        });
      }
    }
  };
  const handleSubmitBox = () => {
    const validationRes = validateAttributeFields();
    if (Object.keys(validationRes).length === 0) {
      //need to investigate
      if (Object.keys(boxInput).length !== 0) {
        if (!boxInput.id) {
          dispatch(
            fileVatActions.createTaxAttribute({
              ...boxInput,
              //if id come from prev screen or user created new version
              versionId:
                state.selectedVersionItem && state.selectedVersionItem.id
                  ? state.selectedVersionItem.id
                  : taxVersionCreated.id,
            })
          );
        } else {
          setDialogOpen(true);
          setDialogData({
            type: "updateBox",
            title: "Are you sure that you want to save the changes?",
            content: "Content will be updated",
          });
        }
      }
    }
  };
  const deleteVersion = () => {
    setDialogOpen(true);
    setDialogData({
      type: "deleteVersion",
      title: "Are you sure that you want to delete the version?”",
      content: "This action cannot be undone!",
    });
  };
  const handleDeleteAttribute = (id) => {
    dispatch(
      fileVatActions.deleteTaxAttribute({
        id,
      })
    );
  };
  const duplicateVersion = (row) => {
    setIsDuplicated(true);
    dispatch(
      fileVatActions.copyTaxVersion({
        versionId: row.id,
      })
    );
  };
  useEffect(() => {
    if (Object.keys(taxVersionCopied).length > 0 && isDuplicated) {
      setIsDuplicated(false);
      navigate(`/filevat-version-detail/${country.toLowerCase()}`, {
        state: {
          ...state,
          selectedVersionItem: taxVersionCopied,
          viewType: "edit",
        },
      });
      window.location.reload();
    }
  }, [taxVersionCopied]);
  const handleDialogResponse = () => {
    setDialogOpen(false);
    setBoxErrors({});
    switch (dialogData.type) {
      case "update":
        dispatch(
          fileVatActions.updateTaxVersion({
            ...versionInput,
          })
        );
        break;
      case "updateBox":
        dispatch(
          fileVatActions.updateTaxAttribute({
            ...boxInput,
          })
        );
        break;
      case "deleteVersion":
        dispatch(
          fileVatActions.deleteTaxVersion({
            id: versionInput.id,
          })
        );
        break;
      case "editCancel":
        setIsEditable(false);
        setIsBoxEditableActive(false);
        dispatch(fileVatActions.setValidateTaxVersion());
        setVersionInput(
          Object.keys(taxVersionCreated).length > 0
            ? taxVersionCreated
            : state.selectedVersionItem
            ? state.selectedVersionItem
            : initialValues
        );
        setAttributeTaxList(
          getSortedAttribute(
            Object.keys(taxBoxUpdatedList).length > 0
              ? taxBoxUpdatedList
              : taxAttributeList
          )
        );
        dispatch(fileVatActions.setFormulaErrorState());

        break;
      case "cancelEditBack":
        navigate(`/filevat-version-list/${country.toLowerCase()}`, {
          state: state,
        });
        break;
      default:
        break;
    }
  };
  return (
    <div
      className="version-detail"
      style={{
        ...((isEditable || isBoxEditableActive) && { marginBottom: "150px" }),
      }}
    >
      <div
        className={`breadcrumb ${
          (isEditable || isBoxEditableActive) && "disable-op"
        }`}
      >
        <Link to={"/filevat-tax-forms"} className="link">
          <ArrowBackIos />
          <span className="text"> Tax Forms Overview</span>
        </Link>
        <span onClick={() => handleBackNavigation()} className="link">
          <ArrowForward />
          <span className="text"> {state.taxName}</span>
        </span>
        <span className="link current">
          <IconButton>
            <ArrowForward />
          </IconButton>
          <span className="text">
            {" "}
            {state.viewType === "create" &&
            Object.keys(taxVersionCreated).length === 0
              ? "Initial Version"
              : versionInput.name}
          </span>
        </span>
      </div>

      <div className="version-detail-head">
        <div className="country-flex">
          <span className="flag">
            <img alt="" src={checkIfImageExists(country)} width={30} />
          </span>
          <span className="title">
            {state.viewType === "create" &&
            Object.keys(taxVersionCreated).length === 0
              ? "Initial Version"
              : versionInput.name}
          </span>

          <Tooltip title={"information"} arrow>
            <InfoOutlined className="info-icon" />
          </Tooltip>
        </div>
        <div className="actions-btn">
          <IconButton
            disabled={isEditable || isBoxEditableActive}
            className={(isEditable || isBoxEditableActive) && "disable-op"}
            onClick={() => deleteVersion()}
          >
            <DeleteIcon />
          </IconButton>

          <IconButton
            onClick={() => duplicateVersion(versionInput)}
            disabled={isEditable || isBoxEditableActive}
            className={(isEditable || isBoxEditableActive) && "disable-op"}
          >
            <Duplicate />
          </IconButton>
          <IconButton
            disabled={isEditable || isBoxEditableActive}
            className={(isEditable || isBoxEditableActive) && "disable-op"}
            onClick={() => setIsEditable(true)}
          >
            <EditIcon />
          </IconButton>
        </div>
      </div>
      <VersionFormField
        state={state}
        isEditable={isEditable}
        handleInputChange={handleInputChange}
        versionInput={versionInput}
        errors={errors}
        isBoxEditableActive={isBoxEditableActive}
      />
      <div className="title-box-table"> Fields</div>
      <Card
        sx={{ minWidth: 275, marginBottom: "60px" }}
        className="card version-list-card"
      >
        <TaxAttributeTable
          state={state}
          country={country}
          setIsBoxEditableActive={setIsBoxEditableActive}
          isBoxEditableActive={isBoxEditableActive}
          isEditable={isEditable}
          setAttributeTaxList={setAttributeTaxList}
          attributeTaxList={attributeTaxList}
          setBoxInput={setBoxInput}
          handleDeleteAttribute={handleDeleteAttribute}
          boxErrors={boxErrors}
        ></TaxAttributeTable>
      </Card>
      {(isEditable || isBoxEditableActive) && (
        <div className="edit-footer">
          {((validationSuccess && validationSuccess.length > 0) ||
            taxBoxFormulaError.code === "FORMULA_EXCEPTION") && (
            <>
              <Alert
                className="overlap-error"
                icon={<AttentionIcon />}
                severity="error"
              >
                <AlertTitle className="alert-title">Attention</AlertTitle>
              </Alert>

              <ul>
                {taxBoxFormulaError.code === "FORMULA_EXCEPTION" && (
                  <li>{taxBoxFormulaError.message}</li>
                )}

                {validationSuccess.map((item) => (
                  <li>
                    An active version <b>{item.name}</b> already exists for the
                    selected time period (
                    <b>
                      {moment(versionInput.validFrom).format("YYYY/MM")} -{" "}
                      {moment(versionInput.validTo).format("YYYY/MM")}
                    </b>
                    )
                  </li>
                ))}
              </ul>
            </>
          )}
          <div className="edit-footer-btn">
            <Button
              variant="text"
              disabled={loading}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={
                loading ||
                Object.keys(errors).length !== 0 ||
                (versionInput.status === "ACTIVE" &&
                  validationSuccess.length !== 0)
              }
              onClick={() =>
                isBoxEditableActive ? handleSubmitBox() : handleSubmitForm()
              }
            >
              Save changes
            </Button>
          </div>
        </div>
      )}
      <ConfirmDialog
        open={dialogOpen}
        data={dialogData}
        handleClose={() => setDialogOpen(false)}
        handleOk={() => handleDialogResponse()}
      />
    </div>
  );
}
