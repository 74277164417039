import { FormattedMessage } from "react-intl";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import "./styles.css";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  TableRow,
  Tooltip
} from "@mui/material";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import UserIcon from "../../../../assets/icons/UserIcon";
import DisableIcon from "../../../../assets/icons/DisableIcon";
import { useSelector } from "react-redux";
import CheckBoxIcon from "../../../../assets/icons/CheckBoxIcon";
import React, { useEffect, useState } from "react";
import TableCell from "../../../../components/TabeleCell";
import AppsIcon from "../../../../assets/icons/AppsIcon";
import ShoppingBagIcon from "../../../../assets/icons/ShoppingBagIcon";
import RightArrowIcon from "../../../../assets/icons/RightArrowIcon";
import { useNavigate, useParams } from "react-router-dom";
import { sortData } from "../../../../configuration/helpers";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import {
  getAllData,
  getUserManagementData
} from "../../../../services/adminDashboard";
import * as _ from "lodash";
import RolesModal from "../../components/RolesModal";
import TableComponent from "../../components/Table/Table";
import { setUserManagement } from "../../../../redux/actions/adminDashboard";
import { useDispatch } from "react-redux";
import UsersModal from "../../components/UsersModal/UsersModal";

const UserManagement = () => {
  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );
  const inviteData = useSelector((state) => state.adminDashboard.inviteData);
  const userManagement = useSelector(
    (state) => state.adminDashboard.userManagement
  );
  const isFetchingUserMgmtData = useSelector(
    (state) => state.adminDashboard.isFetchingUserMgmtData
  );
  const dispatch = useDispatch();
  const { merchantId } = useParams();

  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("email");
  const [isOpenInviteUserModal, setIsOpenInviteUserModal] = useState(false);

  function handleGetAllData() {
    getAllData({ merchantId });
  }

  useEffect(() => {
    _.isEmpty(userManagement) && handleGetAllData();
  }, []);

  const breadcrumbs = [
    {
      label: <FormattedMessage id="Tenants" />,
      link: admin_dashboard_route_strings.adminDashboard
    },
    {
      label: tenantDetails.name,
      link: admin_dashboard_route_strings.tenantDetails_dynamic(merchantId)
    },
    {
      label: <FormattedMessage id="userManagement" />
    }
  ];

  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    height: "28px",
    borderRadius: "6px",
    background: " var(--future-tax-4)"
  };

  const columns = [
    {
      id: "status",
      label: "statusAndActivity",
      align: "left",
      sortLabel: true
    },
    {
      id: "firstName",
      label: "firstName",
      align: "left",
      sortLabel: true
    },
    {
      id: "lastName",
      label: "lastName",
      align: "left",
      sortLabel: true
    },
    {
      id: "email",
      label: "Email Address",
      align: "left",
      sortLabel: true
    },
    {
      id: "actions",
      label: "actions",
      align: "center",
      sortLabel: false
    }
  ];

  const navigate = useNavigate();

  const handleRequestSort = (property, order) => {
    setOrderBy(property);
    setOrder(order);
  };

  useEffect(() => {
    dispatch(setUserManagement(sortData(userManagement, order, orderBy)));
  }, [order, orderBy]);

  const Row = ({ row }) => {
    const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);
    const dispatch = useDispatch();
    return (
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "var(--future-tax-8)"
          }
        }}
        key={row.id}
      >
        <TableCell
          align="center"
          style={{
            padding: "3px 6px"
          }}
          width={100}
        >
          <div
            className={row.status || "undefined"}
            style={{
              borderRadius: "6px",
              fontWeight: 500,
              padding: "3px 0px"
            }}
          >
            <FormattedMessage id={row.status || "undefined"} />
          </div>
        </TableCell>
        <TableCell>{row.merchant?.name || row.firstName}</TableCell>
        <TableCell>{row.lastName || "-"}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell align="center" width={120}>
          <ButtonGroup
            size="large"
            aria-label="small button group"
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "6px"
            }}
          >
            <Tooltip title={<FormattedMessage id="manageRoles" />}>
              <Button
                disabled={(row?.status ?? "") !== "ENABLED"}
                color="primary"
                disableElevation
                onClick={() => {
                  setIsRolesModalOpen(true);
                }}
                variant="contained"
                style={buttonStyles}
              >
                {(row?.status ?? "") === "ENABLED" && (
                  <div style={{ marginRight: "7px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "var(--future-tax-80)",
                        fontWeight: 500
                      }}
                    >
                      {row?.userGroups.length}
                    </span>
                  </div>
                )}
                <AppsIcon
                  color={
                    (row?.status ?? "") !== "ENABLED"
                      ? "var(--future-tax-20)"
                      : "var(--future-tax-80)"
                  }
                />
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="editUser" />}>
              <Button
                disabled
                variant="text"
                color="primary"
                disableElevation
                style={buttonStyles}
                onClick={() => {
                }}
              >
                <DisableIcon color={"var(--future-tax-20)"} />
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="editUser" />}>
              <Button
                disabled
                variant="text"
                color="primary"
                disableElevation
                style={buttonStyles}
                onClick={() => {
                  navigate(
                    `/dashboard/tenant-details/user-management/${inviteData[0].userId}`
                  );
                }}
              >
                <PencilIcon color={"var(--future-tax-20)"} />
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="userDetails" />}>
              <Button
                disabled={(row?.status ?? "") !== "ENABLED"}
                variant="text"
                color="primary"
                disableElevation
                style={buttonStyles}
                onClick={() => {
                  navigate(
                    admin_dashboard_route_strings.userDetails_dynamic(
                      merchantId,
                      row.id
                    )
                  );
                }}
              >
                <RightArrowIcon
                  color={
                    (row?.status ?? "") !== "ENABLED"
                      ? "var(--future-tax-20)"
                      : "var(--future-tax-80)"
                  }
                />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </TableCell>
        <RolesModal
          modalOpen={isRolesModalOpen}
          setModalOpen={setIsRolesModalOpen}
          userGroups={row.userGroups}
          row={row}
          onClose={() => {
            const sort = {
              order,
              orderBy
            };
            getUserManagementData({ merchantId, sort });
          }}
        />
      </TableRow>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px"
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div
        style={{
          marginBottom: "30px",
          background: "white",
          boxShadow:
            " 2px 1px 2px 0 rgba(0, 0, 0, 0.06), -1px -1px 1px 0 rgba(255, 255, 255, 0.6), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.6), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: "6px"
        }}
      >
        <Box style={{ padding: "16px" }}>
          <SectionHeader
            title={
              <span>
                <FormattedMessage id="userManagementOfTenant" />{" "}
                {tenantDetails.name}
              </span>
            }
            tooltipContent={<FormattedMessage id="users.tooltip" />}
            actionButtons={
              <div>
                <IconButton
                  onClick={() => {
                    setIsOpenInviteUserModal(true);
                  }}
                >
                  <UserIcon color="var(--future-tax-20)" />
                </IconButton>
                <IconButton disabled>
                  <CheckBoxIcon color="var(--future-tax-20)" />
                </IconButton>
              </div>
            }
          />
        </Box>
        <Divider />
        <TableComponent
          columns={columns}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          withSearch
          rows={userManagement}
          RowComponent={Row}
          tableContainerStyle={{
            backgroundColor: "var(--future-tax-0)",
            maxHeight: "calc(100vh - 22rem)"
          }}
          onRequestSort={handleRequestSort}
          loading={isFetchingUserMgmtData}
        />
        {isOpenInviteUserModal &&
          <UsersModal
            modalOpen={isOpenInviteUserModal}
            setModalOpen={setIsOpenInviteUserModal}
            row={tenantDetails}
            getAdminData={handleGetAllData}
            onSuccess={handleGetAllData}
          />}
      </div>
    </div>
  );
};

export default UserManagement;
