import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  TextField,
  InputAdornment,
  TableSortLabel,
  TablePagination,
} from "@mui/material";

import { Search } from "@mui/icons-material";

import { checkIfImageExists, getStatusColor } from "../common/helper";
import { fileVatActions } from "../../../redux/actions/filevat";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "./ConfirmDialog";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function CountryTable() {
  const { taxCodeList, totalNumberOfElements } = useSelector(
    (state) => state.fileVat
  );
  const [ascending, setAscending] = useState(true);
  const [orderBy, setOrderBy] = useState("countryCode");
  const [pagination, setPagination] = useState({
    page: 0,
    size: 25,
    // totalRecords: 25,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    dispatch(
      fileVatActions.getTaxCodeList({
        ...pagination,
        ascending,
        orderBy,
        searchedText,
      })
    );
  }, [ascending, orderBy, pagination.page, pagination.size, searchedText]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property ? !ascending : false;
    setAscending(isAsc);
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangePageSize = (event) => {
    setPagination({
      ...pagination,
      size: +event.target.value,
      page: 0,
    });
  };

  const handleSearch = (e) => {
    if (e.trim().length > 0) {
      setSearchedText(e);
    } else {
      setSearchedText("");
    }
  };

  const headCells = [
    {
      id: "countryCode",
      numeric: false,
      disablePadding: true,
      label: "Code",
      sort: true,
    },
    {
      id: "countryName",
      numeric: true,
      disablePadding: false,
      label: "Country",
      sort: true,
    },
    {
      id: "lastUpdatedAt",
      numeric: true,
      disablePadding: false,
      label: "Last updated",
      sort: true,
    },
    {
      id: "lastUpdatedBy",
      numeric: false,
      disablePadding: false,
      label: "Last Updated by",
      sort: true,
    },
  ];
  const SortIndicator = ({ direction, active, colId, orderBy }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2px",
        marginLeft: "10px",
        height: "100%",
      }}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginBottom: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "ASC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        transform="scale(-1,-1)"
        style={{ marginTop: "1px" }}
      >
        <path
          d="M1.13886 4.08623L3.8519 1.07187L6.56494 4.08624"
          stroke={
            direction === "DESC" && colId === orderBy ? "#00183D" : "#CBCFD7"
          }
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
  return (
    <div className="version-table">
      {/* <Link to="/">
        <h2 className="back-btn">
          <ArrowBackIos />
          Back
        </h2>{" "}
      </Link> */}

      <div className="search-header">
        <TextField
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search className="search-icon" />
              </InputAdornment>
            ),
          }}
          placeholder="Filter by Text and/or Keywords"
        />
      </div>
      {/* <TableContainer component={Paper} sx={{ maxHeight: 440 }}> */}
      <Table
        stickyHeader
        sx={{ minWidth: 650 }}
        size="small"
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} align={"left"}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  disabled={!headCell.sort}
                  hideSortIcon={!headCell.sort}
                  direction={ascending ? "asc" : "desc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={() => (
                    <SortIndicator
                      direction={ascending ? "ASC" : "DESC"}
                      active={orderBy === headCell.id}
                      colId={headCell.id}
                      orderBy={orderBy}
                    />
                  )}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {taxCodeList && taxCodeList.length > 0 ? (
            taxCodeList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() =>
                  navigate(
                    `/filevat-tax-code-detail/${row.countryCode.toLowerCase()}`
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <TableCell align="left" scope="row">
                  <div className="country-flex">
                    <span className="flag">
                      <img
                        alt=""
                        src={checkIfImageExists(row.countryCode)}
                        width={27}
                      />
                    </span>
                    <span className="title">{row.countryCode}</span>
                  </div>
                </TableCell>
                <TableCell align="left"> {row.countryName}</TableCell>
                <TableCell align="left">{row.lastUpdatedAt}</TableCell>
                <TableCell align="left">{row.lastUpdatedBy}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <div className="no-records">
                  {searchedText.length > 0
                    ? "No countries found matching your search string"
                    : "No VAT Tax Codes have been added yet"}
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* </TableContainer> */}

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={totalNumberOfElements}
        rowsPerPage={pagination.size}
        page={pagination.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePageSize}
      />
    </div>
  );
}

export default CountryTable;
