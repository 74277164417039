import { ECAxiosAPI } from "../configuration/interceptor";
import { URLS } from "../configuration/urls";


export function handleActiveShop({ shopId, merchantId, onSuccess, onFail }) {
  ECAxiosAPI.put(URLS.MERCHANT_SHOP_ACTIVATION_ENABLE(merchantId, shopId), {})
  .then(onSuccess)
  .catch(onFail);
}

export function handleDeactiveShop({ shopId, merchantId, onSuccess, onFail }) {
  ECAxiosAPI.put(URLS.MERCHANT_SHOP_ACTIVATION_DISABLE(merchantId, shopId), {})
  .then(onSuccess)
  .catch(onFail);
}
