import "./styles.css";
import React, { useEffect } from "react";
import { Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SuccessIcon from "../../assets/icons/SuccessIcon";
import FailureIcon from "../../assets/icons/FailureIcon";

const ModalSnackbar = ({
  visible,
  onClose,
  type,
  message = "title",
  title = "message",
  messageTimeout = 5000,
}) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onClose();
    }, messageTimeout);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [visible]);

  return (
    <Collapse in={visible}>
      <div className={`modal-message ${type}`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {type === "success" ? <SuccessIcon /> : <FailureIcon />}{" "}
          <span style={{ marginLeft: "8px", fontWeight: "500" }}>{title}</span>
        </div>
        <div>
          <span className="message">{message}</span>
        </div>
        <div className="btn-snackbar-close">
          <IconButton onClick={onClose}>
            <CloseIcon
              sx={{
                color:
                  type === "success"
                    ? "var(--success-100)"
                    : "var(--error-100)",
                fontSize: "16px",
              }}
            />
          </IconButton>
        </div>
      </div>
    </Collapse>
  );
};

export default ModalSnackbar;
