import { AddCircleOutline, ArrowForward } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SelectModal from "./SelectModal";
import { checkIfImageExists, getCountryName } from "../common/helper";
import styled from "@emotion/styled";

export function Cards({ country, taxTypeList, addSelectedOption }) {
  // const [taxTypeList, setTaxTypeList] = useState(selectedTaxTypes);
  const [selectModalState, setSelectModalState] = useState({
    isSelectModalVisible: false,
    type: "taxType",
  });
  const navigate = useNavigate();
  const setSelectModalValue = (type, isSelectModalVisible) => {
    setSelectModalState({
      type,
      isSelectModalVisible,
    });
  };
  // const addSelectedOption = (selectedOption) => {
  //   const newTaxType = [...taxTypeList, selectedOption];
  //   setTaxTypeList(newTaxType);
  // };
  const handleTaxTypeModal = () => {
    setSelectModalValue("taxType", true);
  };

  return (
    <>
      <Card sx={{ minWidth: 300 }} className="card country-card">
        <CardHeader
          className="header"
          action={
            <IconButton onClick={() => handleTaxTypeModal()}>
              <AddCircleOutline className="icon" />
            </IconButton>
          }
          title={
            <div className="country-flex">
              <span className="flag">
                <img alt="" src={checkIfImageExists(country)} width={30} />
              </span>
              <span className="title">
                {country}-{getCountryName(country)}
              </span>
            </div>
          }
        />
        <CardContent>
          <Box
            sx={{
              typography: "div",
              "& > :not(style) + :not(style)": {
                mt: 2,
              },
            }}
          >
            {taxTypeList.map((value) => {
              return (
                <Paper
                  onClick={() =>
                    navigate(`/filevat-version-list/${country.toLowerCase()}`, {
                      state: value,
                    })
                  }
                  className="tax-types"
                  key={value}
                  elevation={0}
                >
                  <span>{value.taxName}</span>
                  <ArrowForward />
                </Paper>
              );
            })}
          </Box>
        </CardContent>
      </Card>
      {selectModalState.isSelectModalVisible && (
        <SelectModal
          isSelectModalVisible={selectModalState.isSelectModalVisible}
          setSelectModalValue={setSelectModalValue}
          addSelectedOption={addSelectedOption}
          title="Tax type"
          selectModalType={selectModalState.type}
          selectedTaxItem={taxTypeList}
          placeholder={" Search the tax type"}
          
        />
      )}
    </>
  );
}
