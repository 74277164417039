import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from "@mui/material";
import Breadcrumbs from "../../../../components/Breadcrumbs/Breadcrumbs";
import UserIcon from "../../../../assets/icons/UserIcon";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import { FormattedMessage } from "react-intl";
import { SectionHeader } from "../../../../components/SectionHeader/SectionHeaders";
import RolesSwitch from "../../components/RolesSwitch";
import * as _ from "lodash";
import { setUserRolesList } from "../../../../redux/actions/adminDashboard";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { AUTH_BASE_URL, URLS } from "../../../../configuration/urls";
import { useDispatch } from "react-redux";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import { useParams } from "react-router-dom";
import { getAllData } from "../../../../services/adminDashboard";
import TableComponent from "../../components/Table/Table";

const TenantRoles = () => {
  const [switches, setSwitches] = useState([]);
  const [hasMadeApiCall, setHasMadeApiCall] = useState(false);
  const [order, setOrder] = useState("ASC");
  const [orderBy, setOrderBy] = useState("service");
  const [visibleSwitches, setVisibleSwitches] = useState([]);

  const dispatch = useDispatch();

  const { merchantId } = useParams();

  const tenantDetails = useSelector(
    (state) => state.adminDashboard.tenantDetails
  );

  const userRolesList = useSelector(
    (state) => state.adminDashboard.userRolesList
  );

  const getAllUserRoles = async () => {
    await axios
    .get(AUTH_BASE_URL + URLS.ALL_USER_ROLES)
    .then(function(res) {
      dispatch(setUserRolesList(res.data));
    })
    .catch(function(err) {
      // enqueueSnackbar(err, {
      //   variant: "error",
      // });
      console.log(err);
    });
  };

  useEffect(() => {
    _.isEmpty(tenantDetails) && getAllData({ merchantId });
  }, []);

  const sortData = (data, order, orderBy) => {
    const orderByProperty = orderBy === "service" ? "name" : orderBy;
    if (order === "ASC") {
      return _.sortBy(data, [(obj) => _.toLower(obj[orderByProperty])]);
    } else {
      return _.sortBy(data, [
        (obj) => _.toLower(obj[orderByProperty])
      ]).reverse();
    }
  };

  const handleRequestSort = (property, order) => {
    setOrderBy(property);
    setOrder(order);
  };

  useEffect(() => {
    if (userRolesList.length === 0) {
      getAllUserRoles();
    } else {
      const roles = userRolesList?.map((role, idx) => {
        const isChecked = tenantDetails.users?.[0]?.userGroups?.some(
          (assignedRole) => assignedRole.id === role.id
        );
        return {
          id: idx,
          name: role.name,
          isChecked: isChecked,
          isMakingApiCall: false
        };
      });
      setSwitches(sortData(roles, order, orderBy));
      setVisibleSwitches(sortData(roles, order, orderBy));
    }
  }, [userRolesList]);

  useEffect(() => {
    switches.length > 0 && setSwitches(sortData(switches, order, orderBy));
  }, [order, orderBy]);

  const breadcrumbs = [
    {
      label: <FormattedMessage id="Tenants" />,
      link: admin_dashboard_route_strings.adminDashboard
    },
    {
      label: tenantDetails.name,
      link: admin_dashboard_route_strings.tenantDetails_dynamic(merchantId)
    },
    {
      label: <FormattedMessage id="Subscriptions" />
    }
  ];

  const columns = [
    { id: "service", label: "service", align: "left", sortLabel: true },

    { id: "active", label: "active", align: "left", sortLabel: false }
  ];

  const buttonStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    height: "28px",
    borderRadius: "6px"
  };

  const Row = ({ row }) => {
    return (
      <TableRow key={row.id} hover>
        <TableCell
          sx={{
            borderBottom: "solid 1px var(--future-tax-8)"
          }}
        >
          {row.name}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            borderBottom: "solid 1px var(--future-tax-8)"
          }}
        >
          <RolesSwitch
            name={row.name}
            row={tenantDetails}
            isDefaultChecked={row.isChecked}
            setHasMadeApiCall={setHasMadeApiCall}
            userRolesList={userRolesList}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px"
        }}
      >
        <Breadcrumbs items={breadcrumbs} />
        <div>
          <ButtonGroup
            size="large"
            aria-label="large button group"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Tooltip title={<FormattedMessage id="manageRoles" />}>
              <Button
                color="primary"
                disableElevation
                onClick={() => {
                }}
                variant="text"
                style={buttonStyles}
              >
                <div style={{ marginRight: "6px" }}>
                  <span
                    style={{
                      fontSize: "14px",
                      color: "var(--future-tax-80)",
                      fontWeight: 500
                    }}
                  >
                    {tenantDetails.users?.length}
                  </span>
                </div>
                <UserIcon />
              </Button>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="tenantDetails" />}>
              <Button
                variant="text"
                color="primary"
                disableElevation
                style={buttonStyles}
                onClick={() => {
                }}
              >
                <PencilIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div>
      </div>
      <div
        style={{
          marginBottom: "30px",
          background: "white",
          boxShadow:
            " 2px 1px 2px 0 rgba(0, 0, 0, 0.06), -1px -1px 1px 0 rgba(255, 255, 255, 0.6), inset 1px 1px 2px 0 rgba(255, 255, 255, 0.6), inset -1px -1px 2px 0 rgba(0, 0, 0, 0.08)",
          borderRadius: "6px"
        }}
      >
        <Box style={{ padding: "16px" }}>
          <SectionHeader
            title={`Subscriptions of Tenant ${tenantDetails.name}`}
            tooltipContent={<FormattedMessage id="subscriptions.tooltip" />}
            actionButtons={
              <IconButton>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 11.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                    stroke="#324664"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.665 10.14a1 1 0 0 0 .835-.987v-.306a1 1 0 0 0-.835-.986l-1.342-.224a5.463 5.463 0 0 0-.595-1.437l.79-1.106a1 1 0 0 0-.107-1.289l-.216-.217a1 1 0 0 0-1.288-.106l-1.107.79a5.463 5.463 0 0 0-1.437-.595l-.224-1.341a1 1 0 0 0-.986-.836h-.306a1 1 0 0 0-.986.836l-.224 1.341a5.463 5.463 0 0 0-1.437.595l-1.107-.79a1 1 0 0 0-1.288.107l-.216.216a1 1 0 0 0-.107 1.288l.79 1.107a5.463 5.463 0 0 0-.595 1.437l-1.341.224a1 1 0 0 0-.836.986v.306a1 1 0 0 0 .836.986l1.341.224c.131.512.332.994.595 1.437l-.79 1.107a1 1 0 0 0 .107 1.288l.216.216a1 1 0 0 0 1.288.107l1.107-.79c.443.263.925.464 1.437.595l.224 1.341a1 1 0 0 0 .986.836h.306a1 1 0 0 0 .986-.835l.224-1.342a5.463 5.463 0 0 0 1.437-.595l1.107.79a1 1 0 0 0 1.288-.106l.216-.216a1 1 0 0 0 .107-1.288l-.79-1.108c.263-.443.464-.925.595-1.437l1.341-.224z"
                    stroke="#324664"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </IconButton>
            }
          />
        </Box>
        <Divider />

        <TableComponent
          tableContainerStyle={{ maxHeight: "calc(100vh - 22rem)" }}
          order={order}
          orderBy={orderBy}
          rows={switches}
          columns={columns}
          RowComponent={Row}
          withSearch
          onRequestSort={handleRequestSort}
        />
      </div>
    </div>
  );
};
export default TenantRoles;
