import React from "react";

const FailureIcon = ({ size = "24", color = "#E01600" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m12.568 4.154-5.131 7.697H10.568v2h2.503-2.503v5.698L15.7 11.85h-1.132v.52-.52h-2V4.154zm-6.065 9.697h2.065V21.2c0 1.484 1.925 2.067 2.748.832l6.566-9.849c.665-.996-.05-2.332-1.248-2.332h-2.066V2.503c0-1.484-1.925-2.067-2.748-.832l-6.566 9.848c-.664.997.05 2.332 1.249 2.332z"
        fill={color}
      />
    </svg>
  );
};

export default FailureIcon;
