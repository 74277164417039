import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
  Modal,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  Button,
  Typography,
  ButtonGroup,
  Tooltip,
  Chip,
  CircularProgress
} from "@mui/material";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ModalHeader } from "../../../../components/SectionHeader/SectionHeaders";
import TableHeader from "../../../../components/TableHeader/TableHeader";
import * as _ from "lodash";
import { Input } from "../Input";
import { FormattedMessage } from "react-intl";
import DisableIcon from "../../../../assets/icons/DisableIcon";
import PencilIcon from "../../../../assets/icons/PencilIcon";
import {
  Dropdown,
  MultiSelectDropdown
} from "../../../../components/Dropdown/Dropdown";
import { enqueueSnackbar } from "notistack";
import { existingColumns, inProgressColumns, rolesOptions } from "./constants";
import TableCell from "../../../../components/TabeleCell";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../../../configuration/urls";
import { admin_dashboard_route_strings } from "../../../../route_strings";
import { formattedMessage } from "../../../../configuration/helpers";
import ModalSnackbar from "../../../../components/ModalSnackbar/ModalSnackbar";
import { getInviteStatus } from "../../../../services/adminDashboard";
import { ECAxiosAPI } from "../../../../configuration/interceptor";

const snackBarInit = {
  type: "",
  message: "",
  visible: false,
  title: ""
};

const UsersModal = ({ modalOpen, setModalOpen, getAdminData, row }) => {
  const dataFilters = useSelector((state) => state.adminDashboard.dataFilters);
  const usersDataList = useSelector(
    (state) => state.adminDashboard.usersDataList
  );
  const pagination = useSelector((state) => state.adminDashboard.pagination);
  const dashboardOrder = useSelector((state) => state.adminDashboard.order);

  const inviteData = useSelector((state) => state.adminDashboard.inviteData);
  const [existingUsers, setExistingUsers] = useState(usersDataList);
  const [invitedUsers, setInvitedUsers] = useState(inviteData);
  const [hasMadeApiCall, setHasMadeApiCall] = useState(false);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("emailAddress");

  const [existingUsersOrder, setExistingUsersOrder] = useState("DESC");
  const [existingUsersOrderBy, setExistingUsersOrderBy] = useState("email");

  const [inviteRole, setInviteRole] = useState(["USER"]);
  const [inviteEmail, setInviteEmail] = useState("");

  const [snackbar, setSnackbar] = useState(snackBarInit);

  const [isCallingApi, setIsCallingApi] = useState(false);
  const [isDeletingInvite, setIsDeletingInvite] = useState(false);

  const [hasError, setHasError] = useState({
    inviteEmail: false,
    inviteRole: false,
    message: ""
  });

  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const sortData = (data, order, orderByKey) => {
    return _.orderBy(data, [orderByKey], [order.toLowerCase()]);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isTabletScreen ? "85%" : isMobileScreen ? "95%" : "900px",
    bgcolor: "infoLight.main",
    borderRadius: "6px",
    overflow: "hidden"
  };

  const handleCloseModal = () => {
    setInviteEmail("");
    setInviteRole(["ADMIN"]);
    setModalOpen(false);
    hasMadeApiCall && getAdminData(dataFilters, pagination, dashboardOrder);
    setHasMadeApiCall(false);
    setSnackbar(snackBarInit);
  };

  useEffect(() => {
    setInvitedUsers(sortData(inviteData, order, orderBy));
  }, [order, orderBy]);

  useEffect(() => {
    setExistingUsers(
      sortData(usersDataList, existingUsersOrder, existingUsersOrderBy)
    );
  }, [existingUsersOrder, existingUsersOrderBy]);

  useEffect(() => {
    setInvitedUsers(inviteData);
  }, [inviteData]);

  useEffect(() => {
    setExistingUsers(usersDataList);
  }, [usersDataList]);

  const inviteUser = async (inviteEmail, inviteRole) => {
    setSnackbar(snackBarInit);
    setIsCallingApi(true);
    await ECAxiosAPI.post(URLS.INVITE_USER, {
      email: inviteEmail,
      merchantId: row.merchantId,
      groups: inviteRole
    })
    .then((res) => {
      setHasMadeApiCall(true);
      setInviteEmail("");
      setSnackbar({
        type: "success",
        title: <FormattedMessage id="invite.success.title" />,
        message: (
          <FormattedMessage
            id="invite.success.message"
            values={{
              b: (...chunks) => {
                return (
                  <span style={{ fontWeight: "500" }}>({inviteEmail})</span>
                );
              }
            }}
          />
        ),
        visible: true
      });
      getInviteStatus(row.merchantId);
      setIsCallingApi(false);
    })
    .catch((err) => {
      setIsCallingApi(false);
      switch (err.response.data.message) {
        case "email_already_exists":
          setSnackbar({
            type: "error",
            title: <FormattedMessage id="Attention" />,
            message: (
              <FormattedMessage
                id="invite.duplicate.message"
                values={{
                  b: () => {
                    return (
                      <span style={{ fontWeight: "500" }}>
                          ({inviteEmail})
                        </span>
                    );
                  }
                }}
              />
            ),
            visible: true
          });
          break;
        default:
          setSnackbar({
            type: "error",
            title: <FormattedMessage id="Attention" />,
            message: (
              <FormattedMessage
                id="invite.fail.message"
                values={{
                  b: () => {
                    return (
                      <span style={{ fontWeight: "500" }}>
                          ({inviteEmail})
                        </span>
                    );
                  }
                }}
              />
            ),
            visible: true
          });
      }
    });
  };

  const deleteInvite = async (inviteId, email) => {
    setIsDeletingInvite(true);
    await ECAxiosAPI.delete(URLS.DELETE_INVITE(inviteId))
    .then((res) => {
      setHasMadeApiCall(true);
      setIsDeletingInvite(false);
      setSnackbar({
        type: "success",
        title: <FormattedMessage id="deleteInvite.success.title" />,
        message: (
          <FormattedMessage
            id="deleteInvite.success.message"
            values={{
              b: (...chunks) => {
                return <span style={{ fontWeight: "500" }}>({email})</span>;
              }
            }}
          />
        ),
        visible: true
      });
      setInvitedUsers(invitedUsers.filter((item) => item.id !== inviteId));
    })
    .catch((err) => {
      setIsDeletingInvite(false);
    });
  };

  const InviteActions = () => {
    const handleInvite = () => {
      switch (true) {
        case inviteEmail === "" && inviteRole.length === 0:
          setHasError({
            ...hasError,
            inviteEmail: true,
            inviteRole: true,
            message: "Please type an email and select a role"
          });
          break;
        case inviteEmail === "":
          setHasError({ ...hasError, inviteEmail: true });
          break;
        case inviteRole.length === 0:
          setHasError({ ...hasError, inviteRole: true });
          break;
        case !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
          inviteEmail
        ):
          setHasError({
            ...hasError,
            inviteEmail: true,
            message: "Invalid email"
          });
          break;
        default:
          inviteUser(inviteEmail, inviteRole);
          break;
      }
    };

    const handleChange = (event) => {
      const {
        target: { value }
      } = event;
      value.length > 0 && setHasError({ ...hasError, inviteRole: false });
      setInviteRole(typeof value === "string" ? value.split(",") : value);
    };

    return (
      <ButtonGroup size="small" style={{ padding: "0px 6px" }}>
        <MultiSelectDropdown
          options={sortData(rolesOptions, "asc", "name")}
          containerStyle={{
            backgroundColor: "var(--future-tax-8)",
            marginRight: "8px",
            borderRadius: "4px",
            padding: "0px 4px"
          }}
          onChange={handleChange}
          value={inviteRole}
          error={hasError.inviteRole}
        />
        <Button
          // className="btn-ad"
          variant="contained"
          disableElevation
          style={{ borderRadius: "4px", minWidth: "80px" }}
          onClick={() => {
            handleInvite();
          }}
          disabled={isCallingApi}
        >
          {isCallingApi && (
            <CircularProgress
              style={{ width: "16px", height: "16px", marginRight: "5px" }}
            />
          )}{" "}
          <FormattedMessage id="Invite" />
        </Button>
      </ButtonGroup>
    );
  };

  const navigate = useNavigate();

  return (
    <Modal
      open={modalOpen}
      aria-labelledby="user management modal"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <ModalHeader
          onClose={handleCloseModal}
          title={
            <FormattedMessage
              id="userManagementTitle"
              values={{
                b: (chunks) => (
                  <span>
                    {row.firstName} {row.lastName}
                  </span>
                )
              }}
            />
          }
        />
        <Typography
          fontSize={"14px"}
          color={"var(--future-tax-70)"}
          padding={"12px"}
        >
          <FormattedMessage id="inProgress" />
        </Typography>
        <TableContainer sx={{ maxHeight: 250 }}>
          <Table stickyHeader size="small">
            <TableHeader
              columns={inProgressColumns}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableBody>
              {invitedUsers.length < 1 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <FormattedMessage id="noInvitesInProgress" />
                  </TableCell>
                </TableRow>
              ) : (
                invitedUsers.map((item, idx) => (
                  <TableRow key={item.id}>
                    {/* <TableCell align="left">{item.merchant.name}</TableCell> */}
                    {/* <TableCell>{}</TableCell> */}
                    <TableCell align="left">{item.email}</TableCell>
                    <TableCell align="left">
                      <Chip
                        style={{
                          color: "#0055d2",
                          fontSize: "12px",
                          fontWeight: 500,
                          backgroundColor: "#ccdef9",
                          height: "24px",
                          padding: "0px 16px",
                          minWidth: 180
                        }}
                        label={<FormattedMessage id={item.status} />}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <ButtonGroup>
                        <Tooltip title={<FormattedMessage id="deleteInvite" />}>
                          <Button
                            disabled
                            color="primary"
                            disableElevation
                            onClick={() => {
                              deleteInvite(item.id, item.email);
                            }}
                            variant="text"
                            style={{ borderRadius: "4px" }}
                          >
                            <DisableIcon color="lightgray" />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          fontSize={"14px"}
          color={"var(--future-tax-70)"}
          padding={"12px"}
        >
          <FormattedMessage id="existing" />
        </Typography>
        <TableContainer sx={{ maxHeight: 250 }}>
          <Table stickyHeader size="small">
            <TableHeader
              columns={existingColumns}
              order={existingUsersOrder}
              orderBy={existingUsersOrderBy}
              setOrder={setExistingUsersOrder}
              setOrderBy={setExistingUsersOrderBy}
            />
            <TableBody>
              {existingUsers?.map((item, idx) => (
                <TableRow key={item.id}>
                  <TableCell>{item.firstName}</TableCell>
                  <TableCell align="left"> {item.lastName}</TableCell>
                  <TableCell align="left">{item.email}</TableCell>{" "}
                  <TableCell align="left">
                    {item.userGroups.length !== 0 && (
                      <Dropdown options={item.userGroups} />
                    )}
                  </TableCell>{" "}
                  <TableCell align="right">
                    <ButtonGroup>
                      {/* <Tooltip title={<FormattedMessage id="manageRoles" />}>
                        <Button
                          color="primary"
                          disableElevation
                          onClick={() => {}}
                          variant="text"
                          style={{ borderRadius: "4px" }}
                        >
                          <DisableIcon />
                        </Button>
                      </Tooltip> */}
                      <Tooltip title={<FormattedMessage id="editUser" />}>
                        <Button
                          color="primary"
                          disableElevation
                          onClick={() => {
                            navigate(
                              admin_dashboard_route_strings.userDetails_dynamic(
                                row.merchantId,
                                item.id
                              )
                            );
                          }}
                          variant="text"
                          style={{ borderRadius: "4px" }}
                        >
                          <PencilIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box style={{ padding: "16px" }}>
          <Input
            label={<FormattedMessage id="invite" />}
            actions={<InviteActions />}
            placeholder="Multiple Values"
            onChange={(e) => {
              setHasError({ ...hasError, inviteEmail: false });
              setInviteEmail(e.target.value);
            }}
            value={inviteEmail}
            error={hasError.inviteEmail}
          />
        </Box>
        <ModalSnackbar
          visible={snackbar.visible}
          type={snackbar.type}
          message={snackbar.message}
          title={snackbar.title}
          onClose={() => setSnackbar({ ...snackbar, visible: false })}
        />
      </Box>
    </Modal>
  );
};

export default UsersModal;
