import React from "react";

const DeleteIcon = ({ color = "#324664", size = "16px" }) => {
  return (
    <div>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_659_153945)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 8.5C14 11.8137 11.3137 14.5 8 14.5C4.68629 14.5 2 11.8137 2 8.5C2 5.18629 4.68629 2.5 8 2.5C11.3137 2.5 14 5.18629 14 8.5ZM16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM6 7.5C5.44772 7.5 5 7.94772 5 8.5C5 9.05229 5.44772 9.5 6 9.5H10C10.5523 9.5 11 9.05229 11 8.5C11 7.94772 10.5523 7.5 10 7.5H6Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_659_153945">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default DeleteIcon;
