export const IS_AUTHENTICATED = "IS_AUTHENTICATED";
export const USERNAME = "USERNAME";
export const SET_USER_GROUPS = "SET_USER_GROUPS";

export const setIsAuthenticated = (payload) => {
  return {
    type: "IS_AUTHENTICATED",
    payload,
  };
};

export const setUsername = (payload) => {
  return {
    type: "USERNAME",
    payload,
  };
};

export const setUserGroups = (payload) => {
  return {
    type: "SET_USER_GROUPS",
    payload,
  };
};
